import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { makeAuthenticatedApiCall } from '../../../apiHelper';
import { useDealMisivA, useDealMisivB, useDealMisivE } from '../../../hooks/useApiQueries';
import { DBDeal } from '../../../types/deal';
import {
  documentTypes,
  documentTypesDb,
  formatDateToDDMMYYYY,
  getAdresseTypeVoie,
  getCleanPrice,
  getPipedriveDealData,
  getPipedriveOrgData,
  getPipedrivePersonData,
  removeSpaces,
} from '../../../utils';
import { ErrorMessageSnackbar } from '../../ErrorMessageSnackbar';
import ManualLPN from './ManualLPN';

interface SubmitPurchaseCertificate {
  deal: DBDeal;
  isMandate: boolean;
  isDepositSale?: boolean;
}

const SubmitPurchaseCertificate: React.FC<SubmitPurchaseCertificate> = ({ deal, isMandate, isDepositSale }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLogbookSending, setIsLogbookSending] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [purchaseDateTime, setPurchaseDateTime] = useState('');
  const [responseErrorCode, setResponseErrorCode] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState<AlertColor>('error');
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('');
  const [buttonStep, setButtonStep] = useState<string>('initial');
  const [responseNomFic, setResponseNomFic] = useState<string>('');
  const [responseId, setResponseId] = useState<number>();
  const [errorText, setErrorText] = useState<string>('');
  const [tooltipError, setTooltipError] = useState<string>('');
  const [isTimeoutError, setIsTimeoutError] = useState(false);

  const [showLogbookButton, setShowLogbookButton] = useState(false);
  const [logbookConfirmation, setLogbookConfirmation] = useState<string>('');

  const [clientType, setClientType] = useState<string>('');
  const [niceAddress, setNiceAddress] = useState<Record<string, string | null>>({});
  const [ownerData, setOwnerData] = useState<Record<string, string | null>>({});
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orgName, setOrgName] = useState<string>('');

  const updatedDeal = { ...deal, purchaseDateTime, isMandate, isDepositSale };
  const { data: misivDealData, refetch: refetchMisivDealData } = isDepositSale
    ? useDealMisivE(deal.id)
    : isMandate
      ? useDealMisivB(deal.id)
      : useDealMisivA(deal.id);

  const pipelineLetter = isDepositSale ? 'e' : isMandate ? 'b' : 'a';

  const queryClient = useQueryClient();

  const formMethods = useForm({
    mode: 'all',
  });
  const { handleSubmit, control, formState, trigger } = formMethods;
  const { errors, isValid } = formState;

  const validationRules = {
    firstName: { required: 'Ce champ est requis' },
    lastName: { required: 'Ce champ est requis' },
    addressNumber: {
      validate: (value: string) => {
        const addressType = formMethods.watch('addressType');
        if (addressType !== 'ldit' && !value) {
          return 'Ce champ est requis';
        }
        return true;
      },
    },
    addressType: { required: 'Ce champ est requis' },
    addressName: { required: 'Ce champ est requis' },
    city: { required: 'Ce champ est requis' },
    postalCode: { required: 'Ce champ est requis' },
    country: { required: 'Ce champ est requis' },
  };

  useEffect(() => {
    const determineButtonState = () => {
      const data = misivDealData?.data && misivDealData.data.getDealMisivFromDB[0];
      if (!data) return 'initial';

      const {
        // receipt_file_firebase_url,
        receipt_file_name,
        response_id,
        has_timeout,
        purchase_date_time,
        lpn_id,
        step,
        last_update,
      } = data;

      if (receipt_file_name && response_id && has_timeout === 0) {
        setResponseNomFic(receipt_file_name);
        setButtonText(`✅ DA n°${response_id}`);
        setIsTimeoutError(false);
        if (!lpn_id) {
          setShowLogbookButton(true);
        } else if (lpn_id && step === 2) {
          setLogbookConfirmation(`✅ Inscrit au LPN le : ${formatDateToDDMMYYYY(last_update)}`);
          setShowLogbookButton(false);
        }
        return 'receipt';
      } else if (!receipt_file_name && has_timeout === 1) {
        setResponseId(response_id);
        setIsTimeoutError(true);
        setButtonText(`Renvoyer au ministère n°${response_id}`);
        return 'timeout-error';
      } else if (!receipt_file_name && has_timeout === 0) {
        setPurchaseDateTime(purchase_date_time);
        setIsTimeoutError(false);
        setButtonText(`⚠️ DA n°${response_id}`);
        return 'save';
      }

      return 'initial';
    };

    const newState = determineButtonState();
    setButtonStep(newState);
  }, [misivDealData]);

  const handleConfirmClick = () => {
    setIsConfirmModalOpen(true); // Ouvrir la modale de confirmation au lieu de soumettre directement
  };

  const handleFinalConfirm = async () => {
    const formData = formMethods.getValues(); // Récupérer les données du formulaire actuel

    setIsConfirmModalOpen(false); // Fermer la modale de confirmation
    await handleSave(formData);
    await handleSavePurchaseCertificate(); // Appeler la fonction pour soumettre
    handleDialogClose(); // Fermer la modale principale
  };

  const checkVehicleOwnerInformations = async () => {
    if (isMandate) {
      // JE DOIS CHANGER CA ?
      // Ici je continue de chopper dans Pipedrive car il y a toutes les informations sur l'agence et le gérant (person)
      console.log('isMandate');
      setClientType('pro'); // Car c'est l'agence qui achète, et l'agence est un pro t'as vu

      // Purchaser <=> Agence
      try {
        const pipedriveDealData = await getPipedriveDealData(deal.id);
        setOrgName(pipedriveDealData?.org_name);
        const orgId = pipedriveDealData?.org_id?.value;
        const personId = pipedriveDealData?.person_id?.value;
        const _purchaserData = await getPipedriveOrgData(orgId);
        const agencyPersonData = await getPipedrivePersonData(personId);

        const _niceAddress = getAdresseTypeVoie(_purchaserData?.['address_route'] ?? '');
        setNiceAddress(_niceAddress);

        const purchaserData = {
          clientType: 'pro',
          firstName: agencyPersonData.first_name,
          lastName: agencyPersonData.last_name,
          dateOfBirth: null,
          placeOfBirth: null,
          addressNumber: _purchaserData.address_street_number,
          addressExtension: _purchaserData.address_subpremise,
          addressType: _niceAddress.typeVoie,
          addressName: _niceAddress.nomVoie,
          city: _purchaserData.address_locality,
          postalCode: _purchaserData.address_postal_code,
          country: _purchaserData.address_country,
          gender: null,
          documentId: null,
          documentType: null,
          documentIssueDate: null,
          companyName: _purchaserData?.['d7f00bd23217519c4d134d312d1206bc4a7021c6'] ?? pipedriveDealData?.org_name,
          siret: _purchaserData?.['cd6bc84ef4b1392fb08f9fdb9071cccb2c96ce1f'],
        };
        setOwnerData(purchaserData);
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await makeAuthenticatedApiCall('get', `/api/owners/${pipelineLetter}/${deal.id}`);

        if (!response.data) {
          setTooltipError('Problème dans la récupération du titulaire');
          openSnackbar('Problème dans la récupération du titulaire', 'error');
          return true; // Arrêter l'exécution ici
        }

        const _ownerData = response.data;
        setOwnerData(_ownerData);
        setOrgName(_ownerData?.company_name);
        setClientType(_ownerData?.client_type);
        setNiceAddress(_ownerData?.address_type);

        // Particulier => Francois Grolleau : ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_postal_code']
        // Pro => Adena Piscines : ownerData['address_postal_code'];
        if (_ownerData && !_ownerData.postal_code) {
          setTooltipError('Le code postal du titulaire est absent');
          return true; // Arrêter l'exécution ici
        } else if (!_ownerData) {
          setTooltipError('Les informations du titulaire sont absentes');
        }
      } catch (error: any) {
        setTooltipError(
          `Les informations du vendeur sont absentes dans l'application Skipcar. Veuillez d'abord utiliser la génération du certificat de cession.`,
        );
        setDisabledButton(false); // Pour pouvoir re-tenter sans recharger
        return true;
      }
    }
    return false;
  };

  const openSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarMessage(message);
    setSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleSave = async (formData: any /* FormDataPerson | FormDataPro */) => {
    const submissionData = {
      ...formData,
      documentType: documentTypesDb[formData.documentType] || formData.documentType, // Fallback sur la valeur originale au cas où
    };

    try {
      const responseSave = await makeAuthenticatedApiCall(
        'post',
        `/api/owners/${pipelineLetter}/${deal.id}`,
        submissionData,
      );
      openSnackbar('Les modifications ont bien été prises en compte', 'success');
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.message, 'error');
      setDisabledButton(false); // Pour pouvoir re-tenter sans recharger
    } finally {
      setResponseErrorCode(null);
    }
  };

  const handleSavePurchaseCertificate = async () => {
    setIsLoading(true);
    setDisabledButton(true);
    handleDialogClose();

    try {
      const responseSave = await makeAuthenticatedApiCall('post', `/api/misiv/save/purchase`, updatedDeal);
      const responseSubmitId = responseSave?.data.responseId;
      setButtonStep('save');
      setButtonText(`⚠️ DA n°${responseSubmitId}`);
      handleSubmitPurchase();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de la demande:', error);
      openSnackbar('Erreur lors de la sauvegarde de la demande', 'error');
      setDisabledButton(false);
      setButtonStep('initial');
    } finally {
      // setIsLoading(false);
    }
  };

  const handleSubmitPurchase = async () => {
    setIsLoading(true);
    setDisabledButton(true);

    try {
      const responseSubmit = await makeAuthenticatedApiCall('post', `/api/misiv/submit/purchase`, updatedDeal);

      const responseId = responseSubmit.data.responseId;
      const responseNomFic = responseSubmit.data.responseNomFic;
      setResponseNomFic(responseNomFic);

      // Gérer le cas où il n'y a pas d'erreur
      openSnackbar(`Votre demande a bien été envoyée : ${responseId}`, 'success');
      setButtonStep('receipt');
      setButtonText(`✅ DA n°${responseId}`);

      /*
      const base64String = responseSubmit.data.askPurchaseReceipt;
      const blob = base64ToBlob(base64String, 'application/pdf');
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      */
      const firebaseUrl = responseSubmit.data.firebaseReceipt.firebaseReceiptUrl;
      if (firebaseUrl) {
        // Si nous recevons une URL Firebase, ouvrez-la directement
        window.open(firebaseUrl);
      }

      queryClient.invalidateQueries({ queryKey: ['useDealMisivA'] });
      queryClient.invalidateQueries({ queryKey: ['useDealMisivB'] });
      queryClient.invalidateQueries({ queryKey: ['useDealMisivE'] });
      await refetchMisivDealData();
      handleSendToLogbook();
    } catch (error: any) {
      if (error.response && error.response.status === 500) {
        // Cas où le serveur a renvoyé une erreur 500 avec des données JSON
        const responseId = error.response.data?.responseId;
        const responseErrorCode = error.response.data?.responseErrorCode;
        const responseErrorMessage = error.response.data?.responseErrorMessage;

        if (responseErrorCode === 3 || responseErrorCode === 100) {
          setResponseId(responseId);
          setIsTimeoutError(true);
          setButtonText(`Renvoyer au ministère n°${responseId}`);
          openSnackbar(`Timeout Ministère : Ré-essayer dans quelques instants`, 'error');
          return;
        } else {
          // Enregistrez le code d'erreur pour une utilisation ultérieure
          setResponseErrorCode(responseErrorCode);
        }

        if (responseErrorCode) {
          openSnackbar(`Erreur ${responseErrorCode} : ${responseErrorMessage}`, 'error');
          setErrorText(responseErrorMessage);
        } else {
          console.log(error);
          openSnackbar(error.response.data.error, 'error');
          setErrorText(error.response.data.error);
        }
      } else if (axios.isAxiosError(error)) {
        console.error('Erreur réseau ou serveur :', error.message);
        openSnackbar('Erreur réseau ou serveur : ' + error.message, 'error');
      } else {
        console.error("Erreur inattendue lors de l'envoi du certificat à TMS :", error);
        openSnackbar(`Erreur lors de l'envoi du certificat à TMS`, 'error');
      }
      setDisabledButton(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendToLogbook = async () => {
    setIsLogbookSending(true);
    setShowLogbookButton(true);
    try {
      const response = await makeAuthenticatedApiCall('post', `/api/misiv/send-to-logbook`, {
        updatedDeal,
        pipelineLetter,
      });
      console.log('response logbook', response);
      setLogbookConfirmation('✅ Inscrit au LPN');
      setShowLogbookButton(false);
    } catch (error: any) {
      console.error(error);
      openSnackbar(error.response.data, 'error');
      setErrorText(error.response.data);
    } finally {
      setIsLogbookSending(false);
    }
  };

  const handleResendToMinistry = async (responseSubmitId: number) => {
    setIsLoading(true);

    try {
      const responseOpen = await makeAuthenticatedApiCall('post', `/api/misiv/open/purchase`, {
        responseSubmitId,
      });
      handleGetReceipt(responseOpen.data.jsonObj);
      setButtonStep('receipt');
      setButtonText(`✅ DA n°${responseSubmitId}`);
      setTooltipError('');
      setResponseNomFic(responseOpen.data.jsonObj);
    } catch (error) {
      console.error("Erreur lors de l'ouverture du certificat à TMS :", error);
      openSnackbar("Erreur lors de l'ouverture du certificat à TMS", 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetReceipt = async (responseNomFic: string) => {
    setIsLoading(true);

    try {
      const responseReceipt = await makeAuthenticatedApiCall('post', `/api/misiv/receipt/purchase`, {
        responseNomFic,
        pipelineLetter,
        dealId: deal.id,
        typeDemarche: 'DA',
      });
      /*
        const base64String = responseReceipt.data.askPurchaseReceipt;

      // Convertir le base64 en Blob
      const blob = base64ToBlob(base64String, 'application/pdf');

      // Créer un URL pour le Blob
      const blobUrl = URL.createObjectURL(blob);

      // Ouvrir le PDF dans une nouvelle fenêtre
      window.open(blobUrl);
      */
      const firebaseUrl = responseReceipt.data.firebaseUrl;
      if (firebaseUrl) {
        // Si nous recevons une URL Firebase, ouvrez-la directement
        window.open(firebaseUrl);
      }
      setButtonStep('receipt');
      setIsTimeoutError(false);
    } catch (error: any) {
      console.error('handleGetReceipt - Erreur lors de la génération du reçu :', error);
      openSnackbar(error.response.data.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = async () => {
    const hasError = await checkVehicleOwnerInformations();

    if (!hasError) {
      if (buttonText.startsWith('⚠️ DA n°') && responseErrorCode === 3 && responseErrorCode === 100) {
        // Comportement par défaut
        handleSubmitPurchase();
      } else {
        setIsDialogOpen(true);
      }
    }
  };

  useEffect(() => {
    if (isDialogOpen) {
      trigger(); // Déclencher la validation de tous les champs
    }
  }, [isDialogOpen, trigger]);

  useEffect(() => {
    if (ownerData) {
      formMethods.reset({
        clientType: ownerData.client_type,
        firstName: ownerData.first_name,
        lastName: ownerData.last_name,
        dateOfBirth: ownerData.date_of_birth,
        addressNumber: ownerData.address_number,
        addressExtension: ownerData.address_extension,
        addressType: ownerData.address_type,
        addressName: ownerData.address_name,
        city: ownerData.city,
        postalCode: ownerData.postal_code,
        country: ownerData.country,
        gender: ownerData.gender,
        documentType: ownerData.document_type,
        documentIssueDate: ownerData.document_issue_date,
        documentId: ownerData.document_id,
        siret: ownerData.siret,
        companyName: ownerData.company_name,
        placeOfBirth: ownerData.place_of_birth,
        // Assurez-vous d'ajouter ou de retirer des champs en fonction de ce qui est pertinent pour votre formulaire.
      });
    }
  }, [ownerData, formMethods]);

  return (
    <>
      {buttonStep === 'initial' && !isTimeoutError && (
        <div style={{ position: 'relative' }}>
          <Tooltip title={tooltipError ? tooltipError : ''} placement="top">
            <div>
              <Button
                sx={{
                  fontSize: 14,
                  margin: '8px auto 0',
                  maxWidth: 180,
                  color: 'white',
                  backgroundColor: tooltipError ? 'red' : '', // Changer la couleur du bouton en rouge en cas d'erreur
                }}
                variant="contained"
                onClick={() => handleButtonClick()}
                disabled={disabledButton || !!tooltipError}
                fullWidth
              >
                {isLoading && <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} />}
                {disabledButton ? buttonText : '📩 Soumettre la DA'}
              </Button>
            </div>
          </Tooltip>
          {tooltipError && (
            <Typography
              color="error"
              sx={{
                fontSize: 12,
                marginTop: 1,
                textAlign: 'center',
              }}
            >
              {tooltipError}
            </Typography>
          )}
        </div>
      )}

      {buttonStep === 'save' && !isTimeoutError && (
        <Tooltip
          title={
            isLoading
              ? "Demande sauvegardée, maintenant en cours d'envoi"
              : "La demande n'a pas abouti. Cliquez pour réessayer."
          }
          placement="top"
        >
          <div>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                width: 180,
                backgroundColor: 'orange !important',
              }}
              variant="contained"
              onClick={
                responseErrorCode !== 3 && responseErrorCode !== 100
                  ? () => handleButtonClick()
                  : () => handleSubmitPurchase()
              }
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {errorText && (
              <Typography
                sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 1, fontWeight: 'bold', color: 'red' }}
                color="red"
              >
                {errorText}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}

      {buttonStep === 'receipt' && !isTimeoutError && (
        <Button
          sx={{
            fontSize: 14,
            margin: '8px auto 0',
            maxWidth: 160,
            backgroundColor: 'green',
            color: 'white',
          }}
          variant="contained"
          onClick={() => handleGetReceipt(responseNomFic)}
          fullWidth
        >
          {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
          {buttonText}
        </Button>
      )}
      {isTimeoutError && responseId !== undefined && (
        <Tooltip title="Timeout Ministère : Ré-essayez dans quelques instants" placement="top">
          <div>
            <Button
              sx={{
                fontSize: 14,
                margin: '8px auto 0',
                width: 180,
                backgroundColor: 'red !important',
              }}
              variant="contained"
              onClick={() => handleResendToMinistry(responseId)}
              fullWidth
            >
              {isLoading && <CircularProgress size={12} style={{ color: 'white' }} />}
              {buttonText}
            </Button>
            {errorText && (
              <Typography
                sx={{ fontSize: '10px', lineHeight: '1.25', marginTop: 1, fontWeight: 'bold', color: 'red' }}
                color="red"
              >
                {errorText}
              </Typography>
            )}
          </div>
        </Tooltip>
      )}
      {showLogbookButton && (
        <>
          <Button
            onClick={handleSendToLogbook}
            sx={{
              margin: '8px auto 0',
              width: 180,
              backgroundColor: 'orange !important',
            }}
            variant="contained"
          >
            {isLogbookSending ? (
              <>
                <CircularProgress size={24} style={{ color: 'white', marginRight: 1 }} /> Envoi au LPN en cours
              </>
            ) : (
              '⚠️ Renvoyer au LPN'
            )}
          </Button>
        </>
      )}
      <ManualLPN
        id={misivDealData?.data?.getDealMisivFromDB[0]?.id}
        pipelineLetter={pipelineLetter}
        onSuccess={() => {
          setLogbookConfirmation(`✅ Inscrit au LPN manuellement`);
          setShowLogbookButton(false);
          queryClient.invalidateQueries({ queryKey: [`useDealMisiv${pipelineLetter.toUpperCase()}`] });
        }}
      />
      {logbookConfirmation && (
        <Typography variant="body2" align="center" sx={{ marginTop: 1, fontSize: 16 }}>
          {logbookConfirmation}
        </Typography>
      )}
      <Dialog open={isDialogOpen} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSave)}>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="purchaseDateTime"
                    label="Date et heure de la vente"
                    type="datetime-local"
                    fullWidth
                    value={purchaseDateTime || ''}
                    onChange={(e) => setPurchaseDateTime(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                  <Typography variant="body1" textAlign="center">
                    Veuillez vérifier les informations provenants de Skipcar.
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    Nous les utilisons pour faire l'envoi au SIV.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="sellingPrice"
                    control={control}
                    defaultValue={getCleanPrice(deal)}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.sellingPrice}
                        className="inputElement"
                        margin="dense"
                        id="sellingPrice"
                        label="Prix de vente"
                        type="text"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <Typography variant="body2" style={{ fontWeight: 'normal' }}>
                              €
                            </Typography>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="clientType"
                    control={control}
                    defaultValue={clientType ?? ''}
                    render={({ field }) => (
                      <TextField
                        disabled
                        {...field}
                        className="inputElement"
                        margin="dense"
                        id="clientType"
                        label="Type de client"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={validationRules.firstName}
                    defaultValue={clientType === 'pro' ? ownerData?.firstName : (ownerData?.first_name ?? '')}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.firstName}
                        className="inputElement"
                        margin="dense"
                        id="firstName"
                        label="Prénom"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="lastName"
                    control={control}
                    rules={validationRules.lastName}
                    defaultValue={clientType === 'pro' ? ownerData?.lastName : (ownerData?.last_name ?? '')}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="lastName"
                        error={!!errors.lastName}
                        className="inputElement"
                        margin="dense"
                        label="Nom"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} md={2}>
                  <Controller
                    name="addressNumber"
                    control={control}
                    rules={validationRules.addressNumber}
                    defaultValue={
                      clientType === 'pro'
                        ? isMandate
                          ? ownerData?.addressNumber
                          : ownerData['address_street_number']
                        : (ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_street_number'] ?? '')
                    }
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.addressNumber}
                        className="inputElement"
                        margin="dense"
                        id="addressNumber"
                        label="Numéro d'adresse"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="addressExtension"
                    control={control}
                    defaultValue={
                      clientType === 'pro'
                        ? isMandate
                          ? ownerData?.addressExtension
                          : ownerData['address_street_subpremise']
                        : (ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_subpremise'] ?? '')
                    }
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        margin="dense"
                        id="addressExtension"
                        label="Extension d'adresse"
                        type="text"
                        fullWidth
                        inputProps={{ maxLength: 10 }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Controller
                    name="addressType"
                    control={control}
                    rules={validationRules.addressType}
                    defaultValue={niceAddress?.typeVoie ?? ''}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl fullWidth error={!!error}>
                        <InputLabel className="inputElement" id={`${field.name}-label`}>
                          Type d'adresse
                        </InputLabel>
                        <Select
                          sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                          className="inputElement"
                          {...field}
                          labelId={`${field.name}-label`}
                          id={field.name}
                          label="Type d'adresse"
                          value={field.value ? field.value.toLowerCase() : ''}
                        >
                          <MenuItem value="rue">Rue</MenuItem>
                          <MenuItem value="all">Allée</MenuItem>
                          <MenuItem value="avn">Avenue</MenuItem>
                          <MenuItem value="blvd">Boulevard</MenuItem>
                          <MenuItem value="chau">Chaussée</MenuItem>
                          <MenuItem value="chm">Chemin</MenuItem>
                          <MenuItem value="cour">Cour</MenuItem>
                          <MenuItem value="fbg">Faubourg</MenuItem>
                          <MenuItem value="fort">Fort</MenuItem>
                          <MenuItem value="imp">Impasse</MenuItem>
                          <MenuItem value="ldit">Lieu-Dit</MenuItem>
                          <MenuItem value="parc">Parc</MenuItem>
                          <MenuItem value="pass">Passage</MenuItem>
                          <MenuItem value="plc">Place</MenuItem>
                          <MenuItem value="quai">Quai</MenuItem>
                          <MenuItem value="rte">Route</MenuItem>
                          <MenuItem value="sent">Sentier</MenuItem>
                          <MenuItem value="squa">Square</MenuItem>
                          <MenuItem value="sans">Inconnu</MenuItem>
                        </Select>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="addressName"
                    control={control}
                    rules={validationRules.addressName}
                    defaultValue={niceAddress?.nomVoie ?? ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        error={!!errors.addressName}
                        margin="dense"
                        id="addressName"
                        label="Nom de la voie (Rue, Allée, ou Avenue, ne doivent pas apparaitre ici)"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="city"
                    control={control}
                    rules={validationRules.city}
                    defaultValue={
                      clientType === 'pro'
                        ? isMandate
                          ? ownerData?.city
                          : ownerData['address_locality']
                        : (ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_locality'] ?? '')
                    }
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={!!errors.city}
                        className="inputElement"
                        margin="dense"
                        id="city"
                        label="Ville"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="postalCode"
                    control={control}
                    rules={validationRules.postalCode}
                    defaultValue={
                      clientType === 'pro'
                        ? isMandate
                          ? ownerData?.postalCode
                          : ownerData['address_postal_code']
                        : (ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_postal_code'] ?? '')
                    }
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        error={!!errors.postalCode}
                        margin="dense"
                        id="postalCode"
                        label="Code postal"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    name="country"
                    control={control}
                    rules={validationRules.country}
                    defaultValue={
                      clientType === 'pro'
                        ? isMandate
                          ? ownerData?.country
                          : ownerData['address_country']
                        : (ownerData['cdbbea0bbb21d6bb4fa5ed9ffa679c2a817d5839_country'] ?? '')
                    }
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="inputElement"
                        error={!!errors.country}
                        margin="dense"
                        id="country"
                        label="Pays"
                        type="text"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                {clientType === 'person' && (
                  <>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="dateOfBirth"
                        rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={ownerData?.date_of_birth ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="dateOfBirth"
                            error={!!errors.dateOfBirth}
                            sx={{ marginTop: '8px' }}
                            className="inputElement"
                            label="Date de naissance"
                            type="date"
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="placeOfBirth"
                        control={control}
                        rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        defaultValue={ownerData?.place_of_birth ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            error={!!errors.placeOfBirth}
                            className="inputElement"
                            margin="dense"
                            id="placeOfBirth"
                            label="Lieu de naissance"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="gender"
                        control={control}
                        rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        defaultValue={ownerData?.gender}
                        render={({ field }) => (
                          <Select
                            {...field}
                            error={!!errors.gender}
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '8px' }}
                            margin="dense"
                            fullWidth
                            className="inputElement"
                          >
                            <MenuItem value="male">Masculin</MenuItem>
                            <MenuItem value="female">Féminin</MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="documentId"
                        rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={ownerData?.document_id ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            margin="dense"
                            error={!!errors.documentId}
                            className="inputElement"
                            id="documentId"
                            label="ID du document"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="documentType"
                        rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={
                          ownerData?.['34387af45e0ae48b44f61195ee2f755a00f2280b']
                            ? documentTypes[ownerData?.['34387af45e0ae48b44f61195ee2f755a00f2280b']]?.dbValue || '' // Utiliser dbValue comme valeur par défaut
                            : ''
                        }
                        render={({ field }) => (
                          <Select
                            {...field}
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            error={!!errors.documentType}
                            fullWidth
                            margin="dense"
                            className="inputElement"
                          >
                            <MenuItem value="id_card">Carte d'identité</MenuItem>
                            <MenuItem value="passport">Passeport</MenuItem>
                            <MenuItem value="driving_licence">Permis de conduire</MenuItem>
                            <MenuItem value="residence_permit">Titre de séjour</MenuItem>
                          </Select>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        name="documentIssueDate"
                        rules={clientType === 'person' ? { required: 'Ce champ est requis' } : {}}
                        control={control}
                        defaultValue={ownerData?.document_issue_date ?? ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ minHeight: 'inherit', height: '56px', marginTop: '0' }}
                            error={!!errors.documentIssueDate}
                            className="inputElement"
                            id="documentIssueDate"
                            label="Date de délivrance du document"
                            type="date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                {clientType === 'pro' && (
                  <>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="siret"
                        control={control}
                        rules={
                          clientType === 'pro'
                            ? {
                                required: 'Ce champ est requis',
                                minLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                                maxLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                              }
                            : {
                                minLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                                maxLength: {
                                  value: 14,
                                  message: 'Le SIRET doit contenir exactement 14 caractères',
                                },
                              }
                        }
                        defaultValue={
                          isMandate ? ownerData?.siret : (ownerData['cd6bc84ef4b1392fb08f9fdb9071cccb2c96ce1f'] ?? '')
                        }
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            className="inputElement"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            margin="dense"
                            id="siret"
                            label="SIRET"
                            type="text"
                            fullWidth
                            inputProps={{ maxLength: 14 }} // Limite le nombre de caractères que l'utilisateur peut taper
                            onChange={(e) => field.onChange(removeSpaces(e.target.value))} // Enlève les espaces
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="companyName"
                        control={control}
                        rules={clientType === 'pro' ? { required: 'Ce champ est requis' } : {}}
                        defaultValue={
                          isMandate
                            ? ownerData?.companyName
                            : (ownerData['d7f00bd23217519c4d134d312d1206bc4a7021c6'] ?? orgName)
                        }
                        render={({ field }) => (
                          <TextField
                            {...field}
                            margin="dense"
                            error={!!errors.companyName}
                            id="companyName"
                            className="inputElement"
                            label="Raison sociale"
                            type="text"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose}>Annuler</Button>
              <Button
                sx={{ color: 'white', fontWeight: 600 }}
                variant="contained"
                onClick={handleConfirmClick}
                disabled={!isValid || !purchaseDateTime}
              >
                Soumettre
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
      {/* Modale de confirmation */}
      <Dialog open={isConfirmModalOpen} onClose={() => setIsConfirmModalOpen(false)}>
        <DialogContent>
          <Typography>Êtes-vous sûr que toutes les informations sont correctes ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmModalOpen(false)}>Annuler</Button>
          <Button onClick={handleFinalConfirm}>Confirmer</Button>
        </DialogActions>
      </Dialog>
      <ErrorMessageSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        handleClose={() => setSnackbarOpen(false)}
        severity={severity}
      />
    </>
  );
};
export default SubmitPurchaseCertificate;
