import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { useArbitratorOffersForAgency, useDatabaseDeals, usePipelines, useStages } from '../hooks/useApiQueries';
import { DBDeal } from '../types/deal';
import {
  Capitalize,
  capitalizeAndFormat,
  formatDateToDDMMYYYY,
  formatKilometers,
  isDateMoreThan9YearsOld,
  transformDBRowToPipedriveRow,
} from '../utils';
import { AgencyDealsListTableTypes } from '../utils';
import { AgencyDealsListActions } from './AgencyDealsList/AgencyDealsListActions';
import DealStageActions from './DealsList/DealStageActions';

interface Data {
  id: number;
  vehicle: string;
  make: string;
  model: string;
  registration_plate: string;
  informations: string;
  fuel: string;
  transmission: string;
  kms: number;
  add_time: string;
  stage_id: number;
  pipedrive_deal_id: number;
  ext_url: string;
  photo: string;
  business_type: string | number;
  org_id: number;
  person_id: number;
  reg_date: string;
  acceptation_date: string;
  sender: string;
  google_drive_link: string | undefined;
  googleDriveLink: string | undefined;
  details_link: string;
  org_name: string;
  vin: string | null;
  genre: string;
  commercial_finishing: string | null;
  colour: string;
  number_of_seats: number | null;
  options: string | null;
  documentation: string;
  date_last_revision: string;
  kms_last_revision: number | null;
  desired_price: string | null;
  market_price: string | null;
  vat: boolean;
  source: string;
  vehicle_usage: string;
  detention_duration: string;
  histovec_link: string | null;
  test_drive: string;
  mechanical_condition: string;
  conditions_expertise: string;
  vehicle_type: string;
  kms_confirm: boolean;
  location: string | null;
  comp_infos_1: string | null;
  comp_infos_2: string | null;
  damages: string;
  damages_cost: number;
  spark_fds_encrypt: string | null;
  more_api_infos: string | null;
  refusal_date: string | null;
  sent_em_date: string | null;
  deleted_from_em: number | null;
  marketplace_sent_date: string | null;
  marketplace_expiration_date: string | null;
  marketplace_deleted: 0 | 1 | null;
  duplicate_keys: string | null;
  final_purchase_price: string | null;
  purchase_price: string | null;
  status: string | null;
  encheres_vo_price?: string;
  deposit_sale_supervisor?: string;
  straight_trade_in_supervisor?: string;
  thirty_day_sale_mandate_supervisor?: string;
  deposit_sale_arbitrator?: string;
  straight_trade_in_arbitrator?: string;
  thirty_day_sale_mandate_arbitrator?: string;
  accepted_offer?: string | null;
  arbitration_id?: string | null;
  minimum_em_price?: string | null;
  num_formule?: string | null;
  isBought?: boolean;
  isSold?: boolean;
}

type Order = 'asc' | 'desc';

interface HeadCell {
  id: keyof Data;
  label: string;
  numeric: boolean;
}

// Composant de tableau réutilisable
interface DealTableProps {
  title: string;
  deals: DBDeal[];
  showBusinessType?: boolean;
  showProgression?: boolean;
  pipeline: string;
  onRefresh: () => void;
}

const isVehicleOutOfCharter = (make: string, mileage: number, reg_date: string) => {
  const kmsLimit = 150000;
  const isLandRover = make === 'land_rover';
  const regDate = reg_date.split('-').reverse().join('/');
  return mileage >= kmsLimit || isLandRover || isDateMoreThan9YearsOld(regDate);
};

const LazyImage = ({ src, alt, style }: { src: string; alt: string; style: React.CSSProperties }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = React.useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 },
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return isVisible ? (
    <img ref={imgRef} src={src} alt={alt} style={style} />
  ) : (
    <div ref={imgRef} style={{ ...style, backgroundColor: '#f0f0f0' }} />
  );
};

const DealTable = ({
  title,
  deals,
  showBusinessType = false,
  pipeline,
  showProgression = false,
  onRefresh,
}: DealTableProps) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('pipedrive_deal_id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [updatedDealData, setUpdatedDealData] = useState(null);
  const [updatedArbitrationData, setUpdatedArbitrationData] = useState(null);

  const { data: pipelines } = usePipelines();
  const { data: stages } = useStages();

  useEffect(() => {
    const connect = () => {
      let wsUrl;

      if (process.env.NODE_ENV === 'development' || process.env.FORCE_LOCAL_WS) {
        wsUrl = 'ws://localhost:3001';
      } else {
        const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        wsUrl = `${protocol}//${window.location.host}/ws`;
      }
      const socket = new WebSocket(wsUrl);

      socket.onopen = () => {
        console.log('Connected to WebSocket server');
        // Envoie un heartbeat toutes les X secondes pour maintenir la connexion.
        const heartbeatInterval = setInterval(() => {
          socket.send(JSON.stringify({ type: 'heartbeat' }));
        }, 30000); // 30 secondes, par exemple.

        // Assurez-vous de nettoyer l'interval lors de la fermeture de la socket
        socket.onclose = () => {
          clearInterval(heartbeatInterval);
          console.log('WebSocket connection closed. Reconnecting...');
          setTimeout(connect, 5000); // Essayez de vous reconnecter après 5 secondes.
        };
      };

      socket.onerror = (error) => {
        console.log('WebSocket error:', error);
        socket.close(); // Assurez-vous que la connexion est fermée si une erreur survient.
      };

      socket.onmessage = async (event) => {
        const message = JSON.parse(event.data);
        console.log('WebSocket message received:', message);
        if (message.type === 'arbitrationUpdate') {
          setUpdatedDealData(message.data.deal);
          setUpdatedArbitrationData(message.data.arbitration);

          // await handleRefresh();
        }
        if (message.type === 'refuseArbitration') {
          // Gérer le cas de 'refuseArbitration'
        }
      };

      // Nettoyage en fermant la connexion WebSocket lors du démontage du composant
      return () => {
        socket.close();
      };
    };

    if (pipeline === 'Proposals') {
      connect(); // Connectez-vous immédiatement lors du montage du composant
    }
  }, []); // Le tableau de dépendances vide signifie que cet effet s'exécute une fois, au montage du composant

  const headCells: readonly HeadCell[] = [
    { id: 'photo' as keyof Data, label: 'Photo', numeric: false },
    { id: 'vehicle' as keyof Data, label: 'Véhicule', numeric: false },
    { id: 'registration_plate' as keyof Data, label: 'Immat', numeric: false },
    { id: 'informations' as keyof Data, label: 'Informations', numeric: false },
    { id: 'add_time' as keyof Data, label: 'Date cotation', numeric: false },
    ...(showBusinessType ? [{ id: 'business_type' as keyof Data, label: 'Type', numeric: false }] : []),
    ...(showProgression ? [{ id: 'stage_id' as keyof Data, label: 'Progression', numeric: false }] : []),
    { id: 'actions' as keyof Data, label: 'Actions', numeric: false },
    { id: 'pipedrive_deal_id' as keyof Data, label: 'ID', numeric: false },
  ];

  function createData(deal: DBDeal): Data {
    const photo = deal.photos
      ? deal.photos.frontRegPlate && deal.photos.frontRegPlate.length > 0
        ? deal.photos.frontRegPlate[0]
        : deal.photos.backRegPlate && deal.photos.backRegPlate.length > 0
          ? deal.photos.backRegPlate[0]
          : deal.photos.interior && deal.photos.interior.length > 0
            ? deal.photos.interior[0]
            : ''
      : '';

    return {
      id: deal.id || 0,
      pipedrive_deal_id: deal.pipedrive_deal_id || 0,
      business_type: deal.business_type || '',
      vehicle: `${deal.make} ${deal.model}`,
      make: deal.make,
      model: deal.model,
      registration_plate: deal.registration_plate,
      informations: `${deal.fuel} ${deal.transmission} ${deal.kms}`,
      fuel: deal.fuel,
      transmission: deal.transmission,
      kms: deal.kms,
      add_time: formatDateToDDMMYYYY(deal.add_time || ''),
      stage_id: deal.stage_id || 0,
      ext_url: deal.ext_url,
      photo,
      org_id: deal.org_id || 0,
      person_id: deal.person_id || 0,
      spark_fds_encrypt: deal.spark_fds_encrypt || '',
      refusal_date: deal.refusal_date || '',
      reg_date: deal.reg_date || '',
      acceptation_date: deal.acceptation_date,
      sender: deal.sender,
      google_drive_link: deal.google_drive_link,
      googleDriveLink: deal.googleDriveLink,
      details_link: deal.details_link,
      org_name: deal.org_name,
      vin: deal.vin,
      genre: deal.genre,
      commercial_finishing: deal.commercial_finishing,
      colour: deal.colour,
      number_of_seats: deal.number_of_seats,
      options: deal.options,
      documentation: deal.documentation,
      date_last_revision: deal.date_last_revision,
      kms_last_revision: deal.kms_last_revision,
      desired_price: deal.desired_price,
      market_price: deal.market_price,
      vat: deal.vat,
      source: deal.source,
      vehicle_usage: deal.vehicle_usage,
      detention_duration: deal.detention_duration,
      histovec_link: deal.histovec_link,
      test_drive: deal.test_drive,
      mechanical_condition: deal.mechanical_condition,
      conditions_expertise: deal.conditions_expertise,
      vehicle_type: deal.vehicle_type,
      kms_confirm: deal.kms_confirm,
      location: deal.location,
      comp_infos_1: deal.comp_infos_1,
      comp_infos_2: deal.comp_infos_2,
      damages: deal.damages,
      damages_cost: deal.damages_cost,
      more_api_infos: deal.more_api_infos,
      sent_em_date: deal.sent_em_date,
      deleted_from_em: deal.deleted_from_em,
      marketplace_sent_date: deal.marketplace_sent_date,
      marketplace_expiration_date: deal.marketplace_expiration_date,
      marketplace_deleted: deal.marketplace_deleted,
      duplicate_keys: deal.duplicate_keys,
      final_purchase_price: deal.final_purchase_price,
      purchase_price: deal.purchase_price,
      status: deal.status,
      encheres_vo_price: deal.encheres_vo_price,
      deposit_sale_supervisor: deal.deposit_sale_supervisor,
      straight_trade_in_supervisor: deal.straight_trade_in_supervisor,
      thirty_day_sale_mandate_supervisor: deal.thirty_day_sale_mandate_supervisor,
      deposit_sale_arbitrator: deal.deposit_sale_arbitrator,
      straight_trade_in_arbitrator: deal.straight_trade_in_arbitrator,
      thirty_day_sale_mandate_arbitrator: deal.thirty_day_sale_mandate_arbitrator,
      accepted_offer: deal.accepted_offer,
      arbitration_id: deal.arbitration_id,
      minimum_em_price: deal.minimum_em_price,
      num_formule: deal.num_formule,
      isBought: deal.isBought,
      isSold: deal.isSold,
    };
  }

  const rows = useMemo(() => deals.map(createData), [deals]);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }

  // Fonction pour calculer le pourcentage de progression
  const calculateProgress = (stageId: number, AgencyDealsListTableTypes: string[]) => {
    const index = AgencyDealsListTableTypes.indexOf(stageId.toString());
    if (index === -1) return 0;
    return ((index + 1) / AgencyDealsListTableTypes.length) * 100;
  };

  // Déterminer le type de tableau en fonction du titre
  const getTableType = (title: string) => {
    switch (title) {
      case 'Proposition de cotations':
        return AgencyDealsListTableTypes.Proposals;
      case "Processus d'achat":
        return AgencyDealsListTableTypes.Buying;
      case 'Mandats en cours':
        return AgencyDealsListTableTypes.Mandates;
      case 'Dépôt-Vente en cours':
        return AgencyDealsListTableTypes.DepositSale;
      case 'Terminées':
        return AgencyDealsListTableTypes.Done;
      default:
        return [];
    }
  };

  return (
    <Box sx={{ width: '100%', mb: 4 }}>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Paper sx={{ width: '100%', mb: 2 }}>
        {rows.length === 0 ? (
          <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
            Pas de véhicule dans cette catégorie
          </Typography>
        ) : (
          <>
            <Table stickyHeader>
              <TableHead>
                <TableRow sx={{ background: 'black' }}>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? 'right' : 'left'}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={{
                        fontWeight: 'bold',
                        background: 'black',
                        ...(headCell.id === 'actions' && { width: 155 }),
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : 'asc'}
                        onClick={() => handleRequestSort(headCell.id)}
                        sx={{ background: 'black' }}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .sort(getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover key={row.pipedrive_deal_id}>
                      <TableCell>
                        {row.photo && (
                          <Link href={row.ext_url}>
                            <LazyImage
                              src={row.photo}
                              alt="Vehicle"
                              style={{
                                width: '100px',
                                height: '75px',
                                objectFit: 'cover',
                                borderRadius: '4px',
                              }}
                            />
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>
                        <Link href={row.ext_url}>
                          {capitalizeAndFormat(row.make)} {Capitalize(row.model)}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link href={row.ext_url}>{row.registration_plate}</Link>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize="16px" variant="body2">
                          {t(`fuel.${row.fuel}`)}
                        </Typography>
                        <Typography fontSize="16px" variant="body2">
                          {row.transmission === 'mt'
                            ? 'Manuelle'
                            : row.transmission === 'at'
                              ? 'Automatique'
                              : row.transmission === 'st'
                                ? 'Séquentielle'
                                : 'Inconnue'}
                        </Typography>
                        <Typography fontSize="16px" variant="body2">
                          {formatKilometers(row.kms)}
                        </Typography>
                      </TableCell>
                      <TableCell>{row.add_time}</TableCell>
                      {showBusinessType && (
                        <TableCell>
                          {t(`business_type.${row.business_type}`)}
                          {(pipeline === 'Buying' || pipeline === 'Proposals') &&
                            (row.business_type.toString().includes('deposit') ||
                              row.business_type.toString().includes('mandate')) && (
                              <Typography
                                variant="caption"
                                color="error"
                                sx={{
                                  display: 'block',
                                  fontSize: '11px',
                                  fontStyle: 'italic',
                                  mt: 0.5,
                                }}
                              >
                                Attention pas de marge à l'entrée
                              </Typography>
                            )}
                        </TableCell>
                      )}
                      {showProgression && (
                        <TableCell align="right" sx={{ minWidth: 100 }}>
                          <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress
                              variant="determinate"
                              value={calculateProgress(row.stage_id, getTableType(title))}
                              sx={{
                                height: 8,
                                borderRadius: 5,
                                backgroundColor: '#e0e0e0',
                                '& .MuiLinearProgress-bar': {
                                  borderRadius: 5,
                                  backgroundImage: 'linear-gradient(45deg, #FDC75F 0%, #FF6060 100%)',
                                  transition: 'transform .4s linear',
                                },
                              }}
                            />
                          </Box>
                        </TableCell>
                      )}
                      <TableCell align="center" sx={{ width: 155 }} className="DealStageActionsTableCell">
                        <DealStageActions
                          stageId={row.stage_id}
                          stages={stages}
                          pipelines={pipelines}
                          deal={transformDBRowToPipedriveRow(row, t)}
                          dealData={updatedDealData || row}
                          onRefresh={onRefresh}
                        />
                      </TableCell>
                      <TableCell align="right">{row.pipedrive_deal_id}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 20, 50]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page"
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </>
        )}
      </Paper>
    </Box>
  );
};

const AgencyDealsList = ({ orgId }: { orgId: number }) => {
  const {
    data: databaseDeals,
    isLoading: isLoadingDatabaseDeals,
    refetch: refetchDatabaseDeals,
  } = useDatabaseDeals(orgId);

  const { refetch: refetchArbitratorDeals } = useArbitratorOffersForAgency(orgId);

  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleRefresh = useCallback(async () => {
    setIsLoading(true);
    const response = await axios.post('/api/deals/synchronize');
    console.log('Synchronization response:', response.data);

    try {
      // Invalider les requêtes pour forcer un rechargement
      queryClient.invalidateQueries({ queryKey: ['useArbitratorDeals'] });
      queryClient.invalidateQueries({ queryKey: ['useDatabaseDeals'] });

      await refetchArbitratorDeals();
      await refetchDatabaseDeals();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [queryClient, refetchDatabaseDeals, refetchArbitratorDeals]);

  const deals: DBDeal[] = (databaseDeals?.data as unknown as DBDeal[]) || [];

  const proposalsDeals = deals.filter((deal: DBDeal) =>
    AgencyDealsListTableTypes.Proposals.includes(deal?.stage_id?.toString() || ''),
  );
  const buyingDeals = deals.filter((deal: DBDeal) =>
    AgencyDealsListTableTypes.Buying.includes(deal?.stage_id?.toString() || ''),
  );
  const mandatesDeals = deals
    .filter((deal: DBDeal) => AgencyDealsListTableTypes.Mandates.includes(deal?.stage_id?.toString() || ''))
    .filter((deal: DBDeal) => !deal.isSold);
  const depositSaleDeals = deals
    .filter((deal: DBDeal) => AgencyDealsListTableTypes.DepositSale.includes(deal?.stage_id?.toString() || ''))
    .filter((deal: DBDeal) => !deal.isSold);
  const doneDeals = deals.filter((deal: DBDeal) =>
    AgencyDealsListTableTypes.Done.includes(deal?.stage_id?.toString() || ''),
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid container item xs={12} sm={12} justifyContent="left" marginTop={4}>
        <Tooltip title="Vous avez reçu un mail vous indiquant qu'un nouveau prix était disponible mais il n'apparait pas dans la liste ? Cliquez ici pour rafraichir la liste">
          <Button type="button" variant="contained" className="ActionButton" onClick={handleRefresh}>
            🔄 Rafraichir la liste
          </Button>
        </Tooltip>
      </Grid>
      {isLoading || isLoadingDatabaseDeals ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper sx={{ mb: 4, p: 2, backgroundColor: 'rgba(255, 237, 206, 1)', borderRadius: 2 }}>
            <DealTable
              title="Proposition de cotations"
              pipeline="Proposals"
              deals={proposalsDeals}
              showBusinessType
              onRefresh={handleRefresh}
            />
          </Paper>

          <Paper sx={{ mb: 4, p: 2, backgroundColor: 'rgba(249, 192, 122, 1)', borderRadius: 2 }}>
            <DealTable
              title="Processus d'achat"
              pipeline="Buying"
              deals={buyingDeals}
              showBusinessType
              showProgression
              onRefresh={handleRefresh}
            />
            <DealTable
              title="Mandats en cours"
              pipeline="Mandates"
              deals={mandatesDeals}
              showProgression
              onRefresh={handleRefresh}
            />
            <DealTable
              title="Dépôt-Vente en cours"
              pipeline="DepositSale"
              deals={depositSaleDeals}
              showProgression
              onRefresh={handleRefresh}
            />
          </Paper>

          <Paper sx={{ mb: 4, p: 2, backgroundColor: 'rgba(151, 158, 210, 1)', borderRadius: 2 }}>
            <DealTable title="Terminées" pipeline="Done" deals={doneDeals} showBusinessType onRefresh={handleRefresh} />
          </Paper>
        </>
      )}
    </Box>
  );
};

export default AgencyDealsList;
