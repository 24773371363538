import { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';

import { Card, CardContent, Grid, Tooltip, Typography } from '@mui/material';

import { useStock, useStockOffers } from '../../hooks/useApiQueries';
import theme from '../../theme';
import StockDetailsDialog from './StockDetailsDialog';

interface StockData {
  stockUnder25Days: any[];
  stockOver25Days: any[];
  depositUnder45Days: any[];
  depositOver45Days: any[];
}

const StockOverview = ({ franchise, orgName, role }: { franchise: string; orgName: string; role?: string }) => {
  const { data: stockOffersData } = useStockOffers(franchise, orgName);
  const { data: rawStockData } = useStock(franchise, orgName) as { data: StockData | undefined };

  const [stockData, setStockData] = useState<StockData | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [dialogTitle, setDialogTitle] = useState('');
  const pieChartRef = useRef(null);

  useEffect(() => {
    if (rawStockData) {
      setStockData({
        stockUnder25Days: filterUniqueData(rawStockData.stockUnder25Days),
        stockOver25Days: filterUniqueData(rawStockData.stockOver25Days),
        depositUnder45Days: filterUniqueData(rawStockData.depositUnder45Days),
        depositOver45Days: filterUniqueData(rawStockData.depositOver45Days),
      });
    }
  }, [rawStockData]);

  const filterUniqueData = (data: any[]): any[] => {
    const seen = new Set();
    return data.filter((item) => {
      if (seen.has(item.pipedrive_deal_id)) {
        return false;
      }
      seen.add(item.pipedrive_deal_id);
      return true;
    });
  };

  const businessTypeCounts =
    stockOffersData?.reduce(
      (acc: { [key: string]: number }, offer: { business_type: string }) => {
        const type = offer.business_type.replace(/_/g, '-').toLowerCase();
        acc[type] = (acc[type] || 0) + 1;
        return acc;
      },
      {} as { [key: string]: number },
    ) || {};

  const pieData = {
    labels: ['Mandat 30 jours', 'Reprise sèche', 'Dépôt-vente'],
    datasets: [
      {
        data: [
          (businessTypeCounts?.['30-days-sale-mandate'] || 0) + (businessTypeCounts?.['thirty-day-sale-mandate'] || 0),
          (businessTypeCounts?.['straight-trade-in'] || 0) + (businessTypeCounts?.['straight-trade_in'] || 0),
          businessTypeCounts?.['deposit-sale'] || 0,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const handleStockClick = (type: string, data: any[]) => {
    setSelectedData(data);
    setDialogTitle(`Détails du Stock - ${type}`);
    setDialogOpen(true);
  };

  const handlePieClick = (event: any) => {
    if (!pieChartRef.current) return;

    const chart = pieChartRef.current;
    const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

    if (elements.length > 0) {
      const elementIndex = elements[0].index;
      const label = pieData.labels[elementIndex];

      const filteredData =
        stockOffersData?.filter((offer: { business_type: string }) => {
          const businessType = offer.business_type.replace(/_/g, '-').toLowerCase();
          if (label === 'Mandat 30 jours') {
            return businessType === '30-days-sale-mandate' || businessType === 'thirty-day-sale-mandate';
          }
          if (label === 'Reprise sèche') {
            return businessType === 'straight-trade-in' || businessType === 'straight-trade_in';
          }
          if (label === 'Dépôt-vente') {
            return businessType === 'deposit-sale';
          }
          return false;
        }) || [];

      handleStockClick(label, filteredData);
    }
  };

  const handleTotalClick = () => {
    const allData = [
      ...(stockData?.stockUnder25Days || []),
      ...(stockData?.stockOver25Days || []),
      ...(stockData?.depositUnder45Days || []),
      ...(stockData?.depositOver45Days || []),
    ];
    setSelectedData(allData);
    setDialogTitle('Détails du Stock - Total');
    setDialogOpen(true);
  };

  return (
    <Grid container spacing={4} sx={{ marginTop: theme.spacing(0), paddingTop: 0 }}>
      {role !== 'agency' && (
        <Grid item xs={12} paddingTop={0}>
          <Typography variant="body1" sx={{ textAlign: 'center', color: 'black', paddingTop: 0 }}>
            Stock
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '14px', textAlign: 'center', color: 'black' }}>
            ℹ️ Ces statistiques peuvent être faites par Franchise (voir plus bas) ℹ️
          </Typography>
        </Grid>
      )}

      <Grid
        item
        xs={12}
        md={6}
        sx={role === 'agency' ? { paddingTop: '0 !important', marginBottom: theme.spacing(4) } : {}}
      >
        <Card elevation={2} sx={{ padding: theme.spacing(2) }}>
          <CardContent>
            <Tooltip title="Nombre de véhicule en stock depuis moins de 25 jours" arrow>
              <Grid
                sx={{ cursor: 'pointer' }}
                onClick={() => handleStockClick('Mandat en cours', stockData?.stockUnder25Days || [])}
                container
                item
                xs={12}
                justifyContent="space-between"
                display="flex"
              >
                <Typography variant="body2">Mandat en cours</Typography>
                <Typography variant="body2">{stockData?.stockUnder25Days.length || 0}</Typography>
              </Grid>
            </Tooltip>

            <Tooltip title="Nombre de véhicule en stock depuis 25 jours et plus" arrow>
              <Grid
                sx={{ cursor: 'pointer' }}
                onClick={() => handleStockClick('Mandat bientôt à échéance', stockData?.stockOver25Days || [])}
                container
                item
                xs={12}
                justifyContent="space-between"
              >
                <Typography variant="body2">Mandat bientôt à échéance</Typography>
                <Typography variant="body2">{stockData?.stockOver25Days.length || 0}</Typography>
              </Grid>
            </Tooltip>

            <Tooltip title="Nombre de véhicule en stock depuis moins de 45 jours" arrow>
              <Grid
                onClick={() => handleStockClick('Dépôt-vente en cours', stockData?.depositUnder45Days || [])}
                container
                item
                xs={12}
                justifyContent="space-between"
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant="body2">Dépôt-vente en cours</Typography>
                <Typography variant="body2">{stockData?.depositUnder45Days.length || 0}</Typography>
              </Grid>
            </Tooltip>

            <Tooltip title="Nombre de véhicule en stock depuis plus de 45 jours" arrow>
              <Grid
                onClick={() => handleStockClick('Dépôt-vente long', stockData?.depositOver45Days || [])}
                container
                item
                xs={12}
                justifyContent="space-between"
                sx={{ cursor: 'pointer' }}
              >
                <Typography variant="body2">Dépôt-vente long</Typography>
                <Typography variant="body2">{stockData?.depositOver45Days.length || 0}</Typography>
              </Grid>
            </Tooltip>

            <Tooltip title="Voir tous les véhicules en stock" arrow>
              <Grid
                container
                item
                xs={12}
                justifyContent="space-between"
                sx={{
                  borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                  marginTop: theme.spacing(2),
                  paddingTop: theme.spacing(1),
                  cursor: 'pointer',
                }}
                onClick={handleTotalClick}
              >
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  TOTAL
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {(stockData?.stockUnder25Days?.length || 0) +
                    (stockData?.stockOver25Days?.length || 0) +
                    (stockData?.depositUnder45Days?.length || 0) +
                    (stockData?.depositOver45Days?.length || 0)}
                </Typography>
              </Grid>
            </Tooltip>
          </CardContent>
        </Card>
      </Grid>

      {/* Right Side: Pie Chart */}
      {role !== 'agency' && (
        <Grid item xs={12} md={6}>
          <Card elevation={2}>
            <CardContent>
              <Typography variant="body2" align="center" sx={{ marginBottom: theme.spacing(2) }}>
                Type d'offre
              </Typography>
              <Pie
                ref={pieChartRef}
                data={pieData}
                onClick={handlePieClick}
                options={{
                  maintainAspectRatio: true,
                  responsive: true,
                  aspectRatio: 1.5,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      )}

      <StockDetailsDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={dialogTitle}
        data={selectedData}
      />
    </Grid>
  );
};

export default StockOverview;
