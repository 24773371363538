import { Box, Button, Card, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AgencyDealsList from '../components/AgencyDealsList';
import { useAuth } from '../components/AuthContext';
import MonthlyStatsOverview from '../components/Statistics/MonthlyStatsOverview';

const AgencyHomePage = () => {
  const auth = useAuth();
  const theme = useTheme();

  const currentUser = auth ? auth.currentUser : null;
  const currentUserRole = currentUser?.data?.data?.role;

  return (
    <>
      <Container maxWidth={false} sx={{ padding: theme.spacing(4), backgroundColor: theme.palette.background.default }}>
        {/* Top Agencies Section */}
        <Grid
          container
          spacing={4}
          sx={{
            marginTop: theme.spacing(0),
            width: '100%',
            maxWidth: 1300,
            mx: 'auto',
            marginBottom: theme.spacing(1),
          }}
        >
          <Grid container item sm={5} xs={12} sx={{ paddingLeft: '0 !important' }}>
            <Card
              elevation={5}
              sx={{ padding: theme.spacing(2), display: 'flex', flexDirection: 'column', marginTop: theme.spacing(0) }}
            >
              <Typography variant="body2" gutterBottom align="center" sx={{ marginBottom: theme.spacing(1) }}>
                Les demandes de carte grise sont <b>uniquement à 10€ HT</b> Accessible pour tout véhicule (même les
                véhicules vendus en intermédiation). Profitez en !
              </Typography>
              <Button
                variant="contained"
                color="primary"
                type="button"
                className="ActionButton"
                sx={{ width: '250px', margin: '8px auto !important' }}
              >
                🚗 Demande de reprise
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="button"
                className="ActionButton"
                sx={{ width: '250px', margin: '8px auto 0 !important' }}
              >
                📄 Demande de carte grise
              </Button>
            </Card>
          </Grid>
          <Grid container item xs={7}>
            <Typography variant="body1" gutterBottom align="left" sx={{ marginBottom: theme.spacing(1) }}>
              Vos statistiques du mois en cours
            </Typography>
            <MonthlyStatsOverview
              role={currentUserRole}
              franchise={''}
              orgName={currentUser?.data?.organization?.name}
            />
          </Grid>

          <Box sx={{ width: '100%' }}>
            <AgencyDealsList orgId={currentUser?.data?.organization?.id} />
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default AgencyHomePage;
