// packages/frontend/src/components/Header.tsx
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, Container, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { getAuth, signOut } from 'firebase/auth';

import { makeAuthenticatedApiCall } from '../apiHelper';
import useFromExternalStore from '../stores/FromExternalStore';
import theme from '../theme';
import { useAuth } from './AuthContext';

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [dealsInfo, setDealsInfo] = useState({ toBeTreated: 0, awaitingSupervision: 0 });

  const location = useLocation();
  const { isAuthenticated, currentUser, isInitializing } = useAuth();
  const userRole = currentUser?.data?.data?.role;
  const userNetwork = currentUser?.data?.data?.network;
  const idPipedrive = currentUser?.data?.data?.idPipedrive;

  const isFromExternal = useFromExternalStore((state) => state.fromExternal);
  const setFromExternal = useFromExternalStore((state) => state.setFromExternal);

  const navigate = useNavigate();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!isInitializing && isAuthenticated && !isFromExternal && userRole !== 'agency') {
      const fetchCounts = async () => {
        try {
          const [toBeTreatedResponse, awaitingSupervisionResponse] = await Promise.all([
            makeAuthenticatedApiCall('get', '/api/arbitration/count/to-be-treated'),
            makeAuthenticatedApiCall('get', '/api/arbitration/count/awaiting-supervision'),
          ]);

          setDealsInfo({
            toBeTreated: toBeTreatedResponse.data as number,
            awaitingSupervision: awaitingSupervisionResponse.data as number,
          });
        } catch (error) {
          console.error('Error fetching deal counts:', error);
        }
      };
      fetchCounts();
    }
  }, [isInitializing, isAuthenticated, userRole, isFromExternal]);

  const menuItems = isFromExternal
    ? []
    : [
        { label: 'Accueil', path: '/' },
        { label: 'Affaires en cours', path: userRole === 'agency' ? '/' : '/deals-list' },
        { label: 'À arbitrer', path: '/arbitrator' },
        { label: 'Demande de cotation', path: '/cotation' },
        { label: 'Demande de CG', path: '/form-cg' },
        { label: 'Liste des CG', path: '/list-cg' },
        { label: 'DA', path: 'https://form.typeform.com/to/Xx5CeYaU', external: true },
        { label: 'Marketplace', path: 'https://skipcar.fr/connexion/professionnel', external: true },
      ];

  const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        if (isFromExternal) {
          setFromExternal(false);
          navigate('/login', { replace: true });
        } else {
          navigate('/login', { replace: true });
        }
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: 'black', background: 'black' }}>
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            {!isFromExternal && (
              <RouterLink to="/">
                <img
                  src="https://aptekaa.dev/skipcar_logo_white.svg"
                  alt="Skipcar"
                  className="logoSkipcar"
                  style={{ height: '40px', marginTop: '8px' }}
                />
              </RouterLink>
            )}
            {isFromExternal && (
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <RouterLink to="https://skipcar.fr">
                  <img
                    src="https://aptekaa.dev/skipcar_logo_white.svg"
                    alt="Skipcar"
                    className="logoSkipcar"
                    style={{ height: '40px', marginTop: '8px' }}
                  />
                </RouterLink>
              </Box>
            )}
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {isAuthenticated &&
                !isFromExternal &&
                menuItems.map((page, index) => {
                  const badgeContent =
                    userRole === 'arbitrator'
                      ? page.path === '/arbitrator'
                        ? dealsInfo.toBeTreated
                        : 0
                      : userRole === 'god'
                        ? page.path === '/arbitrator'
                          ? dealsInfo.awaitingSupervision
                          : 0
                        : 0;

                  if (index === 0) {
                    // en desktop on affiche pas accueil
                    return null;
                  }
                  if (
                    (page.path === '/companies-list' ||
                      page.path === '/arbitrator' ||
                      page.path === '/marketplace' ||
                      page.path === '/accounts') &&
                    userRole === 'agency'
                  ) {
                    return null; // Ne pas afficher l'élément pour les utilisateurs ayant le rôle "agency"
                  }

                  const isDisabled =
                    userNetwork === 'Agence Automobiliere' &&
                    idPipedrive !== '197' &&
                    (page.path === '/form-cg' || page.path === '/list-cg');

                  return (
                    <Tooltip
                      key={index}
                      title={
                        isDisabled
                          ? "Cette fonctionnalité n'est pas active sur votre réseau. Pour tout élément supplémentaire, veuillez nous contacter au 07 56 86 73 93."
                          : ''
                      }
                      placement="top"
                      arrow
                    >
                      <Box>
                        <Badge
                          sx={{ '.MuiBadge-badge': { top: 20, right: 20 } }}
                          badgeContent={badgeContent}
                          color="error"
                        >
                          {page.external ? (
                            <Button
                              component="a"
                              href={page.path}
                              target="_blank"
                              rel="noopener noreferrer"
                              sx={{ my: 2, color: theme.palette.black.main, display: 'block' }}
                            >
                              <Typography
                                variant="body2"
                                color={isDisabled ? 'grey' : '#fdd15f'}
                                className={location.pathname === page.path ? 'menuElement active' : 'menuElement'}
                                textAlign="center"
                              >
                                {page.label}
                              </Typography>
                            </Button>
                          ) : (
                            <Button
                              component={RouterLink}
                              to={isDisabled ? '#' : page.path}
                              sx={{ my: 2, color: theme.palette.black.main, display: 'block' }}
                              disabled={isDisabled}
                            >
                              <Typography
                                variant="body2"
                                color={isDisabled ? 'grey' : '#fdd15f'}
                                className={location.pathname === page.path ? 'menuElement active' : 'menuElement'}
                                textAlign="center"
                              >
                                {page.label}
                              </Typography>
                            </Button>
                          )}
                        </Badge>
                      </Box>
                    </Tooltip>
                  );
                })}
            </Box>
            {isFromExternal && (
              <Button
                variant="contained"
                sx={{
                  width: 200,
                  display: { xs: 'none', md: 'flex' },
                  backgroundColor: theme.palette.red.main,
                  '&:hover': { backgroundColor: theme.palette.saffron.main },
                  color: 'white',
                }}
                onClick={() => {
                  handleSignOut();
                  setFromExternal(false);
                  navigate('/login', { replace: true });
                }}
              >
                J'ai déjà un compte
              </Button>
            )}
            {isAuthenticated && !isFromExternal && (
              <Button
                variant="contained"
                sx={{
                  width: 200,
                  display: { xs: 'none', md: 'flex' },
                  backgroundColor: theme.palette.red.main,
                  '&:hover': { backgroundColor: theme.palette.saffron.main },
                  color: 'white',
                }}
                onClick={handleSignOut}
              >
                {t('Sign out')}
              </Button>
            )}
            <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                edge="start"
                sx={{ color: 'white' }}
                aria-label="menu"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                {mobileMenuOpen ? <CloseIcon style={{ color: 'white' }} /> : <MenuIcon style={{ color: 'white' }} />}
              </IconButton>
            </Box>
            <Button
              id="test_sentry"
              variant="contained"
              sx={{ backgroundColor: theme.palette.red.main, color: 'white', display: 'none', width: 256 }}
            >
              {t('Test Sentry')}
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      {mobileMenuOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 56,
            left: 0,
            width: '100%',
            height: 'calc(100% - 56px)',
            background: 'black',
            display: { xs: 'flex', md: 'none' },
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            zIndex: 1300,
          }}
        >
          {isAuthenticated &&
            !isFromExternal &&
            menuItems.map((page, index) => {
              const badgeContent = page.path === '/arbitrator' ? dealsInfo.toBeTreated : 0;
              if (page.label === 'Liste des agences' && userRole === 'agency') {
                return null; // Ne pas afficher l'élément pour les utilisateurs ayant le rôle "agency"
              }

              const isDisabled =
                userNetwork === 'Agence Automobiliere' && (page.path === '/form-cg' || page.path === '/list-cg');

              return (
                <Tooltip
                  key={index}
                  title={
                    isDisabled
                      ? "Cette fonctionnalité n'est pas active sur votre réseau. Pour tout élément supplémentaire, veuillez nous contacter au 07 56 86 73 93."
                      : ''
                  }
                  placement="top"
                  arrow
                >
                  <Box>
                    <Badge badgeContent={badgeContent} color="error">
                      <Button
                        fullWidth
                        component={RouterLink}
                        to={isDisabled ? '#' : page.path}
                        sx={{ my: 2, color: theme.palette.black.main, display: 'block' }}
                        disabled={isDisabled}
                      >
                        <Typography className="menuElement" variant="body2" color={isDisabled ? 'grey' : '#fdd15f'}>
                          {page.label}
                        </Typography>
                      </Button>
                    </Badge>
                  </Box>
                </Tooltip>
              );
            })}
          <Button
            variant="contained"
            sx={{
              backgroundColor: theme.palette.red.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
              color: 'white',
            }}
            onClick={handleSignOut}
          >
            {t('Sign out')}
          </Button>
        </Box>
      )}
    </>
  );
};

export default Header;
