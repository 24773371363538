import { useState } from 'react';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';

import { makeAuthenticatedApiCall } from '../../../apiHelper';
import lpnScreenshot from '../../../assets/lpn-screenshot.png';
import theme from '../../../theme';

interface ManualLPNProps {
  id: number;
  pipelineLetter: string;
  onSuccess: () => void;
}

const ManualLPN: React.FC<ManualLPNProps> = ({ id, pipelineLetter, onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lpnId, setLpnId] = useState('');
  const [isSending, setIsSending] = useState(false);

  const isValidLpnId = (value: string) => {
    // Accepte les lettres, chiffres et tirets uniquement
    return /^[a-zA-Z0-9-]+$/.test(value);
  };

  const handleSubmit = async () => {
    setIsSending(true);
    try {
      await makeAuthenticatedApiCall('post', `/api/misiv/manual-lpn-id-update`, {
        id,
        lpnId,
        pipelineLetter,
      });
      onSuccess?.();
      setIsOpen(false);
      setLpnId(''); // Reset le champ après succès
    } catch (error) {
      console.error('Erreur lors de la mise à jour du LPN ID:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        sx={{
          margin: '8px auto 0',
          maxWidth: 180,
          fontSize: 13,
          color: 'black !important',
          fontWeight: '600',
          backgroundColor: 'orange !important',
          textTransform: 'none',
        }}
        variant="contained"
      >
        🛠️ Mise à jour manuelle du LPN ID
      </Button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle sx={{ fontWeight: 600 }}>Mise à jour manuelle du LPN ID</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ mb: 2, color: 'black !important' }}>
            Veuillez entrer l'ID du LPN que vous pouvez trouver dans l'URL du LPN Misiv sur le véhicule en question. Ici
            : 6dc7ab4c-d538-4f6a-833d-3a7583f3bba0
          </Typography>

          <Box sx={{ mb: 3 }}>
            <img
              src={lpnScreenshot}
              alt="Capture d'écran montrant où trouver le LPN ID"
              style={{
                maxWidth: '100%',
                height: 'auto',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            />
          </Box>

          <TextField
            autoFocus
            margin="dense"
            label="LPN ID"
            className="inputElement"
            fullWidth
            variant="outlined"
            value={lpnId}
            onChange={(e) => setLpnId(e.target.value)}
            error={lpnId !== '' && !isValidLpnId(lpnId)}
            helperText={
              lpnId !== '' && !isValidLpnId(lpnId)
                ? 'Le LPN ID ne doit contenir que des lettres, des chiffres et des tirets'
                : ''
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Annuler
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            sx={{
              color: 'black !important',
              fontWeight: '600',
              backgroundColor: theme.palette.saffron.main,
              '&:hover': { backgroundColor: theme.palette.saffron.main },
            }}
            disabled={!lpnId || !isValidLpnId(lpnId) || isSending}
          >
            {isSending ? 'Mise à jour...' : 'Soumettre'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManualLPN;
