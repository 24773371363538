import { useAuth } from '../components/AuthContext';
import AgencyHomePage from './AgencyHomePage';
import Dashboard from './Dashboard';

const HomePage = () => {
  const auth = useAuth();
  const currentUser = auth ? auth.currentUser : null;

  if (currentUser?.data?.data?.role !== 'agency') {
    return <Dashboard />;
  } else {
    return <AgencyHomePage />;
  }
};

export default HomePage;
