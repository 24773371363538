import { useEffect, useState } from 'react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, Divider, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import { makeAuthenticatedApiCall } from '../../apiHelper';
import { CarDeal } from '../../types/deal';
import { useAuth } from '../AuthContext';
import FilesOpener from '../CGForm/FilesOpener';
import FirebaseUploadComponent from '../CreateCotation/FirebaseUploadComponent';

// Définition des types de props
interface EvoCommentProps {
  deal: CarDeal;
  userType: 'evo' | 'agency' | 'other';
}

const EvoComment: React.FC<EvoCommentProps> = ({ deal, userType }) => {
  const {
    control,
    formState: { errors, isSubmitSuccessful },
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      comment: '',
      answer: '',
    },
  });

  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // State to manage uploaded URLs
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isFieldsDisabled, setIsFieldsDisabled] = useState(false);
  const [hasExistingComment, setHasExistingComment] = useState(false);
  const [hasExistingAnswer, setHasExistingAnswer] = useState(false);
  const [commentDate, setCommentDate] = useState<string>('');
  const [answerDate, setAnswerDate] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [allComments, setAllComments] = useState<any[]>([]);

  const commentValue = watch('comment'); // Watch the comment field value
  const answerValue = watch('answer'); // Watch the answer field value

  useEffect(() => {
    if (userType === 'agency' && !currentUser) {
      setIsLoading(true); // Start loading
      const checkUserInterval = setInterval(() => {
        if (currentUser) {
          clearInterval(checkUserInterval);
          setIsLoading(false); // Stop loading when user is found
        } else {
          console.log('Checking if currentUser is not null...');
        }
      }, 1000);

      const timeoutId = setTimeout(() => {
        clearInterval(checkUserInterval);
        if (!currentUser) {
          navigate('/login', { state: { from: location.pathname + location.search } });
        }
        setIsLoading(false); // Stop loading after redirect
      }, 3000);

      // Cleanup function to clear the interval and timeout
      return () => {
        clearInterval(checkUserInterval);
        clearTimeout(timeoutId);
        setIsLoading(false); // Ensure loading stops on cleanup
      };
    }
    fetchExistingComments();
    setIsLoading(false);
  }, [currentUser, userType, navigate]);

  // Effect to block/unblock scrolling
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Reset scrolling
    }

    // Cleanup function to reset scrolling when component unmounts
    return () => {
      document.body.style.overflow = '';
    };
  }, [isLoading]);

  // Function to handle upload completion
  const handleUploadComplete = (url: string) => {
    try {
      setUploadedUrls((prevUrls) => [...prevUrls, url]);
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };

  // Function to handle delete completion
  const handleDeleteComplete = (url: string) => {
    setUploadedUrls((prevUrls) => prevUrls.filter((prevUrl) => prevUrl !== url));
  };

  const fetchExistingComments = async () => {
    try {
      const response = await axios.get(`/api/evo/comment/${deal.pipedrive_deal_id}`);

      if (response.data.length > 0) {
        setAllComments(response.data);

        const evoCommentExists = response.data.some(
          (comment: any) => comment.sender === 'evo' && comment.pipedrive_deal_id === deal.pipedrive_deal_id,
        );

        const agencyCommentExists = response.data.some(
          (comment: any) => comment.sender === 'agency' && comment.pipedrive_deal_id === deal.pipedrive_deal_id,
        );

        setHasExistingComment(evoCommentExists);
        setHasExistingAnswer(agencyCommentExists);

        setIsFieldsDisabled(userType === 'evo' ? evoCommentExists : agencyCommentExists);
      }
    } catch (error) {
      console.error('Error fetching existing comment:', error);
    }
  };

  const onSubmit = async (data: any) => {
    setIsSubmitting(true);
    try {
      await axios.post('/api/evo/comment', {
        dealId: deal.pipedrive_deal_id,
        userType,
        ...data,
        uploadedUrls,
      });
      setSuccessMessage('Commentaire envoyé avec succès');
      setIsFieldsDisabled(true);
    } catch (error) {
      console.error('Error submitting comment:', error);
      setError('comment', {
        type: 'manual',
        message: "Erreur lors de l'envoi du commentaire.",
      });
    }
    if (userType === 'evo') {
      try {
        await axios.post('/api/mails/evo/comment', {
          dealId: deal.pipedrive_deal_id,
          orgId: deal.org_id,
          regPlate: deal.registration_plate,
          userType,
          make: deal.make,
          model: deal.model,
          comment: commentValue,
          url: window.location.href.replace(/datastore=[^&]+/, `datastore=${deal.spark_fds_encrypt}`),
        });
      } catch (error) {
        console.error('Error submitting email / ', error);
      }
    }
    if (userType === 'agency') {
      // Toute la logique liée à la duplication du deal et à son envoi à EVO

      try {
        const response = await makeAuthenticatedApiCall('post', '/api/evo/spark', {
          deal,
          evoCommentsUploadedUrls: uploadedUrls,
        });

        if ((response.data as any).fds_encrypt) {
          console.log('Duplicata créé avec succès:', (response.data as any).fds_encrypt);
        }
      } catch (error) {
        console.error('Erreur lors de la création du duplicata du deal:', error);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Grid container style={{ marginTop: 24, width: 'calc(100% - 24px)' }}>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            scroll: 'none',
            overflow: 'hidden',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginLeft: 24, width: 'calc(100% - 24px)' }}>
        <Divider
          variant="fullWidth"
          component="hr"
          style={{
            marginLeft: 24,
            marginTop: 15,
            backgroundColor: 'black',
            height: '2px',
            width: 'calc(100% - 24px)',
            border: 0,
          }}
        />

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} style={{ marginTop: 15 }}>
            <Typography variant="body1" fontSize={22} color={theme.palette.saffron.main}>
              Commentaires / Remarques / Échanges
            </Typography>
          </Grid>
          {allComments.map((comment: any, index: number) => (
            <React.Fragment key={comment.id || index}>
              {comment.comment && comment.sender === 'evo' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                      Commentaire (EVO){' '}
                      {comment.comment &&
                        comment.sender === 'evo' &&
                        `- ${new Date(comment.created).toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}`}
                      {comment.pipedrive_deal_id !== deal.pipedrive_deal_id
                        ? ` - Deal ${comment.pipedrive_deal_id}`
                        : ''}
                    </Typography>
                    {comment.comment && comment.sender === 'evo' && (
                      <Typography variant="body2" className="inputElement">
                        {comment.comment}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}></Grid>
                </>
              )}
              {comment.comment && comment.sender === 'agency' && (
                <>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                      Réponse (Agence){' '}
                      {comment.comment &&
                        comment.sender === 'agency' &&
                        `- ${new Date(comment.created).toLocaleString('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}`}
                      {comment.pipedrive_deal_id !== deal.pipedrive_deal_id
                        ? ` - Deal ${comment.pipedrive_deal_id}`
                        : ''}
                    </Typography>
                    {comment.comment && comment.sender === 'agency' && (
                      <Typography variant="body2" className="inputElement">
                        {comment.comment}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1">Fichier(s) uploadé(s)</Typography>
                    <FilesOpener FilesUrls={JSON.parse(comment.uploaded_urls || '[]').join(',')} />{' '}
                    {/* Parse and join URLs */}
                  </Grid>
                  <Divider
                    variant="fullWidth"
                    component="hr"
                    style={{
                      marginLeft: 24,
                      marginTop: 15,
                      backgroundColor: '#ccc',
                      height: '1px',
                      width: 'calc(100% - 24px)',
                      border: 0,
                    }}
                  />
                </>
              )}
            </React.Fragment>
          ))}
        </Grid>

        <Grid container spacing={2}>
          {userType === 'evo' && !hasExistingComment && (
            <>
              <Grid item xs={12} md={6} marginTop={2}>
                <Typography variant="body1">Commentaire (EVO)</Typography>
                <Controller
                  name="comment"
                  control={control}
                  rules={{ required: 'Ce champ est requis' }}
                  render={({ field }) => (
                    <TextField
                      id="comment-label"
                      error={!!errors.comment}
                      helperText={errors.comment ? errors.comment.message : ''}
                      fullWidth
                      {...field}
                      value={commentValue} // Keep the comment visible
                      placeholder="La cotation pose problème car ..."
                      multiline
                      rows={4}
                      className="inputElement"
                      disabled={isFieldsDisabled || isSubmitSuccessful} // Disable after successful submission
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}></Grid>
            </>
          )}

          {userType === 'agency' && !hasExistingAnswer && (
            <>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Réponse (Agence)</Typography>
                <Controller
                  name="answer"
                  control={control}
                  rules={{ required: 'Ce champ est requis' }}
                  render={({ field }) => (
                    <TextField
                      id="answer-label"
                      error={!!errors.answer}
                      helperText={errors.answer ? errors.answer.message : ''}
                      fullWidth
                      {...field}
                      value={answerValue} // Keep the answer visible
                      placeholder="Votre réponse ici..."
                      multiline
                      rows={4}
                      className="inputElement"
                      disabled={isFieldsDisabled || isSubmitSuccessful} // Disable after successful submission
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">Uploader un fichier</Typography>
                <FirebaseUploadComponent
                  onUploadComplete={handleUploadComplete}
                  onDeleteComplete={handleDeleteComplete}
                  uploadedUrls={uploadedUrls}
                  source={currentUser?.uid}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} md={12} style={{ marginTop: '20px' }}>
            {((userType === 'evo' && !hasExistingComment) || (userType === 'agency' && !hasExistingAnswer)) && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: theme.palette.saffron.main,
                  color: 'white',
                  fontWeight: 600,
                }}
                disabled={isSubmitting || isSubmitSuccessful || isFieldsDisabled}
              >
                {isSubmitting ? 'Envoi...' : userType === 'agency' ? 'Répondre' : "Envoyer à l'agence"}
              </Button>
            )}
            {successMessage && (
              <Typography variant="body2" color="success" align="center">
                {successMessage}
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default EvoComment;
