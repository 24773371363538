import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// Importez le crochet useUser
import useMediaQuery from '@mui/material/useMediaQuery';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { makeAuthenticatedApiCall } from '../apiHelper';
import {
  OptionType,
  booleanOptions,
  businessTypeOptions,
  coloursOptions,
  conditionsExpertiseOptions,
  detentionOptions,
  documentationOptions,
  duplicateKeysOptions,
  fuelOptions,
  genreOptions,
  mechanicalConditionOptions,
  options,
  testDriveOptions,
  transmissionOptions,
  vehicleOriginOptions,
  vehicleTypeOptions,
  vehicleUseOptions,
} from '../constants/cotation';
import { useArbitratorDeals, useDatabaseDeals, useOrganizationInfo, useSalesPersons } from '../hooks/useApiQueries';
import useFromExternalStore from '../stores/FromExternalStore';
import {
  capitalizeFirstLetter,
  formatDateToDDMMYYYY,
  formatDateToYYYYMMDD,
  formatRegPlate,
  formatString,
} from '../utils';
import { useAuth } from './AuthContext';
import Car from './CreateCotation/Car';
import CarDialogConfirmation from './CreateCotation/CarDialogConfirmation';
import FRE from './CreateCotation/FRE';
import UploadPhotos from './CreateCotation/FirebaseUploadComponent';
import FooterWhenExternal from './CreateCotation/FooterWhenExternal';
import Timeline from './CreateCotation/Timeline';

// Create a schema for form validation
const schema = yup.object().shape({
  reg_plate: yup.string().required('Le champ "Immatriculation" est requis'),
  // vin: yup.string().required('Ce champ est requis'), // non obl

  genre: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Genre" est requis'),
      // label: yup.string().required('Le champ "Genre" est requis'),
    })
    .required('Le champ "Genre" est requis'),
  reg_date: yup.date().required('Le champ "Date de mise en circulation" est requis'),
  fuel: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Carburant" est requis'),
      // label: yup.string().required('Le champ "Carburant" est requis'),
    })
    .required('Le champ "Carburant" est requis'),
  make: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Marque" est requis'),
      // label: yup.string().required('Le champ "Marque" est requis'),
    })
    .required('Le champ "Marque" est requis'),
  model: yup.string().required('Le champ "Modèle" est requis'),
  derivative: yup.string().required('Le champ "Type commercial" est requis'),
  // commercial_finishing: yup.string().required('Ce champ est requis'), // non obl
  transmission: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Transmission" est requis'),
      // label: yup.string().required('Le champ "Transmission" est requis'),
    })
    .required('Le champ "Transmission" est requis'),

  colour: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Couleur" est requis'),
      // label: yup.string(),
    })
    .nullable()
    .required('Le champ "Couleur" est requis'),

  doors: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)) // Transformez NaN en undefined
    .required('Le champ "Nombre de portes" est requis') // Message d'erreur pour les valeurs requises
    .typeError('Ce champ doit être un nombre'), // Message d'erreur pour les valeurs non numériques
  // seats: yup.number().required('Seats is required').min(1, 'Minimum 1 seat').max(50, 'Maximum 50 seats'), // non obl
  kms: yup
    .number()
    .required('Le champ "Kilométrage" est requis')
    .integer('Ce champ doit être un entier')
    .typeError('Ce champ doit être un nombre'),
  kms_dial: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Confirmation kilométrage" est requis'),
      // label: yup.string().required('Le champ "Confirmation kilométrage" est requis'),
    })
    .required('Le champ "Confirmation kilométrage" est requis'),
  // options: yup.string().required('Options is required'), // non obl

  documentation: yup.object().shape({
    value: yup.string().required('Le champ "Carnet d\'entretien" est requis'),
    // label: yup.string().required('Le champ "Carnet d\'entretien" est requis'),
  }), // non obl

  date_last_revision: yup.date().required('Le champ "Date de dernière révision" est requis'),

  kms_last_revision: yup
    .number()
    // .required('Le champ "Kilométrage" est requis') // non obl
    .integer('Ce champ doit être un entier')
    .typeError('Ce champ doit être un nombre'),

  // desired_price: yup.string().required('Ce champ est requis'), // non obl
  vat: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "TVA" est requis'),
      // label: yup.string().required('Le champ "TVA" est requis'),
    })
    .required('Le champ "TVA" est requis'),
  vehicle_origin: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Origine du véhicule" est requis'),
      // label: yup.string().required('Le champ "Origine du véhicule" est requis'),
    })
    .required('Le champ "Origine du véhicule" est requis'),
  vehicle_use: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Utilisation du véhicule" est requis'),
      // label: yup.string().required('Le champ "Utilisation du véhicule" est requis'),
    })
    .required('Le champ "Utilisation du véhicule" est requis'),
  business_type: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Type d\'affaire" est requis'),
      // label: yup.string().required('Le champ "Type d\'affaire" est requis'),
    })
    .required('Le champ "Type d\'affaire" est requis'),

  detention: yup.object().shape({
    value: yup.string().required('Le champ "Durée de détention" est requis'),
    // label: yup.string().required('Le champ "Durée de détention" est requis'),
  }), // non obl

  // histovec_link: yup.string().required('Ce champ est requis'), // non obl

  test_drive: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Essai véhicule" est requis'),
      // label: yup.string().required('Le champ "Essai véhicule" est requis'),
    })
    .required('Le champ "Essai véhicule" est requis'),
  mechanical_condition: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "État mécanique" est requis'),
      // label: yup.string().required('Le champ "État mécanique" est requis'),
    })
    .required('Le champ "État mécanique" est requis'),
  // comp_infos: yup.string().required('Comp infos is required'), // non obl
  conditions_expertise: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Conditions de l\'expertise" est requis'),
      // label: yup.string().required('Le champ "Conditions de l\'expertise'),
    })
    .required('Le champ "Conditions de l\'expertise'),

  vehicle_type: yup
    .object()
    .shape({
      value: yup.string().required('Le champ "Type de véhicule" est requis'),
      // label: yup.string().required('Le champ "Type de véhicule" est requis'),
    })
    .required('Le champ "Type de véhicule'),

  // damages: yup.array().of(yup.string()).required('Damages is required.'),
  // damage_cost: yup.number().required('Damage cost is required'),
  // photos[]

  // location: yup.string().required('Location is required'), // non obl*/
});

const getFranchise = (id: string): string => {
  // Temporairement on va utiliser ça, ensuite on remet le vrai dealer owner
  switch (id) {
    case '37':
      return 'Skipcar'; // Mais en vrai c'est Ewigo
    case '38':
      return 'Bye Buy Car';
    case '39':
      return 'Vell';
    case '40':
      return 'AutoEasy';
    case '41':
      return 'Autres';
    case '52':
      return 'Vago Auto';
    case '53':
      return 'Agence Automobiliere';
    case '56':
      return 'Skipcar Indépendants';
    case '57':
      return 'Skipcar Click and buy auto';
    case '59':
      return 'Biwiz';
    default:
      return 'Unknown'; // or handle the default case as you see fit
  }
};

const CreateCotation = () => {
  const [step, setStep] = useState<number>(1);

  const [uploadedUrls, setUploadedUrls] = useState<{ [key: string]: string[] }>({});
  const [isCarDocumentValid, setIsCarDocumentValid] = useState<boolean>(false);
  const [isFrontPhotoValid, setIsFrontPhotoValid] = useState<boolean>(false); // Pour vérifier si la photo 3/4 avant est présente quand isFromExternal est true

  const [regPlateValue, setRegPlateValue] = useState<string>('');
  const [apiPlaqueError, setApiPlaqueError] = useState<string | null>(null);
  const [isApiPlaqueLoading, setIsApiPlaqueLoading] = useState<boolean>(false);
  const [otherInformationsFromApi, setOtherInformationsFromApi] = useState<string | null>(null);

  const [isCarValid, setIsCarValid] = useState<boolean>(false);
  const [repairTexts, setRepairTexts] = useState({});
  const [carRepairValues, setCarRepairValues] = useState({});
  const [totalCarRepairValue, setTotalCarRepairValue] = useState(0);

  const [isFREValid, setIsFREValid] = useState<boolean>(false);
  const [restorationTexts, setRestorationTexts] = useState({});
  const [restorationValues, setRestorationValues] = useState({});
  const [totalRestorationValue, setTotalRestorationValue] = useState<number>(0);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [submitFormStep, setSubmitFormStep] = useState<string>('');
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
  const [showExternalSuccessMessage, setShowExternalSuccessMessage] = useState<boolean>(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [carRepairConfirmation, setCarRepairConfirmation] = useState(false);

  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const isFromExternal = useFromExternalStore((state) => state.fromExternal);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const orgId = currentUser?.data.data.role === 'god' ? 188 : isFromExternal ? 386 : currentUser?.data.data.idPipedrive; // 188 <=> Skipcar

  const { data: salesPersons } = useSalesPersons(orgId);
  const { data: organizationInfo } = useOrganizationInfo(orgId);
  const { refetch: refetchArbitratorDeals } = useArbitratorDeals();
  const { refetch: refetchUseDatabaseDeals } = useDatabaseDeals();

  const handleValidationCallback = (isValid: boolean) => {
    setIsCarValid(isValid);
  };

  const handleFREValidationCallback = (isValid: boolean) => {
    setIsFREValid(isValid);
  };

  const handleCarDocumentValidationCallback = (isValid: boolean) => {
    setIsCarDocumentValid(isValid);
  };

  const handleUploadComplete = (category: string, url: string) => {
    setUploadedUrls((prevState) => {
      const newState = {
        ...prevState,
        [category]: [...(prevState[category] || []), url],
      };
      return newState;
    });
  };

  const handleDeleteComplete = (category: string, url: string) => {
    setUploadedUrls((prevState) => {
      // On vérifie que la catégorie existe et on filtre l'URL à supprimer
      const filteredUrls = prevState[category]?.filter((existingUrl) => existingUrl !== url) || [];

      // On crée le nouvel état en mettant à jour la catégorie avec les URL filtrées
      const newState = {
        ...prevState,
        [category]: filteredUrls,
      };

      return newState;
    });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirm = () => {
    setOpenDialog(false);
    setCarRepairConfirmation(true);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    trigger(); // Pour que toutes les erreurs s'affichent direect au chargement

    if (step == 2) {
      Object.entries(repairTexts).forEach(([id, repairInfo]) => {
        const svgElement = document.getElementById(id);
        const childElements = svgElement?.querySelectorAll('path, rect, ellipse, circle, polygon, polyline');

        if (childElements) {
          childElements.forEach((childElement) => {
            childElement.style.fill = repairInfo.fill;
          });
        }
      });
    }
  }, [repairTexts, step]);

  useEffect(() => {
    if (step == 3) {
      type RestorationInfo = {
        text: string;
        classes: string;
      };

      const combinedTexts = [...Object.values(repairTexts), ...Object.values(restorationTexts)];
      const allRepairsTexts: string[] = combinedTexts.map((info) => info.text as string);

      setValue('damage_cost', totalRestorationValue + totalCarRepairValue);
      setValue('damages', allRepairsTexts);

      Object.entries<RestorationInfo>(restorationTexts).forEach(([id, restorationInfo]) => {
        const svgElement = document.getElementById(id)?.parentElement;

        if (svgElement) {
          svgElement.className = restorationInfo.classes;
        }
      });
    }
  }, [restorationTexts, totalCarRepairValue, totalRestorationValue, setValue, step]);

  useEffect(() => {
    if (shouldNavigate && !hasSubmitError) {
      navigate('/');
      setShouldNavigate(false);
    }
  }, [shouldNavigate, hasSubmitError]);

  const onSubmit = async (dataPayload: any) => {
    dataPayload.buy_back_date = dayjs().format('DD-MM-YYYY');
    const franchiseId = organizationInfo?.bf580a2a61f8c16cae1aa13670de6fa21184592f;
    dataPayload.franchise = getFranchise(franchiseId);
    dataPayload.reg_plate = formatRegPlate(dataPayload.reg_plate);

    setIsLoading(true);
    setHasSubmitError(false); // Reset error state on each submission attempt

    try {
      setSubmitFormStep('Étape 1/5');
      const postDataPipeDriveResponse = await postDataPipeDrive(dataPayload);
      const dealId: number = postDataPipeDriveResponse.id;
      const addTime: string = postDataPipeDriveResponse.add_time;
      const stageId: number = postDataPipeDriveResponse.stage_id;
      const orgId: number = postDataPipeDriveResponse.org_id.value;
      const orgName: string = postDataPipeDriveResponse.org_id.name;

      setSubmitFormStep('Étape 2/5');
      const fdsEncrypt = await postDataSpark(dataPayload, dealId);

      setSubmitFormStep('Étape 5/5');
      await postDataDB(dataPayload, dealId, addTime, fdsEncrypt, stageId, orgId, orgName);
    } catch (error: any) {
      handleError(error, dataPayload, 'onSubmit');
    } finally {
      setIsLoading(false);

      if (!isFromExternal) {
        setSubmitFormStep('Super ! On vous redirige vers les affaires en cours');
        setShouldNavigate(true); // Set navigation flag here
        refetchArbitratorDeals();
        refetchUseDatabaseDeals();
      } else {
        setShowExternalSuccessMessage(true);
      }
    }
  };

  const postDataPipeDrive = async (dataPayload: any) => {
    const data = {
      title: `${dataPayload.make.label} ${dataPayload.model} ${dataPayload.derivative} | ${dataPayload.reg_plate}`,
      org_id: orgId,
      person_id: dataPayload.sales_person,
      pipeline_id: 3,
      stage_id: isFromExternal ? 49 : 39, // GO : A0
      status: 'open',
      '9b46fdc3826bd4640f115914543718da6f786f37': dataPayload.reg_plate,
      '81d27549b4122664b81ede974fdf0b0915aa2b57': dataPayload.vin,
      '85f18f089962f7c2c07802ad797293eef5a9f292': dataPayload.genre.label,
      '79ac7ef7489f93453caeb3871c7bd293c7162278': dataPayload.reg_date,
      bcb5ff5c08492bc23658db0a705383effa6fcd7d: dataPayload.fuel.label,
      '5443f60884f02f4aebb678c1b031f4cdf2f1bdd7': dataPayload.make.label,
      '02925a65d0ea914762f8a702363401fe042c0682': dataPayload.derivative,
      '9de2c63437cba2b024fc3255c22d4121bbbecbd0': dataPayload.commercial_finishing,
      dca90049866d5fa2089f3d10b4da9dc6711c497e: dataPayload.transmission.label,
      '453ac647ce769c38579a91a07f4b56bba3cb819f': dataPayload.colour.label,
      '3ac3c99b1ece74dc410165422b1687afeead3334': dataPayload.kms,
      '3ea11b0c87c7bdb47335b1dd201040b9cefc143d': dataPayload.business_type.label,
      '33636596285aa82ddfaed970ac44b27ec5c32d19': dataPayload.buy_back_date,
      '011b8243b3763dab3ca191fdc912fe2a9ef3aff7': `${dataPayload.comp_infos} | ${dataPayload.options} | ${dataPayload.comp_infos2}`,
      f77927a5d12ae7b92bbd872c5b927ddd46c199b3: dataPayload.histovec_link,
    };

    try {
      const response = await axios.post(
        `https://api.pipedrive.com/v1/deals?api_token=${import.meta.env.VITE_PIPEDRIVE_API_KEY}`,
        data,
      );
      const { id, add_time, org_id, person_id, stage_id } = response.data.data;
      return { id, add_time, org_id, person_id, stage_id };
    } catch (error: any) {
      handleError(
        error,
        {
          errorMessage: 'Error creating the deal',
          dataPayload,
          functionContext: 'postDataPipeDrive',
        },
        'postDataPipeDrive',
      );
      throw error;
    }
  };

  const postDataDB = async (
    dataPayload: any,
    dealId: number,
    addTime: string,
    fdsEncrypt: number,
    stageId: number,
    orgId: number,
    orgName: string,
  ) => {
    dataPayload.add_time = addTime;
    dataPayload.stage_id = stageId;
    dataPayload.org_id = orgId;
    dataPayload.org_name = orgName;
    dataPayload.sales_person_contact = dataPayload.sales_person_contact;
    dataPayload.reg_date =
      dataPayload.reg_date instanceof Date
        ? dataPayload.reg_date.toISOString().split('T')[0]
        : dataPayload.reg_date.toString().split('T')[0];
    dataPayload.date_last_revision = formatDateToDDMMYYYY(dataPayload.date_last_revision);
    dataPayload.photos = uploadedUrls;
    dataPayload.pipedrive_deal_id = dealId;
    dataPayload.fds_encrypt = fdsEncrypt; // fds_encrypt de l'API Spark
    dataPayload.more_api_infos = otherInformationsFromApi;

    try {
      const response = await makeAuthenticatedApiCall('post', '/api/deal', dataPayload);
      if (response.status < 200 || response.status >= 300) {
        const error = new Error(`Request failed with status ${response.status}`);
        handleError(
          error,
          {
            dataPayload,
            dealId,
            fdsEncrypt,
            response: response,
          },
          'postDataDB',
        );
        throw error;
      }
    } catch (error: any) {
      handleError(
        error,
        {
          dataPayload,
          dealId,
          fdsEncrypt,
          response: error.response,
        },
        'postDataDB',
      );
    }
  };

  const handleError = (error: Error, context: Record<string, any>, where: string) => {
    console.error(`Erreur à ${where}:`, error);

    setSubmitFormStep('Il y a eu un soucis dans le traitement du formulaire. Nos équipes en sont informés');
    setHasSubmitError(true);

    Sentry.withScope((scope) => {
      scope.setTag('where', where);
      Object.keys(context).forEach((key) => {
        scope.setExtra(key, JSON.stringify(context[key]));
      });
      Sentry.captureException(error);
    });
  };

  const postDataSpark = async (dataPayload: any, dealId: number) => {
    const selectedPersonDatas = salesPersons?.find((person) => person?.id === dataPayload?.sales_person);
    const mobilePhoneObject = selectedPersonDatas?.phone?.find((phoneObject: any) => phoneObject.label === 'mobile');
    const mobilePhoneNumber = mobilePhoneObject ? mobilePhoneObject.value : 'Not found';
    const flatUploadedUrls = Object.values(uploadedUrls).flat();

    const dataToPostSpark = {
      type: 'cotation',
      t_s_key: 'SVEHeNoxB4bjTKDjnHqJi15L1fc6MRBYwV1lBqr8NjCZhvYCGP',
      system: 'skipcar',
      data_store: 'jfSg7itBkvOAnemdw7HCEhU5fEbogdZ5e4HAsNP7gBwopI87c4',
      logiwin_lookup: 'false',
      data: {
        ext_ref: `${dealId}`,
        reg_plate: `${dataPayload.reg_plate}`,
        genre: dataPayload.genre.label,
        vin: dataPayload.vin,
        make: dataPayload.make.label,
        model: dataPayload.model,
        derivative: dataPayload.derivative,
        reg_date: formatDateToYYYYMMDD(dataPayload.reg_date), // OBL - registration date YYYY-MM-DD
        fuel: dataPayload.fuel.label,
        transmission: dataPayload.transmission.label,
        colour: dataPayload.colour.label,
        kms: dataPayload.kms,
        kms_dial: dataPayload.kms_dial.value, // mileage confirmation yes/no
        damages: dataPayload.damages,
        damage_cost: `${dataPayload.damage_cost}`,
        comp_infos: `${dataPayload.comp_infos} ${dataPayload.options} ${dataPayload.comp_infos2}`,
        seats: `${dataPayload.seats}`,
        doors: `${dataPayload.doors}`,
        body_style: dataPayload.vehicle_type.value,
        documentation:
          dataPayload.documentation.value === 'update_to_date' || dataPayload.documentation.value === 'not_up_to_date',
        vat: dataPayload.vat.value === 'yes', //Vat applied. Boolean true/false
        foreign: dataPayload.vehicle_origin.value === 'import', //foreign markets Prevenance. Boolean: true/false
        second_hand: dataPayload.vehicle_origin.value === 'second_hand_french', //second hand. Boolean: true/false
        buy_back_date: dayjs().format('YYYY-MM-DD'), //buy back date. Format YYYY-MM-DD
        location: dataPayload.location, //vehicle location
        photos: flatUploadedUrls,
        ext_url_page: `https://app.skipcar.fr/car-deal/${dealId}/?fuel=${encodeURIComponent(
          dataPayload.fuel.value,
        )}&make=${dataPayload.make.value}&datastore=evo`,
        supplier: {
          company: dataPayload.franchise, // organizationInfo?.name, //Dealership
          address: `${organizationInfo?.address_street_number} ${organizationInfo?.address_route || 'Non renseigné'}`, // Address
          post_code: organizationInfo?.address_postal_code || '00000', // Postal
          town: organizationInfo?.address_locality || 'Non renseigné', // Town
          country: organizationInfo?.address_country || 'Non renseigné', // Country
          tel: (organizationInfo && organizationInfo['32050f9ee8ffc46092af27b316c7f9f8623630f0']) || '00 00 00 00 00', // Tel
          fax: '', // Fax
          contact: {
            surname: selectedPersonDatas?.last_name, // surname
            name: selectedPersonDatas?.first_name, // name
            tel: mobilePhoneNumber || '', // tel
            email: currentUser?.email, // OBL - email user.email qui provient de mon authent <=> Email générique de l'agence !!
          },
        },
        response: {
          resp_url: '',
        },
      },
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    try {
      const response = await axios.post('/spark/create/', dataToPostSpark, config);
      // Les réponses de l'API de création de cotation sont :
      // 1) quand tu crées la demande de cotation --> le fds_encrypt (qui correspond à ce qui actuellement est stocké dans le champ "Data Store" de l'Affaire créée)
      // 2) quand un prix est donné (et si tu as donné un url d'API dans "resp_url") --> le prix

      if (response.data && response.data.response && response.data.response.fds_encrypt) {
        // La propriété 'fds_encrypt' existe dans la réponse et peut être utilisée
        // Mise à jour de l'état de l'application pour refléter le succès de la demande
        setIsSubmitting(false); // Réinitialiser l'état de soumission
        setSubmitFormStep('Étape 3/5'); // Mise à jour de l'étape du formulaire, par exemple
        // Appel d'une fonction pour gérer la mise à jour des données en fonction de la réponse
        // Par exemple, mise à jour d'un deal dans votre système avec le fds_encrypt reçu
        await updateDealAfterSpark(dealId, response.data.response.fds_encrypt);
        return response.data.response.fds_encrypt;
      } else {
        // Création d'une nouvelle erreur pour indiquer le problème spécifique
        const fdsEncryptError = new Error('Problème avec la réponse fds_encrypt');
        console.error("Réponse inattendue de l'API, fds_encrypt manquant:", response.data);

        handleError(
          fdsEncryptError,
          {
            dealId,
            dataPayload,
            organizationInfo,
            dataToPostSpark,
            response: response.data,
          },
          'postDataSpark',
        );

        throw fdsEncryptError;
      }
    } catch (error: any) {
      handleError(
        error,
        {
          dealId,
          dataPayload,
          organizationInfo,
          dataToPostSpark,
          response: error.response,
        },
        'postDataSpark',
      );
      throw error;
    }
  };

  const updateDealAfterSpark = async (dealId: number, fdsEncrypt: string) => {
    const data = {
      b8cd89664c069e066439ee3d0a13df5c96c5a1f6: fdsEncrypt, // Data Store
      f927be7a70b8f538bc300ffd94cb6f1c955d0354: fdsEncrypt, // Clé Deal Spark(Data)
      '81292394d28a9b8722d4f9c45bde4bcc52d1a973': `https://apps.tea-digital.com/forms/vendor/dtl?k=${fdsEncrypt}`, // Lien SPARK
    };

    try {
      const response = await axios.put(
        `https://api.pipedrive.com/v1/deals/${dealId}?api_token=${import.meta.env.VITE_PIPEDRIVE_API_KEY}`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setSubmitFormStep('Étape 4/5');
      return response.data;
    } catch (error: any) {
      const apiResponse = error.response ? error.response.data : null;

      handleError(
        new Error('Error updating deal field'),
        {
          dealId,
          data,
          response: apiResponse,
        },
        'updateDealAfterSpark',
      );

      throw error;
    }
  };

  const handleNext = async (e: any) => {
    e.preventDefault(); // Empêche le formulaire d'être soumis

    const isStepValid = await (async () => {
      switch (step) {
        case 1:
          return await trigger([
            'reg_plate',
            'genre',
            'reg_date',
            'fuel',
            'make',
            'derivative',
            'transmission',
            'doors',
            'kms',
            'kms_dial',
            'date_last_revision',
            'vat',
            'vehicle_origin',
            'vehicle_use',
            'vehicle_type',
            'business_type',
            'buy_back_date',
            'colour',
          ]);
        default:
          return true;
        // ajoutez plus de cas si vous avez plus d'étapes
      }
    })();

    if (step == 2 && isStepValid && isCarValid) {
      setStep(step + 1);
    }
    if (isStepValid && step < 6) {
      setStep(step + 1);
    }
  };

  const handlePrevious = (e: any) => {
    e.preventDefault(); // Empêche le formulaire d'être soumis
    if (step > 1) setStep(step - 1);
  };

  // Fonction pour vérifier si la plaque peut être formatée au format "AA-123-AA"
  const canBeFormatted = (regPlate: string) => {
    // Vérifie si la plaque correspond au format "AA123AA", "AA-123AA", "AA123-AA", ou "AA-123-AA" (avec ou sans tirets)
    return /^[A-Z]{2}-?\d{3}-?[A-Z]{2}$/i.test(regPlate);
  };

  const handleGetRegPlateInformations = async () => {
    setIsApiPlaqueLoading(true); // Démarrez l'animation avant la requête
    try {
      const regPlateValue = getValues('reg_plate');
      const formattedRegPlate = formatRegPlate(regPlateValue);

      if (canBeFormatted(formattedRegPlate ?? '')) {
        setValue('reg_plate', formattedRegPlate);
      } else {
        setValue('reg_plate', regPlateValue);
      }

      // Assurez-vous que regPlateValue est une chaîne
      if (typeof regPlateValue !== 'string') {
        setApiPlaqueError("La valeur de la plaque d'immatriculation n'est pas valide.");
        setIsApiPlaqueLoading(false);
        return false;
      }

      // Vérifier si la plaque contient des caractères cyrilliques
      const cyrillicPattern = /[\u0400-\u04FF]/;
      if (cyrillicPattern.test(regPlateValue)) {
        setApiPlaqueError(
          "La plaque d'immatriculation contient des caractères non valides (cyriliques). Veuillez vérifier la plaque puis la retaper à la main plutôt qu'un copier/coller.",
        );
        setIsApiPlaqueLoading(false);
        return false;
      }

      const infoImmatResponse = await makeAuthenticatedApiCall(
        'get',
        `/api/misiv/regplate?regPlate=${encodeURIComponent(formattedRegPlate?.toString() ?? '')}`,
      );
      const vehicleInfoPositiveResponse = infoImmatResponse.data;

      if (vehicleInfoPositiveResponse) {
        setValue('vin', vehicleInfoPositiveResponse.VIN);
        setValue('model', vehicleInfoPositiveResponse.Modele);
        setValue('derivative', vehicleInfoPositiveResponse.Version);

        setValue('registration_plate', formattedRegPlate);

        setValue('seats', vehicleInfoPositiveResponse.NbPlacesAss);
        // Déterminez si le genre du véhicule correspond à une moto ou assimilé
        const isMoto = ['MTT1', 'MTT2', 'CYCL', 'MOT3', 'MOTO', 'MOT2', 'TQM'].includes(
          vehicleInfoPositiveResponse.Genre,
        );

        // Si ce n'est pas une moto et que le nombre de portes est 0, utilisez le nombre de places assises
        if (!isMoto && vehicleInfoPositiveResponse.NbPortes === 0) {
          setValue('doors', vehicleInfoPositiveResponse.NbPlacesAss);
        } else {
          setValue('doors', vehicleInfoPositiveResponse.NbPortes);
        }

        const dateStr = vehicleInfoPositiveResponse.DateMec; // La chaîne de caractères de la date, par exemple "2011-03-29"
        const dateValue = dateStr ? dayjs(dateStr, 'YYYY-MM-DD') : null; // Analyse en utilisant le format correct
        setValue('reg_date', dateValue);

        const genreValue = genreOptions.find((opt) => opt.label === vehicleInfoPositiveResponse.Genre);
        if (genreValue) {
          setValue('genre', { label: genreValue.label, value: genreValue.value });
        }

        // À tester sur Land Rover / Alfa Romeo
        const makeValue = options.find((opt) => opt.value === vehicleInfoPositiveResponse.Marque.toLowerCase());
        if (makeValue) {
          setValue('make', { label: makeValue.label, value: makeValue.value });
        }

        const colourType = (() => {
          switch (vehicleInfoPositiveResponse.Couleur) {
            case 'JAUN':
              return 'JAUNE';
            case 'BLAN':
              return 'BLANC';
            case 'MARR':
              return 'MARRON';
            case 'BEIG':
              return 'BEIGE';
            case 'ROUG':
              return 'ROUGE';
            case 'ORAN':
              return 'ORANGE';
            default:
              return capitalizeFirstLetter(vehicleInfoPositiveResponse.Couleur);
          }
        })();

        // À tester avec Gris clair, etc
        const colourValue = coloursOptions.find((opt) => opt.label === capitalizeFirstLetter(colourType));
        if (colourValue) {
          setValue('colour', { label: colourValue.label, value: colourValue.value });
        }

        const fuelType = (() => {
          switch (vehicleInfoPositiveResponse.Energie) {
            case 'GAZOLE':
              return 'diesel';
            case 'ESSENCE':
              return 'gasoline';
            case 'ESS+ELEC HNR':
              return 'gasoline-hybrid';
            case 'ESS+ELEC HR':
              return 'gasoline-rechargeable';
            case 'GAZ+ELEC HNR':
              return 'diesel-hybrid';
            case 'ELECTRIC':
              return 'electric';
            case 'ESS+G.P.L.':
              return 'gasoline-lpg';
            case 'GAZOLE+GAZNAT':
              return 'gazole+gaznat';
            case 'AIR COMPRIM':
              return 'air-comprime';
            case 'BICARBUR':
              return 'bicarburation';
            case 'BIODIESEL':
              return 'biocarburation';
            case 'ESS+ELEC HR':
              return 'ess+elec-hr';
            case 'ESS+G.P.L.':
              return 'ess+gpl';
            case 'ESS+G.NV+ELEC':
              return 'ess+gnv+elec';
            case 'ESS+G.NAT':
              return 'ess+gnv';
            case 'ES+GZNAT+EL HNR':
              return 'es+gznat+el-hnr';
            case 'ES+GPL+EL HNR':
              return 'es+gpl+el-hnr';
            case 'ESS+GPL+ELEC HR':
              return 'ess+gpl+elec-hr';
            case 'ESSENCE':
              return 'essence';
            case 'ETHANOL':
              return 'ethanol';
            case 'SUPERETHANOL':
              return 'superethanol';
            case 'S.ETHAN+G.P.L.':
              return 's.ethan+gpl';
            case 'ELEC+S.ETH HNR':
              return 'elec+s.eth-hnr';
            case 'ELEC+S.ETHAN':
              return 'elec+s.ethan';
            case 'S.ETHAN+GZNAT':
              return 's.ethan+gznat';
            case 'FUEL-OIL':
              return 'fuel-oil';
            case 'SET+GNAT+EL HR':
              return 'set+gnat+el-hr';
            case 'SET+GNAT+EL HNR':
              return 'set+gnat+el-hnr';
            case 'S.ET+GPL+EL HNR':
              return 's.et+gpl+el-hnr';
            case 'S.ET+GPL+EL HR':
              return 's.et+gpl+el-hr';
            case 'GAZOLE+GPL':
              return 'gazole+gpl';
            case 'GAZOGENE':
              return 'gazogene';
            case 'ESS+GAZO':
              return 'ess+gazo';
            case 'GAZOLE+GAZNAT':
              return 'gazole+gaznat';
            case 'GAZOLE+GAZO':
              return 'gazole+gazo';
            case 'GAZ+ELEC HNR':
              return 'gaz+elec-hnr';
            case 'GAZ+ELEC HR':
              return 'gaz+elec-hr';
            case 'GAZ+GNV+ELEC HR':
              return 'gaz+gnv+elec-hr';
            case 'GAZ NAT.VEH':
              return 'gaz-nat-veh';
            case 'GAZOLE':
              return 'gazole';
            case 'G.P.L.':
              return 'g.p.l.';
            case 'GAZOL+G.NAT+HYB':
              return 'gazol+g.nat+hyb';
            case 'CARB GAZEUX':
              return 'carb-gazeux';
            case 'HYDROGENE':
              return 'hydrogene';
            case 'HYDRO+ELEC HR':
              return 'hydro+elec-hr';
            case 'HYDRO+ELEC HNR':
              return 'hydro+elec-hnr';
            case 'ELEC+G.NAT':
              return 'elec+g.nat';
            case 'GAZ NAT+EL HNR':
              return 'gaz-nat+el-hnr';
            case 'ELEC+G.P.L.':
              return 'elec+g.p.l.';
            case 'ELEC+G.P.L. HNR':
              return 'elec+g.p.l.-hr';
            case 'PETROL.LAMP':
              return 'petrol-lamp';
            case 'AUTRES':
              return 'autres';
            case 'INCONNU':
              return 'inconnu';
            default:
              return null;
          }
        })();
        const fuelValue = fuelOptions.find((opt) => opt.value === fuelType);
        if (fuelValue) {
          setValue('fuel', { label: fuelValue.label, value: fuelValue.value });
        }

        const transmissionType = (() => {
          switch (vehicleInfoPositiveResponse.TPBoiteVit) {
            case 'MECANIQUE':
              return 'MT';
            case 'AUTOMATIQUE':
              return 'AT';
            case 'SEQUENTIELLE':
              return 'ST';
            case 'B.V.A.':
              return 'AT';
            case 'VARIAT.ME':
              return 'AT';
            case 'VARIAT.EL':
              return 'AT';

            default:
              return null;
          }
        })();

        const transmissionValue = transmissionOptions.find((opt) => opt.value === transmissionType);
        if (transmissionValue) {
          setValue('transmission', { label: transmissionValue.label, value: transmissionValue.value });
        }
        /*
        setOtherInformationsFromApi(
          `Nombre de portes : ${vehicleInfoPositiveResponse.NbPortes}, Nombre de places : ${vehicleInfoPositiveResponse.NbPlacesAss}, ` +
            `Carrosserie : ${vehicleInfoPositiveResponse.Carrosserie}, Carrosserie CG : ${vehicleInfoPositiveResponse.CarrosserieCG}, ` +
            `CO2 : ${vehicleInfoPositiveResponse.CO2}, Cylindrée : ${vehicleInfoPositiveResponse.Cylindree}, ` +
            `Genre VCG : ${vehicleInfoPositiveResponse.GenreCG}, Puissance Fiscale : ${vehicleInfoPositiveResponse.PuissFisc}`,
        );
        */

        setOtherInformationsFromApi(
          `Nombre de portes : ${vehicleInfoPositiveResponse.NbPortes}, ` +
            `Nombre de places : ${vehicleInfoPositiveResponse.NbPlacesAss}, ` +
            `Carrosserie : ${vehicleInfoPositiveResponse.Carrosserie}, ` +
            `CarrosserieCG : ${vehicleInfoPositiveResponse.CarrosserieCG}, ` +
            `CO2 : ${vehicleInfoPositiveResponse.CO2}, ` +
            `Cylindrée : ${vehicleInfoPositiveResponse.Cylindree}, ` +
            `Genre VCG : ${vehicleInfoPositiveResponse.GenreCG}, ` +
            `Puissance Fiscale : ${vehicleInfoPositiveResponse.PuissFisc}, ` +
            `Date de mise en circulation : ${vehicleInfoPositiveResponse.DateMec}, ` +
            `Marque : ${vehicleInfoPositiveResponse.Marque}, ` +
            `Modèle : ${vehicleInfoPositiveResponse.Modele}, ` +
            `Version : ${vehicleInfoPositiveResponse.Version}, ` +
            `Energie : ${vehicleInfoPositiveResponse.Energie}, ` +
            `Couleur : ${vehicleInfoPositiveResponse.Couleur}, ` +
            `Type de boîte de vitesses : ${vehicleInfoPositiveResponse.TPBoiteVit}, ` +
            `Nombre de vitesses : ${vehicleInfoPositiveResponse.NbVitesses}, ` +
            `PuisFiscReel : ${vehicleInfoPositiveResponse.PuissCh}, ` +
            `Puissance en kW : ${vehicleInfoPositiveResponse.PuissanceKW}, ` +
            `Type de propulsion : ${vehicleInfoPositiveResponse.Propulsion}, ` +
            `Poids : ${vehicleInfoPositiveResponse.PoidsVide}, ` +
            `Longueur : ${vehicleInfoPositiveResponse.Longueur}, ` +
            `Largeur : ${vehicleInfoPositiveResponse.Largeur}, ` +
            `Hauteur : ${vehicleInfoPositiveResponse.Hauteur}, ` +
            `Empattement : ${vehicleInfoPositiveResponse.Empatement}, ` +
            `VIN : ${vehicleInfoPositiveResponse.VIN}, ` +
            `Turbo / Compresseur : ${vehicleInfoPositiveResponse.TurboCompr}, ` +
            `Nombre de cylindres : ${vehicleInfoPositiveResponse.NbCylind}, ` +
            `Type : ${vehicleInfoPositiveResponse.Type}, ` +
            `Mode d'injection : ${vehicleInfoPositiveResponse.ModeInjection}, ` +
            `Dépollution : ${vehicleInfoPositiveResponse.Depollution}, ` +
            `PTR : ${vehicleInfoPositiveResponse.PTR}`,
        );
      } else {
        setApiPlaqueError("Aucune donnée trouvée pour cette plaque d'immatriculation.");
        return false;
      }

      setIsApiPlaqueLoading(false);
      return true;
    } catch (error: any) {
      const errorMessagePlaque = "Une erreur s'est produite lors de la récupération des données du véhicule";
      setApiPlaqueError(errorMessagePlaque);

      Sentry.withScope((scope) => {
        scope.setTag('where', 'handleGetRegPlateInformations');
        scope.setExtra('regPlateValue', regPlateValue);
        if (error.response) {
          // Inclure des détails de la réponse en cas d'erreur de réponse
          scope.setExtra('response', {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers,
          });
        }
        Sentry.captureException(error);
        return false;
      });
    } finally {
      trigger();
      // Appelle formatRegPlate seulement si la plaque peut être formatée
      setIsApiPlaqueLoading(false); // Arrêtez l'animation après avoir reçu la réponse
    }
  };

  type FormData = {
    business_type: OptionType;
    genre: OptionType;
    colour: OptionType;
    // Et d'autres champs si nécessaire...
  };

  const handleNextWithApiCall = async (e: any) => {
    setIsApiPlaqueLoading(true);
    if (isFromExternal) {
      setValue('vehicle_type', { value: 'bp', label: 'Berline petite' });
      const handleGetRegPlateInformationsResponse = await handleGetRegPlateInformations();
      console.log('handleGetRegPlateInformationsResponse', handleGetRegPlateInformationsResponse);
      if (handleGetRegPlateInformationsResponse) {
        handleNext(e);
      }
    } else {
      handleNext(e);
    }
    setIsApiPlaqueLoading(false);
  };

  return (
    <>
      <Typography variant="h1" align="center" sx={{ margin: { xs: '0.5em auto 1em', md: '1em auto' } }}>
        Demande de cotation
      </Typography>
      <Paper sx={{ marginTop: 1, marginBottom: { xs: 4, md: 0 } }} elevation={0}>
        <Box sx={{ p: { xs: 0, md: 4 } }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={6} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Timeline activeStep={step - 1} />
              {step === 1 && (
                <>
                  {isFromExternal && (
                    <Grid item xs={12} sm={12} sx={{ paddingTop: '0 !important' }}>
                      <Typography variant="h2" sx={{ textAlign: 'center', fontWeight: 600, marginBottom: 2 }}>
                        Bienvenue sur l’outil de cotation Skipcar
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 2,
                          flexWrap: 'wrap',
                        }}
                      >
                        <img
                          src="https://cdn.prod.website-files.com/660e522e73ed3f9b272d86d2/6644ca3e3b742459cb98101f_Ewigo_BS-10-ans%201.webp"
                          alt="Ewigo"
                          style={{ width: '100px', height: 'auto', margin: '10px' }}
                        />
                        <img
                          src="https://aptekaa.dev/logo-cash-sentinel.png"
                          alt="Cash Sentinel"
                          style={{ width: '100px', height: 'auto', margin: '10px' }}
                        />
                        <img
                          src="https://agenceauto.com/img/logo-aa/logo-white-fr-200w.png"
                          alt="Agence Automobilière"
                          style={{ width: '100px', height: 'auto', margin: '10px', filter: 'invert(1)' }}
                        />
                        <img
                          src="https://biwiz.me/build/assets/logo-53e1451a.svg"
                          alt="Biwiz"
                          style={{ width: '100px', height: 'auto', margin: '10px' }}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="reg_plate"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="registration-plate"
                          label="Immatriculation *"
                          className="inputElement"
                          error={!!errors.reg_plate}
                          helperText={errors.reg_plate?.message}
                          {...field}
                          onChange={(e) => {
                            field.onChange(e); // Important : conservez la gestion des événements par défaut de React Hook Form
                            setRegPlateValue(e.target.value);
                          }}
                          fullWidth
                        />
                      )}
                    />
                    {!isFromExternal && (
                      <Button
                        sx={{
                          marginTop: 1,
                          fontWeight: 600,
                          width: 330,
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                        onClick={() => handleGetRegPlateInformations()}
                        variant="contained"
                        disabled={!regPlateValue} // Le bouton sera désactivé si regPlateValue est vide
                      >
                        {isApiPlaqueLoading ? (
                          <CircularProgress size={24} sx={{ width: 330 }} color="inherit" />
                        ) : (
                          'Récupérer les informations du véhicule'
                        )}
                      </Button>
                    )}
                    {apiPlaqueError && (
                      <Typography color="error" variant="body2">
                        {apiPlaqueError}
                      </Typography>
                    )}
                  </Grid>
                  {isFromExternal ? (
                    <>
                      <Controller
                        name="vin"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="genre"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="reg_date"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="make"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="model"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="fuel"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="transmission"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="derivative"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="colour"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="doors"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="kms"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                      <Controller
                        name="options"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <input type="hidden" {...field} />}
                      />
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="vin"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              className="inputElement"
                              id="vin-label"
                              label="Numéro de chassis (VIN)"
                              error={!!errors.vin} // Ajoutez cette ligne pour activer la bordure rouge
                              helperText={errors.vin?.message} // Affichez le message d'erreur
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="genre"
                          control={control}
                          defaultValue={{ value: '', label: '' }}
                          render={({ field }) => {
                            return (
                              <FormControl error={!!errors.genre} fullWidth>
                                <InputLabel className="inputElement" id="genre-label">
                                  Genre
                                </InputLabel>
                                <Select
                                  labelId="genre-label"
                                  label="Genre"
                                  fullWidth
                                  className="inputElement"
                                  error={!!errors.genre}
                                  value={field.value.value || ''}
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      fontWeight: '300',
                                    },
                                    '& .MuiMenuItem-root': {
                                      fontWeight: '300',
                                    },
                                  }}
                                  onChange={(e: SelectChangeEvent<string>) => {
                                    const selectedOption = genreOptions.find(
                                      (option) => option.value === e.target.value,
                                    );
                                    if (selectedOption) {
                                      field.onChange(selectedOption);
                                    }
                                  }}
                                >
                                  {genreOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.genre && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="reg_date"
                          control={control}
                          defaultValue={null}
                          render={({ field }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateField
                                {...field}
                                fullWidth
                                className="inputElement"
                                label="Date de mise en circulation *"
                                format="DD/MM/YYYY"
                                aria-placeholder="JJ/MM/AAAA"
                                error={!!errors.reg_date} // Ajoutez cette ligne pour activer la bordure rouge
                                helperText={errors.reg_date?.message} // Affichez le message d'erreur
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="make"
                          control={control}
                          defaultValue={{ value: '', label: '' }}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <Autocomplete
                                {...field}
                                id="make-label"
                                options={options}
                                getOptionLabel={(option) => option.label}
                                style={{ width: '100%' }}
                                freeSolo
                                forcePopupIcon={true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    helperText={errors.make?.message}
                                    label="Marque *"
                                    fullWidth
                                    className="inputElement"
                                  />
                                )}
                                onChange={(_, data) => field.onChange(data || { value: '', label: '' })}
                                value={field.value || null}
                                error={!!errors.make ? '' : undefined}
                              />
                              {errors.make && (
                                <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="model"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              id="model"
                              label="Modèle *"
                              className="inputElement"
                              error={!!errors.model}
                              helperText={errors.model?.message}
                              {...field}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="fuel"
                          control={control}
                          defaultValue={{ value: '', label: '' }}
                          render={({ field }) => {
                            return (
                              <FormControl error={!!errors.fuel} fullWidth>
                                <InputLabel className="inputElement" id="fuel-label">
                                  Carburant *
                                </InputLabel>
                                <Select
                                  labelId="fuel-label"
                                  label="Carburant *"
                                  className="inputElement"
                                  // Use the value property of the field object if it exists
                                  value={field.value?.value || ''}
                                  onBlur={() => trigger('fuel')}
                                  onChange={(e: SelectChangeEvent<string>) => {
                                    const selectedOption = fuelOptions.find(
                                      (option) => option.value === e.target.value,
                                    );
                                    if (selectedOption) {
                                      // Update the field with the whole selected option object
                                      field.onChange(selectedOption);
                                    }
                                  }}
                                >
                                  {fuelOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.fuel && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="transmission"
                          control={control}
                          defaultValue={{ value: '', label: '' }}
                          render={({ field }) => {
                            return (
                              <FormControl error={!!errors.transmission} fullWidth>
                                <InputLabel className="inputElement" id="transmission-label">
                                  Transmission
                                </InputLabel>
                                <Select
                                  labelId="transmission-label"
                                  label="Transmission"
                                  className="inputElement"
                                  // Use the value property of the field object if it exists
                                  value={field.value?.value || ''}
                                  onChange={(e: SelectChangeEvent<string>) => {
                                    const selectedOption = transmissionOptions.find(
                                      (option) => option.value === e.target.value,
                                    );
                                    if (selectedOption) {
                                      // Update the field with the whole selected option object
                                      field.onChange(selectedOption);
                                    }
                                  }}
                                >
                                  {transmissionOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.transmission && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="derivative"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              id="derivative"
                              label="Type commercial (finition) *"
                              error={!!errors.derivative}
                              helperText={errors.derivative?.message}
                              {...field}
                              fullWidth
                              className="inputElement"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="commercial_finishing"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              id="commercial_finishing-label"
                              label="Finition commerciale"
                              error={!!errors.commercial_finishing}
                              helperText={errors.commercial_finishing?.message}
                              {...field}
                              fullWidth
                              className="inputElement"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="colour"
                          control={control}
                          defaultValue={{ value: '', label: '' }}
                          render={({ field }) => {
                            const selectedOption = coloursOptions.find((colour) => colour.value === field.value.value);
                            return (
                              <FormControl fullWidth>
                                <Autocomplete
                                  {...field}
                                  value={selectedOption || null} // This handles the undefined issue
                                  options={coloursOptions}
                                  getOptionLabel={(option) => option.label}
                                  freeSolo
                                  forcePopupIcon={true}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      helperText={errors.colour?.message}
                                      label="Couleur"
                                      fullWidth
                                      className="inputElement"
                                    />
                                  )}
                                  onChange={(_, data) => {
                                    field.onChange(data || { value: '', label: '' });
                                  }}
                                  onBlur={field.onBlur}
                                  error={!!errors.colour ? '' : undefined}
                                />
                                {errors.colour && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Controller
                          name="seats"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              id="seats-label"
                              label="Nombre de sièges"
                              type="number"
                              error={!!errors.seats}
                              helperText={errors.seats?.message}
                              {...field}
                              fullWidth
                              className="inputElement"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <Controller
                          name="doors"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              id="doors-label"
                              label="Nombre de portes *"
                              error={!!errors.doors}
                              helperText={errors.doors?.message}
                              {...field}
                              fullWidth
                              type="number"
                              className="inputElement"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                        <Controller
                          name="vehicle_type"
                          control={control}
                          defaultValue=""
                          render={({ field }) => {
                            return (
                              <FormControl error={!!errors.vehicle_type} fullWidth>
                                <InputLabel className="inputElement" id="vehicle_type-label">
                                  Type de véhicule
                                </InputLabel>
                                <Select
                                  labelId="vehicle_type-label"
                                  label="Type de véhicule"
                                  className="inputElement"
                                  value={field.value?.value || ''}
                                  onBlur={() => trigger('vehicle_type')}
                                  onChange={(e: SelectChangeEvent<string>) => {
                                    const selectedOption = vehicleTypeOptions.find(
                                      (option) => option.value === e.target.value,
                                    );
                                    if (selectedOption) {
                                      field.onChange(selectedOption);
                                    }
                                  }}
                                >
                                  {vehicleTypeOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.vehicle_type && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Controller
                          name="options"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <>
                              <TextField
                                id="options-label"
                                error={!!errors.options}
                                helperText={errors.options?.message}
                                fullWidth
                                label="Options"
                                {...field}
                                placeholder=""
                                multiline
                                rows={4}
                                className="inputElement"
                              />
                              <Typography variant="body2">
                                Exemple : PM, Clim, Régulateur, GPS Couleur, Porte Latérale, Toit ouvrant, Radars
                                AV...etc
                              </Typography>
                            </>
                          )}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="kms"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="kms"
                          label="Kilométrage *"
                          error={!!errors.kms}
                          helperText={errors.kms?.message}
                          {...field}
                          fullWidth
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Permet l'affichage du clavier numérique sur mobile
                          className="inputElement"
                          onBlur={() => trigger('kms')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="kms_dial"
                      control={control}
                      defaultValue={isFromExternal ? { value: 'yes', label: 'Oui' } : { value: '', label: '' }}
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.kms_dial} fullWidth>
                            <InputLabel className="inputElement" id="kms_dial-label">
                              Confirmation kilométrage *
                            </InputLabel>
                            <Select
                              labelId="kms_dial-label"
                              label="Confirmation kilométrage *"
                              value={field.value?.value || ''}
                              className="inputElement"
                              onBlur={() => trigger('kms_dial')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = booleanOptions.find((option) => option.value === e.target.value);
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {booleanOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.kms_dial && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="documentation"
                      control={control}
                      defaultValue={{ value: '', label: '' }}
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.documentation} fullWidth>
                            <InputLabel className="inputElement" id="documentation-label">
                              Carnet d'entretien
                            </InputLabel>
                            <Select
                              labelId="documentation-label"
                              label="Carnet d'entretien"
                              value={field.value?.value || ''}
                              className="inputElement"
                              onBlur={() => trigger('documentation')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = documentationOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {documentationOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.documentation && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="duplicate_keys"
                      control={control}
                      defaultValue={{ value: '', label: '' }}
                      render={({ field }) => {
                        return (
                          <FormControl fullWidth>
                            <InputLabel className="inputElement" id="duplicate_keys-label">
                              Double des clés
                            </InputLabel>
                            <Select
                              labelId="duplicate_keys-label"
                              label="Double des clés"
                              value={field.value?.value || ''}
                              className="inputElement"
                              onBlur={() => trigger('duplicate_keys')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = duplicateKeysOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {duplicateKeysOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="date_last_revision"
                      control={control}
                      defaultValue={null} // ou une autre valeur par défaut
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateField
                            id="date_last_revision-label"
                            error={!!errors.date_last_revision}
                            helperText={errors.date_last_revision?.message}
                            {...field}
                            label="Date de dernière révision"
                            format="DD/MM/YYYY"
                            aria-placeholder="JJ/MM/AAAA"
                            fullWidth
                            className="inputElement"
                            onBlur={() => trigger('date_last_revision')}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="kms_last_revision"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="kms_last_revision-label"
                          error={!!errors.kms_last_revision}
                          helperText={errors.kms_last_revision?.message}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Permet l'affichage du clavier numérique sur mobile
                          fullWidth
                          label="Kilométrage dernière révision"
                          {...field}
                          className="inputElement"
                          onBlur={() => trigger('kms_last_revision')}
                        />
                      )}
                    />
                  </Grid>
                  {isFromExternal && (
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="mechanical_condition"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => {
                          return (
                            <FormControl error={!!errors.mechanical_condition} fullWidth>
                              <InputLabel className="inputElement" id="mechanical_condition-label">
                                Etat mécanique général du véhicule
                              </InputLabel>
                              <Select
                                className="inputElement"
                                labelId="mechanical_condition-label"
                                label="État mécanique général du véhicule"
                                value={field.value?.value || ''}
                                onBlur={() => trigger('mechanical_condition')}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  const selectedOption = mechanicalConditionOptions.find(
                                    (option) => option.value === e.target.value,
                                  );
                                  if (selectedOption) {
                                    field.onChange(selectedOption);
                                  }
                                }}
                              >
                                {mechanicalConditionOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.mechanical_condition && (
                                <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="vat"
                      control={control}
                      defaultValue={isFromExternal ? { value: 'no', label: 'Non' } : { value: '', label: '' }}
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.vat} fullWidth>
                            <InputLabel className="inputElement" id="vat-label">
                              TVA récupérable *
                            </InputLabel>
                            <Select
                              labelId="vat-label"
                              label="TVA récupérable *"
                              id="vat"
                              value={field.value?.value || ''}
                              className="inputElement"
                              onBlur={() => trigger('vat')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = booleanOptions.find((option) => option.value === e.target.value);
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {booleanOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.vat && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="vehicle_origin"
                      control={control}
                      defaultValue={
                        isFromExternal
                          ? { value: 'second_hand_french', label: "Véhicule français acheté d'occasion" }
                          : { value: '', label: '' }
                      }
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.vehicle_origin} fullWidth>
                            <InputLabel className="inputElement" id="vehicle_origin-label">
                              Origine du véhicule
                            </InputLabel>
                            <Select
                              labelId="vehicle_origin-label"
                              label="Origine du véhicule"
                              value={field.value?.value || ''}
                              className="inputElement"
                              onBlur={() => trigger('vehicle_origin')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = vehicleOriginOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {vehicleOriginOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.vehicle_origin && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="vehicle_use"
                      control={control}
                      defaultValue={
                        isFromExternal
                          ? { value: 'personal_or_corporate', label: 'Véhicule personnel / véhicule d’entreprise' }
                          : { value: '', label: '' }
                      }
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.vehicle_use} fullWidth>
                            <InputLabel className="inputElement" id="vehicle_use-label">
                              Utilisation du véhicule *
                            </InputLabel>
                            <Select
                              value={field.value?.value || ''}
                              labelId="vehicle_use-label"
                              label="Utilisation du véhicule *"
                              className="inputElement"
                              onBlur={() => trigger('vehicle_use')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = vehicleUseOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  // Update the field with the whole selected option object
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {vehicleUseOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.vehicle_use && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="business_type"
                      control={control}
                      defaultValue={
                        isFromExternal
                          ? { value: 'straight_trade_in', label: 'Reprise sèche' }
                          : { value: '', label: '' }
                      }
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.business_type} fullWidth>
                            <InputLabel className="inputElement" id="business_type-label">
                              Type d'affaire *
                            </InputLabel>
                            <Select
                              labelId="business_type-label"
                              label="business_type"
                              className="inputElement"
                              // Use the value property of the field object if it exists
                              value={field.value?.value || ''}
                              onBlur={() => trigger('business_type')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = businessTypeOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  // Update the field with the whole selected option object
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {businessTypeOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.business_type && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="detention"
                      control={control}
                      defaultValue={
                        isFromExternal
                          ? { value: 'more_than_two', label: 'Supérieure à 2 ans' }
                          : { value: '', label: '' }
                      }
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.detention} fullWidth>
                            <InputLabel className="inputElement" id="detention-label">
                              Durée détention véhicule
                            </InputLabel>
                            <Select
                              className="inputElement"
                              labelId="detention-label"
                              label="Durée détention véhicule"
                              value={field.value?.value || ''}
                              onBlur={() => trigger('detention')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = detentionOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  // Update the field with the whole selected option object
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {detentionOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.detention && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="histovec_link"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="histovec_link"
                          label="Lien Histovec"
                          {...field}
                          helperText={errors.histovec_link?.message}
                          error={!!errors.histovec_link}
                          fullWidth
                          className="inputElement"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="desired_price"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="desired_price-label"
                          error={!!errors.desired_price}
                          helperText={errors.desired_price?.message}
                          fullWidth
                          label="Prix désiré par le client"
                          {...field}
                          className="inputElement"
                          onBlur={() => trigger('desired_price')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="market_price"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          id="market_price-label"
                          error={!!errors.market_price}
                          helperText={errors.market_price?.message}
                          fullWidth
                          label="Prix du marché selon vous"
                          {...field}
                          className="inputElement"
                          onBlur={() => trigger('market_price')}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="location"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TextField
                            id="location"
                            label="Lieu où se trouve le véhicule (Adresse complète)"
                            multiline
                            rows={3}
                            {...field}
                            error={!!errors.location}
                            fullWidth
                            helperText={errors.location?.message}
                            className="inputElement"
                          />
                          <Typography variant="body2">Si différent de l'endroit de l'agence</Typography>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box style={{ backgroundColor: theme.palette.saffron.main, height: 2 }}></Box>
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="test_drive"
                      control={control}
                      defaultValue={
                        isFromExternal
                          ? {
                              value: 'assessment_based_on_description',
                              label: 'Expertise faite sur base description client',
                            }
                          : { value: '', label: '' }
                      }
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.test_drive} fullWidth>
                            <InputLabel className="inputElement" id="test_drive-label">
                              Essai véhicule
                            </InputLabel>
                            <Select
                              labelId="test_drive-label"
                              label="Essai véhicule"
                              className="inputElement"
                              value={field.value?.value || ''}
                              onBlur={() => trigger('test_drive')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = testDriveOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {testDriveOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.test_drive && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                  {!isFromExternal && (
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="mechanical_condition"
                        control={control}
                        defaultValue={{ value: '', label: '' }}
                        render={({ field }) => {
                          return (
                            <FormControl error={!!errors.mechanical_condition} fullWidth>
                              <InputLabel className="inputElement" id="mechanical_condition-label">
                                Etat mécanique général du véhicule
                              </InputLabel>
                              <Select
                                className="inputElement"
                                labelId="mechanical_condition-label"
                                label="État mécanique général du véhicule"
                                value={field.value?.value || ''}
                                onBlur={() => trigger('mechanical_condition')}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  const selectedOption = mechanicalConditionOptions.find(
                                    (option) => option.value === e.target.value,
                                  );
                                  if (selectedOption) {
                                    field.onChange(selectedOption);
                                  }
                                }}
                              >
                                {mechanicalConditionOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.mechanical_condition && (
                                <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isFromExternal ? 12 : 6}>
                    <Controller
                      name="comp_infos"
                      control={control}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <TextField
                            helperText={errors?.comp_infos?.message}
                            error={!!errors.comp_infos}
                            fullWidth
                            label="Complément d'information"
                            {...field}
                            multiline
                            rows={5}
                            placeholder=""
                            className="inputElement"
                          />
                          <Typography variant="body2">
                            Champ libre ou complément d'information sur l'état. Exemple : Diag réalisé par l'atelier,
                            pas de rétroviseur rabattable
                          </Typography>
                        </>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} display={isFromExternal ? 'none' : 'block'}>
                    <Controller
                      name="conditions_expertise"
                      control={control}
                      defaultValue={isFromExternal ? { value: 'normal', label: 'Normale' } : { value: '', label: '' }}
                      render={({ field }) => {
                        return (
                          <FormControl error={!!errors.conditions_expertise} fullWidth>
                            <InputLabel className="inputElement" id="conditions_expertise-label">
                              Conditions de l'expertise
                            </InputLabel>
                            <Select
                              className="inputElement"
                              labelId="conditions_expertise-label"
                              label="Conditions de l'expertise"
                              value={field.value?.value || ''}
                              onBlur={() => trigger('conditions_expertise')}
                              onChange={(e: SelectChangeEvent<string>) => {
                                const selectedOption = conditionsExpertiseOptions.find(
                                  (option) => option.value === e.target.value,
                                );
                                if (selectedOption) {
                                  field.onChange(selectedOption);
                                }
                              }}
                            >
                              {conditionsExpertiseOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.conditions_expertise && (
                              <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
              {step === 2 && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Car
                      onValidate={handleValidationCallback}
                      repairTexts={repairTexts}
                      setRepairTexts={setRepairTexts}
                      carRepairValues={carRepairValues}
                      setCarRepairValues={setCarRepairValues}
                      totalCarRepairValue={totalCarRepairValue}
                      setTotalCarRepairValue={setTotalCarRepairValue}
                    />
                  </Grid>
                </>
              )}
              {step === 3 && (
                <>
                  <Grid item xs={12} sm={12}>
                    <FRE
                      onValidate={handleFREValidationCallback}
                      restorationTexts={restorationTexts}
                      setRestorationTexts={setRestorationTexts}
                      restorationValues={restorationValues}
                      setRestorationValues={setRestorationValues}
                      totalRestorationValue={totalRestorationValue}
                      setTotalRestorationValue={setTotalRestorationValue}
                    />
                  </Grid>
                </>
              )}
              {step === 4 && (
                <>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                      }}
                    >
                      <Typography variant="h2" align="center">
                        Résumé
                      </Typography>
                      <Divider variant="inset" component="div" sx={{ margin: 5 }} />
                      <Typography variant="h5" align="center" sx={{ fontWeight: 500, marginBottom: 3 }}>
                        Réparations
                      </Typography>
                      {Object.values(repairTexts).map((repairInfo, index) => (
                        <Typography variant="body2" align="center" key={index}>
                          {formatString(repairInfo.text as string)}
                        </Typography>
                      ))}
                      <Typography align="center" variant="body2" sx={{ margin: 5, textDecoration: 'underline' }}>
                        Total : {totalCarRepairValue} €
                      </Typography>
                      <Divider variant="middle" component="div" />
                      <Typography variant="h5" align="center" sx={{ fontWeight: 500, margin: 3 }}>
                        FRE
                      </Typography>
                      {Object.values(restorationTexts).map((restorationInfo, index) => (
                        <Typography variant="body2" align="center" key={index}>
                          {formatString(restorationInfo.text as string)}
                        </Typography>
                      ))}
                      <Typography align="center" variant="body2" sx={{ margin: 5, textDecoration: 'underline' }}>
                        Total : {totalRestorationValue} €
                      </Typography>
                      <Divider variant="fullWidth" component="div" />
                      <Typography align="center" variant="body1" sx={{ marginTop: 5 }}>
                        Grand Total : {totalRestorationValue + totalCarRepairValue} €
                      </Typography>

                      <Grid item xs={12} sm={6} sx={{ margin: '5em auto 1em' }}>
                        {!isFromExternal ? (
                          <Typography variant="body2" align="center">
                            Avez-vous d'autres informations à nous communiquer ?
                          </Typography>
                        ) : (
                          <Typography variant="body2" align="center">
                            Pour finir, notez nous n’importe quelles informations importantes
                          </Typography>
                        )}
                        <Controller
                          name="comp_infos2"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              label="Informations supplémentaires"
                              {...field}
                              multiline
                              helperText={errors?.comp_infos2?.message}
                              error={!!errors.comp_infos2}
                              fullWidth
                              className="inputElement"
                              rows={4}
                              placeholder="Avez-vous des informations supplémentaires à fournir ?"
                            />
                          )}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
              {step === 5 && (
                <Grid
                  spacing={isMobile ? 0 : 8}
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  item
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{ paddingTop: '0 !important', textAlign: 'center', marginBottom: { xs: 5, sm: 1 } }}
                  >
                    <Typography variant="h5">Fichiers acceptés : PDF, JPG, PNG</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Photo(s) 3/4 Avant
                      {isFromExternal && <Box component="span"> *</Box>}
                    </Typography>
                    <Typography variant="body2">
                      Veuillez prendre une ou plusieurs photos 3/4 avant du véhicule avec plaque d'immatriculation.
                    </Typography>
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['frontRegPlate'] || []}
                      onUploadComplete={(url) => handleUploadComplete('frontRegPlate', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('frontRegPlate', url)}
                      onValidate={handleCarDocumentValidationCallback}
                      isRequired={isFromExternal ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Photo(s) 3/4 Arrière
                    </Typography>
                    <Typography variant="body2">
                      Veuillez prendre une ou plusieurs photos 3/4 arrière du véhicule avec plaque d'immatriculation.
                    </Typography>
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['backRegPlate'] || []}
                      onUploadComplete={(url) => handleUploadComplete('backRegPlate', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('backRegPlate', url)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Intérieur
                    </Typography>
                    <Typography variant="body2" sx={{ height: { xs: 'auto', sm: 58 } }}>
                      Veuillez prendre une ou plusieurs photos de l'intérieur.
                    </Typography>
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['interior'] || []}
                      onUploadComplete={(url) => handleUploadComplete('interior', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('interior', url)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Tableau de bord
                    </Typography>
                    <Typography variant="body2">
                      Veuillez prendre une ou plusieurs photos du tableau de bord moteur tournant avec kilométrage.
                    </Typography>
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['dashboard'] || []}
                      onUploadComplete={(url) => handleUploadComplete('dashboard', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('dashboard', url)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box style={{ marginTop: 10, background: theme.palette.sunburst.main, height: 3 }}></Box>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Factures d'entretiens
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['invoices'] || []}
                      onUploadComplete={(url) => handleUploadComplete('invoices', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('invoices', url)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box style={{ marginTop: 10, background: theme.palette.sunburst.main, height: 3 }}></Box>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Dégats
                    </Typography>
                    <Typography variant="body2">
                      Veuillez prendre des photos de tout type de dégâts du véhicule les unes apres les autres ou
                      séléctionner multiples photos depuis votre bibliotheque.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['damage'] || []}
                      onUploadComplete={(url) => handleUploadComplete('damage', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('damage', url)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box style={{ marginTop: 10, background: theme.palette.sunburst.main, height: 3 }}></Box>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    <Typography variant="h5" sx={{ marginBottom: 1 }}>
                      Carte grise {!isFromExternal && <Box component="span"> *</Box>}
                    </Typography>
                    <Typography variant="body2">Veuillez prendre une photo de la carte grise.</Typography>
                    {isFromExternal && (
                      <Typography variant="body2">
                        ➡️ Plus l'équipe cotation a d'élément plus la cotation sera élevé
                      </Typography>
                    )}
                    <UploadPhotos
                      uploadedUrls={uploadedUrls['carDocument'] || []}
                      onUploadComplete={(url) => handleUploadComplete('carDocument', url)}
                      onDeleteComplete={(url) => handleDeleteComplete('carDocument', url)}
                      onValidate={handleCarDocumentValidationCallback}
                      isRequired={!isFromExternal ? true : false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ marginBottom: { xs: 5, sm: 1 } }}>
                    {!isFromExternal && !isCarDocumentValid && (
                      <Typography align={isMobile ? 'center' : 'inherit'} color="error">
                        Veuillez envoyer les photos de la carte grise
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box
                  sx={(theme) => ({
                    position: 'relative',
                    display: 'flex',
                    flexDirection: isFromExternal && step === 1 ? 'column' : { xs: 'column', md: 'row' },
                    justifyContent: step === 1 ? 'flex-end' : { xs: 'flex-end', md: 'space-between' },
                  })}
                >
                  {step == 1 && (
                    <>
                      <Box sx={isMobile ? { position: 'relative' } : { float: 'left', position: 'absolute', left: 0 }}>
                        {!isFromExternal &&
                          Object.keys(errors).map((errorKey) => {
                            const error = errors[errorKey];
                            // Vérifiez si l'erreur concerne un champ complexe
                            if (error && typeof error === 'object' && error.type === undefined) {
                              return Object.keys(error).map((subKey) => {
                                const subError = error[subKey];
                                return (
                                  <Typography variant="body2" color="error" key={`${errorKey}.${subKey}`}>
                                    {subError.message}
                                  </Typography>
                                );
                              });
                            } else {
                              // Gestion des champs simples
                              return (
                                <Typography variant="body2" color="error" key={errorKey}>
                                  {error.message}
                                </Typography>
                              );
                            }
                          })}
                      </Box>
                      <Button
                        type="button"
                        variant="contained"
                        disabled={isApiPlaqueLoading}
                        onClick={handleNextWithApiCall}
                        sx={{
                          marginRight: 0,
                          marginLeft: 'auto',
                          fontWeight: 600,
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        {isFromExternal ? (
                          isApiPlaqueLoading ? (
                            <>
                              <CircularProgress size={20} sx={{ marginRight: 1 }} />
                              Je passe à l'inspection du véhicule
                            </>
                          ) : (
                            "Je passe à l'inspection du véhicule"
                          )
                        ) : (
                          'Suivant'
                        )}
                      </Button>
                      {isFromExternal && apiPlaqueError && (
                        <Typography
                          align={'center'}
                          sx={{
                            textAlign: 'right',
                            color: 'red !important',
                            fontWeight: { xs: 500, md: 600 },
                          }}
                        >
                          Veuillez entrer une plaque d'immatriculation valide
                        </Typography>
                      )}
                    </>
                  )}
                  {step == 2 && (
                    <>
                      <CarDialogConfirmation
                        open={openDialog}
                        handleClose={handleDialogClose}
                        handleConfirm={handleDialogConfirm}
                      />
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handlePrevious}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                          margin: { xs: '1em auto', md: '0' },
                        }}
                      >
                        Précédent
                      </Button>
                      {!isCarValid && (
                        <Typography
                          align={isMobile ? 'center' : 'inherit'}
                          sx={{
                            color: 'red',
                            fontWeight: { xs: 500, md: 600 },
                          }}
                        >
                          Vérifications obligatoires : pare-brise AV, pneu et optique
                        </Typography>
                      )}
                      <Button
                        type="button"
                        disabled={!isCarValid}
                        variant="contained"
                        onClick={
                          totalCarRepairValue === 0 && !carRepairConfirmation && !isFromExternal
                            ? () => setOpenDialog(true)
                            : (e) => handleNext(e)
                        }
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        {totalCarRepairValue === 0 && !carRepairConfirmation ? 'Je valide' : 'Suivant'}
                      </Button>
                    </>
                  )}
                  {step == 3 && (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handlePrevious}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        Précédent
                      </Button>
                      {!isFREValid && (
                        <Typography
                          align={isMobile ? 'center' : 'inherit'}
                          sx={{
                            color: 'red',
                            fontWeight: { xs: 500, md: 600 },
                          }}
                        >
                          Veuillez vérifier : grêle et kit distribution
                        </Typography>
                      )}
                      <Button
                        type="button"
                        variant="contained"
                        disabled={!isFREValid}
                        onClick={handleNext}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        Suivant
                      </Button>
                    </>
                  )}
                  {step == 4 && (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handlePrevious}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        Précédent
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleNext}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        Suivant
                      </Button>
                    </>
                  )}
                  {step == 5 && (
                    <>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handlePrevious}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        Précédent
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        onClick={handleNext}
                        disabled={!isCarDocumentValid}
                        sx={{
                          width: { xs: '50%', md: 'auto' },
                          margin: { xs: '1em auto', md: '0' },
                          backgroundColor: theme.palette.saffron.main,
                          '&:hover': { backgroundColor: theme.palette.saffron.main },
                        }}
                      >
                        Suivant
                      </Button>
                    </>
                  )}
                  {step === 6 && (
                    <Container
                      sx={{
                        display: 'flex', // Add this line
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        flexDirection: isFromExternal ? 'column' : 'row',
                      }}
                    >
                      {isFromExternal && (
                        <>
                          <Grid item xs={12} sm={12}>
                            <Typography variant="body2" align="center">
                              Veuillez renseigner les informations ci-dessous afin de vous prendre contact avec vous.
                            </Typography>
                          </Grid>
                          <Grid container spacing={2} sx={{ marginTop: 2 }}>
                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="first_name"
                                control={control}
                                defaultValue=""
                                rules={{ required: isFromExternal }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Nom *"
                                    fullWidth
                                    disabled={showExternalSuccessMessage}
                                    className="inputElement"
                                    error={!!errors.first_name}
                                    helperText={errors.first_name ? 'Ce champ est requis' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="last_name"
                                control={control}
                                defaultValue=""
                                rules={{ required: isFromExternal }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Prénom *"
                                    fullWidth
                                    disabled={showExternalSuccessMessage}
                                    className="inputElement"
                                    error={!!errors.last_name}
                                    helperText={errors.last_name ? 'Ce champ est requis' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="phone_number"
                                control={control}
                                defaultValue=""
                                rules={{ required: isFromExternal }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Numéro de téléphone *"
                                    fullWidth
                                    disabled={showExternalSuccessMessage}
                                    className="inputElement"
                                    error={!!errors.phone_number}
                                    helperText={errors.phone_number ? 'Ce champ est requis' : ''}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{ required: isFromExternal, pattern: /^[^@\s]+@[^@\s]+\.[^@\s]+$/ }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Adresse email *"
                                    fullWidth
                                    disabled={showExternalSuccessMessage}
                                    className="inputElement"
                                    error={!!errors.email}
                                    helperText={errors.email ? 'Veuillez entrer un email valide' : ''}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}

                      <Grid
                        container
                        item
                        xs={5}
                        spacing={2}
                        display={isFromExternal ? 'none' : 'initial'}
                        sx={{ marginTop: 2 }}
                      >
                        <Grid item xs={12}>
                          <Controller
                            name="sales_person"
                            control={control}
                            defaultValue={salesPersons && salesPersons?.length > 0 ? salesPersons[0]?.id : ''} // On utilise la première option de salesPersons comme valeur par défaut si elle existe
                            render={({ field }) => (
                              <FormControl error={!!errors.sales_person} fullWidth>
                                <InputLabel id="sales_person-label">Votre nom *</InputLabel>
                                <Select
                                  labelId="sales_person-label"
                                  label="Nom du commercial *"
                                  className="inputElement"
                                  {...field}
                                >
                                  {salesPersons &&
                                    salesPersons?.map((person) => (
                                      <MenuItem key={person.id} value={person.id}>
                                        {person.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                                {errors.sales_person && (
                                  <FormHelperText sx={{ color: '#d32f2f' }}>Ce champ est requis</FormHelperText>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="sales_person_contact"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <>
                                <InputLabel id="sales_person_contact-label" sx={{ fontSize: 16 }}>
                                  Nom et numéro de téléphone sur lequel vous joindre *
                                </InputLabel>
                                <TextField {...field} multiline rows={2} fullWidth className="inputElement" />
                              </>
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        sx={{
                          display: 'flex',
                          flexDirection: isFromExternal ? 'column' : 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <Button
                          type="button"
                          variant="text"
                          sx={{ marginTop: 1, fontWeight: 600 }}
                          onClick={handlePrevious}
                          disabled={showExternalSuccessMessage || isSubmitting}
                        >
                          Je veux vérifier une dernière fois
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            marginTop: 1,
                            fontWeight: 600,
                            backgroundColor: theme.palette.saffron.main,
                            '&:hover': { backgroundColor: theme.palette.saffron.main },
                          }}
                          disabled={
                            showExternalSuccessMessage ||
                            (isFromExternal &&
                              (!watch('first_name') ||
                                !watch('last_name') ||
                                !watch('phone_number') ||
                                !watch('email')))
                          }
                        >
                          Soumettre la demande de cotation
                        </Button>
                      </Grid>
                      {showExternalSuccessMessage && (
                        <Grid item xs={12} sm={12}>
                          <Typography variant="body2" align="center" mt={3}>
                            Votre demande de cotation a bien été envoyée.
                          </Typography>
                          <Typography variant="body2" align="center" mt={1}>
                            Notre équipe de coteur se charge de coter le véhicule, nous vous contactons rapidement.
                          </Typography>
                        </Grid>
                      )}
                    </Container>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
        {isLoading && (
          <Grid container direction="column" justifyContent="center" alignItems="center" className="formSubmitLoading">
            <Typography sx={{ marginBottom: 2 }} variant="body1">
              En cours de traitement
            </Typography>
            <Typography sx={{ marginBottom: 2 }} variant="body2">
              {submitFormStep}
            </Typography>
            <CircularProgress size={70} />
          </Grid>
        )}
      </Paper>
      {isFromExternal && <FooterWhenExternal />}
    </>
  );
};

export default CreateCotation;
