import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const CarSvg = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="car-svg"
      width="100%"
      height="100%"
      style={{
        marginBottom: isMobile ? '0em' : '10em',
        marginLeft: isMobile ? '0em' : '1em',
      }}
      viewBox="-1 0 1505 1800"
    >
      <g id="pare_brise_av" className="st0">
        <path
          d="M772.977 794.349H787.726C807.051 794.349 826.353 795.437 845.56 797.601L846.932 797.755C874.856 800.901 902.473 806.318 929.51 813.958L932.053 814.68L975.222 656.42L934.193 644.617C904.046 635.947 873.177 630.034 841.953 626.97C825.147 625.315 808.269 624.487 791.392 624.487H709.536C692.647 624.487 675.781 625.315 658.975 626.97C627.751 630.046 596.882 635.947 566.735 644.617L525.706 656.42L568.875 814.68L571.418 813.958C598.455 806.318 626.072 800.901 653.996 797.755L655.368 797.601C674.575 795.437 693.877 794.349 713.203 794.349H772.965H772.977Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
      </g>
      <g id="pavillon" className="st0">
        <path
          d="M772.977 1220.54H845.312C874.324 1220.54 903.301 1218.58 932.053 1214.65V825.75L929.51 825.028C902.473 817.388 874.856 811.971 846.933 808.825L845.561 808.671C826.353 806.507 807.051 805.419 787.726 805.419H713.203C693.877 805.419 674.575 806.507 655.368 808.671L653.996 808.825C626.072 811.971 598.455 817.388 571.418 825.028L568.875 825.75V1214.65C597.627 1218.58 626.604 1220.54 655.616 1220.54H772.965H772.977Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
      </g>
      <g id="pare_brise_ar" className="st0">
        <path
          d="M772.977 1429.91H843.479C866.234 1429.91 888.966 1428.51 911.556 1425.74L945.063 1421.63L932.053 1224.14C903.301 1228.06 874.324 1230.03 845.312 1230.03H772.977H727.963H655.628C626.616 1230.03 597.639 1228.06 568.887 1224.14L555.877 1421.63L589.384 1425.74C611.974 1428.52 634.706 1429.91 657.461 1429.91H772.977Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
      </g>
      <g id="pneu_ard" className="st0">
        <path
          d="M1412.24 1444.1C1461.23 1444.1 1500.94 1404.38 1500.94 1355.39C1500.94 1306.4 1461.23 1266.69 1412.24 1266.69C1363.25 1266.69 1323.53 1306.4 1323.53 1355.39C1323.53 1404.38 1363.25 1444.1 1412.24 1444.1Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path
          d="M1412.24 1400.34C1437.06 1400.34 1457.18 1380.22 1457.18 1355.39C1457.18 1330.57 1437.06 1310.45 1412.24 1310.45C1387.41 1310.45 1367.29 1330.57 1367.29 1355.39C1367.29 1380.22 1387.41 1400.34 1412.24 1400.34Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="pneu_avd" className="st0">
        <path
          d="M1412.24 522.761C1461.23 522.761 1500.94 483.047 1500.94 434.057C1500.94 385.068 1461.23 345.354 1412.24 345.354C1363.25 345.354 1323.53 385.068 1323.53 434.057C1323.53 483.047 1363.25 522.761 1412.24 522.761Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path
          d="M1412.24 479.001C1437.06 479.001 1457.18 458.879 1457.18 434.057C1457.18 409.236 1437.06 389.114 1412.24 389.114C1387.41 389.114 1367.29 409.236 1367.29 434.057C1367.29 458.879 1387.41 479.001 1412.24 479.001Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aile_ard" className="st0">
        <path
          d="M1376.75 1468.94C1314.05 1468.94 1263.21 1418.1 1263.21 1355.39C1263.21 1292.69 1314.05 1241.85 1376.75 1241.85H1403.73V1227.05H1373.24C1309.75 1227.05 1257.03 1273.15 1246.71 1333.69H1210L1065.71 1218.2V1267.85L1155.6 1486.68L1187.53 1600.22L1212.37 1632.15H1227.25V1567.1L1291.61 1590.76V1628.6L1321.18 1646.34H1345.94L1355.48 1629.79L1380.31 1619.14L1385.05 1602.58L1403.74 1597.41V1469.82L1376.77 1468.94H1376.75Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1227.24 1567.1V1426.36L1188.11 1371.36" fill="white" />
        <path d="M1227.24 1567.1V1426.36L1188.11 1371.36" stroke="black" strokeMiterlimit="10" />
        <path d="M1385.03 1602.58V1469.21" stroke="black" strokeMiterlimit="10" />
        <path d="M1355.47 1629.79V1466.94" stroke="black" strokeMiterlimit="10" />
        <path
          d="M1321.17 1646.34V1483.13C1317.63 1481.86 1312.95 1479.72 1308.16 1476.03C1300.13 1469.85 1295.95 1462.51 1293.96 1458.29"
          fill="white"
        />
        <path
          d="M1321.17 1646.34V1483.13C1317.63 1481.86 1312.95 1479.72 1308.16 1476.03C1300.13 1469.85 1295.95 1462.51 1293.96 1458.29"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path d="M1291.6 1590.76V1514.65" stroke="black" strokeMiterlimit="10" />
        <path
          d="M1157.08 1491.95L1188.11 1371.36L1078.71 1261.96L1074.61 1289.54L1157.08 1491.95Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aile_avd" className="st0">
        <path
          d="M1376.75 320.516C1314.05 320.516 1263.21 371.35 1263.21 434.057C1263.21 496.765 1314.05 547.598 1376.75 547.598H1409.43V588.402L1351.35 572.435H1302.24L1295.15 564.18H1206.44L1182.79 590.176L1196.98 338.257L1235.23 241.274V292.131L1288.05 271.623V201.145L1351.92 215.266L1360.2 225.911H1380.3L1388.58 237.738L1409.43 244.787V322.019L1376.75 320.528V320.516Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1351.92 215.255V323.118" stroke="black" strokeMiterlimit="10" />
        <path d="M1360.2 225.899V321.723" stroke="black" strokeMiterlimit="10" />
        <path d="M1380.3 225.899V296.862L1394.5 308.689" fill="white" />
        <path d="M1380.3 225.899V296.862L1394.5 308.689" stroke="black" strokeMiterlimit="10" />
        <path d="M1388.58 237.726V303.757" stroke="black" strokeMiterlimit="10" />
        <path d="M1235.23 292.131V546.262" stroke="black" strokeMiterlimit="10" />
        <path d="M1248.67 286.915V430.864L1236.39 446.653" fill="white" />
        <path d="M1248.67 286.915V430.864L1236.39 446.653" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="bas_de_caisse_droit" className="st0">
        <path
          d="M1457.18 605.398V989.142L1451.27 1195.73H1470.5L1483.2 1183.9V615.214L1473.43 605.445L1457.18 605.398Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1473.43 605.445L1470.5 1195.73" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="porte_avd" className="st0">
        <path
          d="M1063.33 949.722L1179.24 935.53H1296.12L1302.24 939.078L1353.1 937.895L1359.01 946.174H1409.43V623.884L1351.35 607.917H1302.24L1295.15 599.638L1206.44 599.662L1182.79 625.658L1068.07 795.969L1063.33 949.722Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1295.15 599.638V935.53" stroke="black" strokeMiterlimit="10" />
        <path d="M1302.24 607.917V788.246" stroke="black" strokeMiterlimit="10" />
        <path d="M1351.35 607.917V937.895" stroke="black" strokeMiterlimit="10" />
        <path d="M1358.42 609.857V788.246" stroke="black" strokeMiterlimit="10" />
        <path
          d="M1234.71 920.166V876.252L1246.51 865.158V920.166H1234.71Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M1195.3 612.056V935.53H1179.24L1063.33 949.722L1068.07 795.969L1182.79 625.658L1195.3 612.056Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="porte_ard" className="st0">
        <path
          d="M1063.33 997.031L1065.7 1159.06L1209.99 1274.56H1237.24C1241.12 1261.01 1253.26 1225.56 1288.05 1198.09C1337.1 1159.38 1393.35 1166.41 1403.73 1167.91C1405.64 1109.77 1407.54 1051.63 1409.45 993.483H1359.03L1353.11 985.204L1302.26 986.387L1296.13 982.839H1179.25L1063.35 997.031H1063.33Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1296.12 982.839V1192.18" stroke="black" strokeMiterlimit="10" />
        <path d="M1302.24 986.387V1092.83" stroke="black" strokeMiterlimit="10" />
        <path d="M1353.1 985.204V1169.08" stroke="black" strokeMiterlimit="10" />
        <path d="M1359.01 993.483V1092.83" stroke="black" strokeMiterlimit="10" />
        <path
          d="M1234.71 1222.94V1179.03L1246.51 1167.93V1222.94H1234.71Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M1195.3 982.839V1263.14L1065.7 1159.06L1063.33 997.031L1179.24 982.839H1195.3Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="bouclier_avd" className="st0">
        <path
          d="M855.767 97.3259L1047.96 96.9829L1135.48 140.743L1146.12 97.3259L1083.44 43.7606L1066.88 14.1926L997.102 0H855.767V97.3259Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1146.12 97.3259L1071.72 52.0041L1004.79 41.7263H855.767" fill="white" />
        <path d="M1146.12 97.3259L1071.72 52.0041L1004.79 41.7263H855.767" stroke="black" strokeMiterlimit="10" />
        <path d="M1083.44 43.7605L1006.56 28.3852H855.767" fill="white" />
        <path d="M1083.44 43.7605L1006.56 28.3852H855.767" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="optique_avd" className="st0">
        <path
          d="M893.023 229.447L880.013 268.477L886.518 282.658L991.189 300.41L987.049 229.447H893.023Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M880.013 268.477L975.222 278.246" stroke="black" strokeMiterlimit="10" />
        <path d="M988.823 262.563L919.043 259.015L906.908 271.233" fill="white" />
        <path d="M988.823 262.563L919.043 259.015L906.908 271.233" stroke="black" strokeMiterlimit="10" />
        <path d="M889.368 240.399H959.09" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="anti-brouillard_avd" className="st0">
        <path
          d="M1036.24 229.447L1042.06 300.41H1098.82V276.685L1067.52 229.447H1036.24Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1098.82 276.685H1065.91" stroke="black" strokeMiterlimit="10" />
        <path d="M1038.66 259.015H1070.43L1082.36 276.685" fill="white" />
        <path d="M1038.66 259.015H1070.43L1082.36 276.685" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="retroviseur_d" className="st0">
        <path
          d="M1024.17 517.664L1053.25 488.593L1105.46 540.798L1115.26 621.293L1084.77 651.784L1026.22 593.239V556.055L1044.4 537.888L1024.17 517.664Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1096.21 640.335L1026.22 570.188" stroke="black" strokeMiterlimit="10" />
        <path d="M1044.4 537.888H1074.36L1087.44 522.785" fill="white" />
        <path d="M1044.4 537.888H1074.36L1087.44 522.785" stroke="black" strokeMiterlimit="10" />
        <path d="M1110.03 626.521L1102.36 575.984" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="optique_ard" className="st0">
        <path
          d="M914.205 1636.67L910.823 1674.4L1033.54 1668.2L1062.15 1639.25L1038.5 1595.49L966.351 1629.79L914.205 1636.67Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M974.631 1643.98L1046.17 1609.7" stroke="black" strokeMiterlimit="10" />
        <path d="M1053.47 1623.19L1019.57 1639.25L993.495 1634.94" fill="white" />
        <path d="M1053.47 1623.19L1019.57 1639.25L993.495 1634.94" stroke="black" strokeMiterlimit="10" />
        <path d="M912.171 1659.35L963.986 1655.8" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="bouclier_ard" className="st0">
        <path
          d="M796.632 1742.17L1008.93 1742.14L1050.32 1706.66L1069.25 1733.86L1059.79 1764.62L1038.5 1781.17L1024.3 1803.64L945.063 1814.29H796.632V1742.17Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M1059.79 1764.62L1010.69 1787.52H796.632" fill="white" />
        <path d="M1059.79 1764.62L1010.69 1787.52H796.632" stroke="black" strokeMiterlimit="10" />
        <path d="M1038.5 1781.17L1010.69 1795.74H796.632" fill="white" />
        <path d="M1038.5 1781.17L1010.69 1795.74H796.632" stroke="black" strokeMiterlimit="10" />
        <path d="M1069.25 1733.86L1019.85 1764.62H958.013" fill="white" />
        <path d="M1069.25 1733.86L1019.85 1764.62H958.013" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="pneu_arg" className="st0">
        <path
          d="M88.7038 1444.1C137.694 1444.1 177.408 1404.38 177.408 1355.39C177.408 1306.4 137.694 1266.69 88.7038 1266.69C39.7141 1266.69 0 1306.4 0 1355.39C0 1404.38 39.7141 1444.1 88.7038 1444.1Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path
          d="M88.7038 1400.34C113.525 1400.34 133.647 1380.22 133.647 1355.39C133.647 1330.57 113.525 1310.45 88.7038 1310.45C63.8824 1310.45 43.7606 1330.57 43.7606 1355.39C43.7606 1380.22 63.8824 1400.34 88.7038 1400.34Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="pneu_avg" className="st0">
        <path
          d="M88.7038 522.761C137.694 522.761 177.408 483.047 177.408 434.057C177.408 385.068 137.694 345.354 88.7038 345.354C39.7141 345.354 0 385.068 0 434.057C0 483.047 39.7141 522.761 88.7038 522.761Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path
          d="M88.7038 479.001C113.525 479.001 133.647 458.879 133.647 434.057C133.647 409.236 113.525 389.114 88.7038 389.114C63.8824 389.114 43.7606 409.236 43.7606 434.057C43.7606 458.879 63.8824 479.001 88.7038 479.001Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aile_arg" className="st0">
        <path
          d="M124.185 1468.94C186.893 1468.94 237.726 1418.1 237.726 1355.39C237.726 1292.69 186.893 1241.85 124.185 1241.85H97.2076V1227.05H127.698C191.186 1227.05 243.912 1273.15 254.225 1333.69H290.937L435.228 1218.2V1267.85L345.342 1486.68L313.408 1600.22L288.571 1632.15H273.693V1567.1L209.329 1590.76V1628.6L179.761 1646.34H154.995L145.462 1629.79L120.625 1619.14L115.895 1602.58L97.1957 1597.41V1469.82L124.174 1468.94H124.185Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M273.705 1567.1V1426.36L312.829 1371.36" fill="white" />
        <path d="M273.705 1567.1V1426.36L312.829 1371.36" stroke="black" strokeMiterlimit="10" />
        <path d="M115.906 1602.58V1469.21" stroke="black" strokeMiterlimit="10" />
        <path d="M145.474 1629.79V1466.94" stroke="black" strokeMiterlimit="10" />
        <path
          d="M179.773 1646.34V1483.13C183.309 1481.86 187.993 1479.72 192.783 1476.03C200.814 1469.85 204.989 1462.51 206.976 1458.29"
          fill="white"
        />
        <path
          d="M179.773 1646.34V1483.13C183.309 1481.86 187.993 1479.72 192.783 1476.03C200.814 1469.85 204.989 1462.51 206.976 1458.29"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path d="M209.341 1590.76V1514.65" stroke="black" strokeMiterlimit="10" />
        <path
          d="M343.863 1491.95L312.829 1371.36L422.23 1261.96L426.334 1289.54L343.863 1491.95Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="aile_avg" className="st0">
        <path
          d="M124.185 320.516C186.893 320.516 237.726 371.35 237.726 434.057C237.726 496.765 186.893 547.598 124.185 547.598H91.5068V588.402L149.59 572.435H198.697L205.793 564.18H294.497L318.151 590.176L303.958 338.257L265.709 241.274V292.131L212.889 271.623V201.145L149.022 215.266L140.743 225.911H120.637L112.358 237.738L91.5068 244.787V322.019L124.185 320.528V320.516Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />

        <path d="M149.022 215.255V323.118" stroke="black" strokeMiterlimit="10" />
        <path d="M140.743 225.899V321.723" stroke="black" strokeMiterlimit="10" />
        <path d="M120.637 225.899V296.862L106.445 308.689" fill="white" />
        <path d="M120.637 225.899V296.862L106.445 308.689" stroke="black" strokeMiterlimit="10" />
        <path d="M112.358 237.726V303.757" stroke="black" strokeMiterlimit="10" />
        <path d="M265.709 292.131V546.262" stroke="black" strokeMiterlimit="10" />
        <path d="M252.274 286.915V430.864L264.55 446.653" fill="white" />
        <path d="M252.274 286.915V430.864L264.55 446.653" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="bas_de_caisse_gauche" className="st0">
        <path
          d="M43.7605 605.398V989.142L49.6741 1195.73H30.4431L17.7407 1183.9V615.214L27.51 605.445L43.7605 605.398Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M27.51 605.445L30.4431 1195.73" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="porte_avg" className="st0">
        <path
          d="M437.606 949.722L321.699 935.53H204.823L198.697 939.078L147.84 937.895L141.926 946.174H91.5068V623.884L149.59 607.917H198.697L205.793 599.638L294.497 599.662L318.151 625.658L432.875 795.969L437.606 949.722Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M205.793 599.638V935.53" stroke="black" strokeMiterlimit="10" />
        <path d="M198.697 607.917V788.246" stroke="black" strokeMiterlimit="10" />
        <path d="M149.59 607.917V937.895" stroke="black" strokeMiterlimit="10" />
        <path d="M142.518 609.857V788.246" stroke="black" strokeMiterlimit="10" />
        <path
          d="M266.23 920.166V876.252L254.426 865.158V920.166H266.23Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M305.638 612.056V935.53H321.699L437.606 949.722L432.875 795.969L318.151 625.658L305.638 612.056Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="porte_arg" className="st0">
        <path
          d="M437.606 997.031L435.24 1159.06L290.949 1274.56H263.699C259.819 1261.01 247.685 1225.56 212.889 1198.09C163.842 1159.38 107.592 1166.41 97.2076 1167.91C95.3034 1109.77 93.3992 1051.63 91.4951 993.483H141.914L147.828 985.204L198.685 986.387L204.811 982.839H321.687L437.594 997.031H437.606Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M204.823 982.839V1192.18" stroke="black" strokeMiterlimit="10" />
        <path d="M198.697 986.387V1092.83" stroke="black" strokeMiterlimit="10" />
        <path d="M147.84 985.204V1169.08" stroke="black" strokeMiterlimit="10" />
        <path d="M141.926 993.483V1092.83" stroke="black" strokeMiterlimit="10" />
        <path
          d="M266.23 1222.94V1179.03L254.426 1167.93V1222.94H266.23Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
        <path
          d="M305.638 982.839V1263.14L435.24 1159.06L437.606 997.031L321.699 982.839H305.638Z"
          fill="white"
          stroke="black"
          strokeMiterlimit="10"
        />
      </g>
      <g id="calandre" className="st0">
        <path d="M953.614 129.508H772.977H727.963H547.326" fill="white" />
        <path d="M953.614 129.508H772.977H727.963H547.326" stroke="black" stroke-width="1.03" strokeMiterlimit="10" />
        <path d="M945.654 147.863H772.977H727.963H555.286" fill="white" />
        <path d="M945.654 147.863H772.977H727.963H555.286" stroke="black" stroke-width="1.01" strokeMiterlimit="10" />
        <path d="M938.558 166.231H772.977H727.963H562.382" fill="white" />
        <path d="M938.558 166.231H772.977H727.963H562.382" stroke="black" stroke-width="0.98" strokeMiterlimit="10" />
        <path d="M868.777 184.587H772.977H727.963H632.163" fill="white" />
        <path d="M868.777 184.587H772.977H727.963H632.163" stroke="black" stroke-width="0.75" strokeMiterlimit="10" />
        <path d="M862.864 202.943H772.977H727.963H638.076" fill="white" />
        <path d="M862.864 202.943H772.977H727.963H638.076" stroke="black" stroke-width="0.73" strokeMiterlimit="10" />
        <path
          d="M727.963 220.861H643.931L630.389 180.956H570.07L542.868 120.637H727.963H772.977H958.073L930.87 180.956H870.551L857.009 220.861H772.977H727.963Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M550.555 138.686H727.963H772.977H950.385" fill="white" />
        <path d="M550.555 138.686H727.963H772.977H950.385" stroke="black" stroke-width="1.02" strokeMiterlimit="10" />
        <path d="M560.017 157.053H727.963H772.977H940.923" fill="white" />
        <path d="M560.017 157.053H727.963H772.977H940.923" stroke="black" stroke-width="0.99" strokeMiterlimit="10" />
        <path d="M567.113 175.409H727.963H772.977H933.827" fill="white" />
        <path d="M567.113 175.409H727.963H772.977H933.827" stroke="black" stroke-width="0.97" strokeMiterlimit="10" />
        <path d="M635.711 193.765H727.963H772.977H865.229" fill="white" />
        <path d="M635.711 193.765H727.963H772.977H865.229" stroke="black" stroke-width="0.74" strokeMiterlimit="10" />
        <path d="M641.624 212.132H727.963H772.977H859.316" fill="white" />
        <path d="M641.624 212.132H727.963H772.977H859.316" stroke="black" stroke-width="0.71" strokeMiterlimit="10" />
      </g>
      <g id="bouclier_avg" className="st0">
        <path
          d="M645.173 97.3259L452.981 96.9829L365.46 140.743L354.815 97.3259L417.499 43.7606L434.058 14.1926L503.838 0H645.173V97.3259Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M354.815 97.3259L429.22 52.0041L496.15 41.7263H645.173" fill="white" />
        <path d="M354.815 97.3259L429.22 52.0041L496.15 41.7263H645.173" stroke="black" strokeMiterlimit="10" />
        <path d="M417.499 43.7605L494.376 28.3852H645.173" fill="white" />
        <path d="M417.499 43.7605L494.376 28.3852H645.173" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="optique_avg" className="st0">
        <path
          d="M607.917 229.447L620.927 268.477L614.422 282.658L509.751 300.41L513.891 229.447H607.917Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M620.927 268.477L525.718 278.246" stroke="black" strokeMiterlimit="10" />
        <path d="M512.117 262.563L581.897 259.015L594.032 271.233" fill="white" />
        <path d="M512.117 262.563L581.897 259.015L594.032 271.233" stroke="black" strokeMiterlimit="10" />
        <path d="M611.572 240.399H541.85" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="anti-brouillard_avg" className="st0">
        <path
          d="M464.702 229.447L458.883 300.41H402.124V276.685L433.419 229.447H464.702Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M402.124 276.685H435.027" stroke="black" strokeMiterlimit="10" />
        <path d="M462.277 259.015H430.509L418.576 276.685" fill="white" />
        <path d="M462.277 259.015H430.509L418.576 276.685" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="retroviseur_g" className="st0">
        <path
          d="M476.765 517.664L447.694 488.593L395.477 540.798L385.684 621.293L416.175 651.784L474.719 593.239V556.055L456.541 537.888L476.765 517.664Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M404.726 640.335L474.719 570.188" stroke="black" strokeMiterlimit="10" />
        <path d="M456.541 537.888H426.583L413.502 522.785" fill="white" />
        <path d="M456.541 537.888H426.583L413.502 522.785" stroke="black" strokeMiterlimit="10" />
        <path d="M390.912 626.521L398.576 575.984" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="capot" className="st0">
        <path
          d="M772.977 615.605H791.392C808.281 615.605 825.147 616.433 841.953 618.088C873.177 621.163 904.046 627.065 934.193 635.735L975.222 647.538V312.829L863.715 296.602L853.402 286.289H727.963H647.538L637.225 296.602L525.718 312.829V647.538L566.747 635.735C596.894 627.065 627.763 621.152 658.987 618.088C675.793 616.433 692.671 615.605 709.548 615.605H772.977Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M853.402 286.277L975.222 647.538" stroke="black" strokeMiterlimit="10" />
        <path d="M863.715 296.602L975.222 613.121" stroke="black" strokeMiterlimit="10" />
        <path d="M647.538 286.277L525.718 647.538" stroke="black" strokeMiterlimit="10" />
        <path d="M637.225 296.602L525.718 613.121" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="optique_arg" className="st0">
        <path
          d="M586.735 1636.67L590.117 1674.4L467.398 1668.2L438.788 1639.25L462.443 1595.49L534.589 1629.79L586.735 1636.67Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M526.31 1643.98L454.755 1609.7" stroke="black" strokeMiterlimit="10" />
        <path d="M447.47 1623.19L481.366 1639.25L507.445 1634.94" fill="white" />
        <path d="M447.47 1623.19L481.366 1639.25L507.445 1634.94" stroke="black" strokeMiterlimit="10" />
        <path d="M588.769 1659.35L536.954 1655.8" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="bouclier_arg" className="st0">
        <path
          d="M704.309 1742.17L492.011 1742.14L450.616 1706.66L431.692 1733.86L441.154 1764.62L462.443 1781.17L476.635 1803.64L555.877 1814.29H704.309V1742.17Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M441.154 1764.62L490.237 1787.52H704.309" fill="white" />
        <path d="M441.154 1764.62L490.237 1787.52H704.309" stroke="black" strokeMiterlimit="10" />
        <path d="M462.443 1781.17L490.237 1795.74H704.309" fill="white" />
        <path d="M462.443 1781.17L490.237 1795.74H704.309" stroke="black" strokeMiterlimit="10" />
        <path d="M431.692 1733.86L481.094 1764.62H542.927" fill="white" />
        <path d="M431.692 1733.86L481.094 1764.62H542.927" stroke="black" strokeMiterlimit="10" />
      </g>
      <g id="hayon" className="st0">
        <path
          d="M772.977 1673.55H836.253L848.08 1614.41L901.302 1607.31L980.544 1581.71V1419.26L941.148 1224.14L955.707 1433.45L915.199 1438.98C890.196 1442.38 864.981 1444.1 839.742 1444.1H661.199C635.959 1444.1 610.744 1442.38 585.741 1438.98L545.233 1433.45L559.792 1224.14L520.396 1419.26V1581.71L599.638 1607.31L652.86 1614.41L664.688 1673.55H772.977Z"
          fill="white"
          stroke="black"
          stroke-width="3"
          strokeMiterlimit="10"
        />
        <path d="M848.08 1614.41L955.707 1433.45" stroke="black" strokeMiterlimit="10" />
        <path d="M652.86 1614.41L545.233 1433.45" stroke="black" strokeMiterlimit="10" />
      </g>
    </svg>
  );
};
