// packages/frontend/src/hooks/useApiQueries.ts
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';

import { makeAuthenticatedApiCall } from '../apiHelper';
import { CGListData, CGStatistics } from '../types/cg';
import { DBDeal, FranchiseData, MarketplaceDeal } from '../types/deal';

interface MonthlyStats {
  currentMonth: {
    totalCotations: number;
    totalCotationsPotentialValue: number;
    totalBuying: number;
    totalBuyingValue: number;
    totalSelling: number;
    totalSellingValue: number;
    totalNetMarginValue: number;
  };
  previousMonth: {
    totalCotations: number;
    totalCotationsPotentialValue: number;
    totalBuying: number;
    totalBuyingValue: number;
    totalSelling: number;
    totalSellingValue: number;
    totalNetMarginValue: number;
  };
}

interface DailyBuyingStats {
  date: string;
  quotations: number;
  purchases: number;
  transformation_rate: number;
}

interface DailySellingStats {
  date: string;
  net_amount: number;
  net_margin: number;
}

interface AgencyPerformance {
  cotations: {
    org_name: string;
    count: number;
  }[];
  buying: {
    org_name: string;
    count: number;
  }[];
  selling: {
    org_name: string;
    count: number;
    net_amount: string;
    net_margin: number;
  }[];
}

const fetchDealExistence = async (id: number): Promise<boolean> => {
  if (id < 8718) {
    // Premier ID en base, évite de tout tester
    return false;
  }

  try {
    const response = await axios.get(`/api/deals/exists/${id}`);
    return response.data.exists;
  } catch (error) {
    console.error('Error checking deal existence:', error);
    throw error;
  }
};

export const useDealExistence = (pipedriveDealId: number) => {
  return useQuery({
    queryKey: ['dealExistence', pipedriveDealId],
    queryFn: () => fetchDealExistence(pipedriveDealId),
  });
};

const fetchSalesPersons = async (orgId: string): Promise<any[]> => {
  try {
    const response = await axios.get(`/api/pipedrive/sales-persons?orgId=${orgId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching sales persons:', error);
    throw error;
  }
};

export const useSalesPersons = (orgId: string) => {
  return useQuery({
    queryKey: ['salesPersons', orgId],
    queryFn: () => fetchSalesPersons(orgId),
  });
};

const fetchOrganizationInfo = async (orgId: string) => {
  const response = await axios.get(`/api/pipedrive/organizations/${orgId}`);
  return response.data;
};

export const useOrganizationInfo = (orgId: string) => {
  return useQuery({
    queryKey: ['organizationInfo', orgId],
    queryFn: () => fetchOrganizationInfo(orgId),
  });
};

const fetchPersonInfo = async (personId: string) => {
  const response = await axios.get(`/api/pipedrive/persons/${personId}`);
  return response.data;
};

export const usePersonInfo = (orgId: string) => {
  return useQuery({
    queryKey: ['personInfo', orgId],
    queryFn: () => fetchPersonInfo(orgId),
  });
};

const fetchStages = async () => {
  try {
    const response = await axios.get('/api/pipedrive/stages');
    return response.data;
  } catch (error) {
    console.error('Error fetching stages:', error);
    throw error;
  }
};

export const useStages = () => {
  return useQuery({
    queryKey: ['stages'],
    queryFn: fetchStages,
    staleTime: 2 * 7 * 24 * 60 * 60 * 1000, // 2 weeks in milliseconds
  });
};

const fetchPipelines = async () => {
  try {
    const response = await axios.get('/api/pipedrive/pipelines');
    return response.data;
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    throw error;
  }
};

export const usePipelines = () => {
  return useQuery({
    queryKey: ['pipelines'],
    queryFn: fetchPipelines,
    staleTime: 2 * 7 * 24 * 60 * 60 * 1000, // 2 weeks in milliseconds
  });
};

const fetchPipedriveDealInfo = async (dealId: string) => {
  const response = await axios.get(`/api/pipedrive/deals/${dealId}`);
  return response.data;
};

export const usePipedriveDealInfo = (dealId: string) => {
  return useQuery({
    queryKey: ['usePipedriveDealInfo', dealId],
    queryFn: () => fetchPipedriveDealInfo(dealId),
  });
};

const fetchDatabaseDealInfo = async (dealId: number, make: string, fuel: string) => {
  if (dealId < 8718) {
    // Premier ID en base, évite de tout tester
    return false;
  }
  const response = await axios.get(`/api/deals/${dealId}`, {
    params: {
      make,
      fuel,
    },
  });
  return response.data;
};

export const useDatabaseDealInfo = (dealId: number, make: string, fuel: string) => {
  return useQuery({
    queryKey: ['useDatabaseDealInfo', dealId],
    queryFn: () => fetchDatabaseDealInfo(dealId, make, fuel),
  });
};

const fetchDatabaseDealInfoAuth = async (dealId: number) => {
  if (dealId >= 8929) {
    const response = await makeAuthenticatedApiCall('get', `/api/deals/${dealId}/auth`);
    return response;
  }
  return '';
};

export const useDatabaseDealInfoAuth = (dealId: number) => {
  return useQuery({
    queryKey: ['useDatabaseDealInfoAuth', dealId],
    queryFn: () => fetchDatabaseDealInfoAuth(dealId),
  });
};

interface DatabaseDealsResponse {
  data: DBDeal[];
}

export const useDatabaseDeals = (orgId?: number | null) => {
  return useQuery({
    queryKey: ['useDatabaseDeals', orgId],
    queryFn: () => fetchDatabaseDeals(orgId),
  });
};

const fetchDatabaseDeals = async (orgId?: number | null) => {
  const baseEndpoint = `/api/deals`;
  const endpoint = orgId ? `${baseEndpoint}?orgId=${orgId}` : baseEndpoint;
  const response = (await makeAuthenticatedApiCall('get', endpoint)) as AxiosResponse<DatabaseDealsResponse>;
  return response;
};

const fetchMarketplaceDeals = async () => {
  try {
    const response = await makeAuthenticatedApiCall<readonly MarketplaceDeal[]>('get', '/api/deals/marketplace');
    return response;
  } catch (error) {
    // Gérer l'erreur selon les besoins
    throw new Error('Erreur lors de la récupération des marketplace deals');
  }
};

export const useMarketplaceDeals = () => {
  return useQuery({
    queryKey: ['useMarketplaceDeals'],
    queryFn: fetchMarketplaceDeals,
  });
};

const fetchArbitratorDeals = async (status?: string, limit?: number, orderByDateField?: boolean) => {
  const params = new URLSearchParams();
  if (status) params.append('status', status);
  if (limit) params.append('limit', limit.toString());
  if (orderByDateField) params.append('orderByDateField', orderByDateField.toString());

  const url = `/api/deals/arbitrator?${params.toString()}`;

  const response = await makeAuthenticatedApiCall('get', url);
  return response;
};

export const useArbitratorDeals = (status?: string, limit?: number, orderByDateField?: boolean) => {
  return useQuery({
    queryKey: ['useArbitratorDeals', status, limit, orderByDateField], // Inclure status, limit, et orderByDateField dans la clé de requête
    queryFn: () => fetchArbitratorDeals(status, limit, orderByDateField), // Passer status, limit, et orderByDateField à la fonction fetch
    refetchOnWindowFocus: false,
  });
};

const fetchArbitratorOffersForAgency = async (orgId: number) => {
  try {
    const response = await makeAuthenticatedApiCall('get', `/api/deals/arbitrator/${orgId}`);
    return response;
  } catch (error) {
    // Gérer l'erreur selon les besoins
    throw new Error('Erreur lors de la récupération des offres d’arbitrage pour l’agence');
  }
};

export const useArbitratorOffersForAgency = (orgId: number) => {
  return useQuery({
    queryKey: ['useArbitratorOffersForAgency', orgId],
    queryFn: () => fetchArbitratorOffersForAgency(orgId),
    enabled: !!orgId,
  });
};

const fetchArbitrationDeal = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/arbitration/${dealId}`);
  return response;
};

export const useArbitrationDeal = (dealId: number, shouldFetch: boolean = true) => {
  return useQuery({
    queryKey: ['useArbitrationDeal', dealId],
    queryFn: () => fetchArbitrationDeal(dealId),
    enabled: shouldFetch, // Exécute la requête uniquement si shouldFetch est true
    refetchOnWindowFocus: false,
    staleTime: 2000,
  });
};

export const useDealMisivA = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivA', dealId],
    queryFn: () => fetchDealMisivA(dealId),
  });
};
const fetchDealMisivA = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/a/${dealId}`);
  return response;
};

export const useDealMisivB = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivB', dealId],
    queryFn: () => fetchDealMisivB(dealId),
  });
};
const fetchDealMisivB = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/b/${dealId}`);
  return response;
};

export const useDealMisivC = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivC', dealId],
    queryFn: () => fetchDealMisivC(dealId),
  });
};
const fetchDealMisivC = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/c/${dealId}`);
  return response;
};

export const useDealMisivE = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivE', dealId],
    queryFn: () => fetchDealMisivE(dealId),
  });
};
const fetchDealMisivE = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/e/${dealId}`);
  return response;
};
export const useDealMisivF = (dealId: number) => {
  return useQuery({
    queryKey: ['useDealMisivF', dealId],
    queryFn: () => fetchDealMisivF(dealId),
  });
};
const fetchDealMisivF = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deals/f/${dealId}`);
  return response;
};

const fetchDepositSaleBuyer = async (dealId: number) => {
  const response = await makeAuthenticatedApiCall('get', `/api/misiv/deposit-sale-buyer/${dealId}`);
  return response.data;
};

export const useDepositSaleBuyer = (dealId: number) => {
  return useQuery({
    queryKey: ['useDepositSaleBuyer', dealId],
    queryFn: () => fetchDepositSaleBuyer(dealId),
  });
};

const fetchCGList = async (
  userPipedrive: number,
  userRole: string,
  page: number,
  limit: number,
  searchQuery: string,
  stageId?: number | null,
  sortColumn: string = 'R.RegistrationID',
  sortOrder: string = 'DESC',
  queryNonVisible: boolean = false,
): Promise<CGListData> => {
  const stageIdParam = stageId ? `&stage_id=${stageId}` : '';
  const visibleParam = queryNonVisible ? '&visible=hidden' : '&visible=1';
  const response = await makeAuthenticatedApiCall<CGListData>(
    'get',
    `/api/cg/?org_id=${userPipedrive}&user_role=${userRole}&page=${page}&limit=${limit}&search=${searchQuery}${stageIdParam}&sortColumn=${sortColumn}&sortOrder=${sortOrder}${visibleParam}`,
  );
  return response.data;
};

export const useCGList = (
  userPipedrive: number,
  userRole: string,
  page: number,
  limit: number,
  searchQuery: string,
  stageId?: number | null,
  sortColumn: string = 'R.RegistrationID',
  sortOrder: string = 'DESC',
  queryNonVisible: boolean = false,
) => {
  return useQuery<CGListData>({
    queryKey: [
      'useCGList',
      userPipedrive,
      userRole,
      page,
      limit,
      searchQuery,
      stageId,
      sortColumn,
      sortOrder,
      queryNonVisible,
    ],
    queryFn: () =>
      fetchCGList(userPipedrive, userRole, page, limit, searchQuery, stageId, sortColumn, sortOrder, queryNonVisible),
    staleTime: 0,
    refetchOnWindowFocus: true,
  });
};

export const useCGData = (registrationId: string) => {
  return useQuery({
    queryKey: ['useCGData'],
    queryFn: () => fetchCGData(registrationId),
  });
};

const fetchCGData = async (registrationId: string) => {
  const response = await makeAuthenticatedApiCall('get', `/api/cg/${registrationId}`);
  return response.data;
};

export const useDealsByIds = (ids: number[]) => {
  return useQuery({
    queryKey: ['useDealsByIds'],
    queryFn: () => fetchDealsByIds(ids),
  });
};

const fetchDealsByIds = async (ids: number[]) => {
  const response = await makeAuthenticatedApiCall('post', '/api/deals-by-ids', { ids });
  return response.data;
};

/*
export const useDealQonto = (dealId: number, pipelineLetter: string) => {
  return useQuery({
    queryKey: ['useDealQonto', dealId, pipelineLetter],
    queryFn: () => fetchDealQonto(dealId, pipelineLetter),
    staleTime: 0,
  });
};

const fetchDealQonto = async (dealId: number, pipelineLetter: string) => {
  const response = await makeAuthenticatedApiCall('get', `/api/qonto/deals/${pipelineLetter}/${dealId}`);
  return response?.data;
};

*/

export const useCGStatistics = () => {
  return useQuery({
    queryKey: ['useCGStatistics'],
    queryFn: () => fetchCGStatistics(),
  });
};

const fetchCGStatistics = async () => {
  const response = await makeAuthenticatedApiCall<CGStatistics>('get', `/api/cg/statistics`);
  return response.data;
};

export const useGetFranchise = () => {
  return useQuery({
    queryKey: ['useGetFranchise'],
    queryFn: () => fetchGetFranchise(),
    staleTime: 2 * 7 * 24 * 60 * 60 * 1000, // 2 weeks in milliseconds
  });
};

const fetchGetFranchise = async () => {
  const response = await makeAuthenticatedApiCall<FranchiseData>('get', `/api/statistics/franchise`);
  return response.data;
};

export const useGetAgencies = () => {
  return useQuery({
    queryKey: ['useGetAgencies'],
    queryFn: () => fetchGetAgencies(),
    staleTime: 2 * 7 * 24 * 60 * 60 * 1000, // 2 weeks in milliseconds
  });
};

const fetchGetAgencies = async () => {
  const response = await makeAuthenticatedApiCall('get', `/api/statistics/agencies`);
  return response.data;
};

// Hook pour obtenir les statistiques de cotations par date
const fetchStatisticsCountByDate = async (startDate: string, endDate: string, franchise: string, agency: string) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/count-by-date?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
  );
  return response.data;
};

export const useStatisticsCountByDate = (startDate: Date, endDate: Date, franchise: string, agency: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  return useQuery({
    queryKey: ['statisticsCountByDate', formattedStartDate, formattedEndDate, franchise, agency],
    queryFn: () => fetchStatisticsCountByDate(formattedStartDate, formattedEndDate, franchise, agency),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures
  });
};

// Hook pour les statistiques d'achats
const fetchBuyingStatistics = async (startDate: string, endDate: string, franchise: string, agency: string) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/buying?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
  );
  return response.data;
};

export const useBuyingStatistics = (startDate: Date, endDate: Date, franchise: string, agency: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  return useQuery({
    queryKey: ['buyingStatistics', formattedStartDate, formattedEndDate, franchise, agency],
    queryFn: () => fetchBuyingStatistics(formattedStartDate, formattedEndDate, franchise, agency),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures
  });
};

// Hook pour les statistiques de ventes
const fetchSellingStatistics = async (startDate: string, endDate: string, franchise: string, agency: string) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/selling?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
  );
  return response.data;
};

export const useSellingStatistics = (startDate: Date, endDate: Date, franchise: string, agency: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  return useQuery({
    queryKey: ['sellingStatistics', formattedStartDate, formattedEndDate, franchise, agency],
    queryFn: () => fetchSellingStatistics(formattedStartDate, formattedEndDate, franchise, agency),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures
  });
};

// Hook pour les statistiques d'arbitrages
const fetchArbitrationStatistics = async (
  type: string,
  startDate: string,
  endDate: string,
  franchise: string,
  agency: string,
) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/arbitrations/${type}?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
  );
  return response.data;
};

export const useArbitrationStatistics = (
  type: string,
  startDate: Date,
  endDate: Date,
  franchise: string,
  agency: string,
) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  return useQuery({
    queryKey: ['arbitrationStatistics', type, formattedStartDate, formattedEndDate, franchise, agency],
    queryFn: () => fetchArbitrationStatistics(type, formattedStartDate, formattedEndDate, franchise, agency),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures
  });
};

// Hook pour récupérer les agences top et flop
const fetchAgencyPerformance = async (
  performanceType: 'top' | 'flop',
  startDate: string,
  endDate: string,
  franchise: string,
) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/agencies/${performanceType}?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}`,
  );
  return response.data;
};

export const useAgencyPerformance = (
  performanceType: 'top' | 'flop',
  startDate: Date,
  endDate: Date,
  franchise: string,
) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  return useQuery({
    queryKey: ['agencyPerformance', performanceType, formattedStartDate, formattedEndDate, franchise],
    queryFn: () => fetchAgencyPerformance(performanceType, formattedStartDate, formattedEndDate, franchise),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures
  });
};

const fetchArbitrationPerformance = async (
  performanceType: 'top' | 'flop',
  startDate: string,
  endDate: string,
  franchise: string,
) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/arbitrations/${performanceType}?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}`,
  );
  return response.data;
};

export const useArbitrationPerformance = (
  performanceType: 'top' | 'flop',
  startDate: Date,
  endDate: Date,
  franchise: string,
) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');
  return useQuery({
    queryKey: ['arbitrationPerformance', performanceType, formattedStartDate, formattedEndDate, franchise],
    queryFn: () => fetchArbitrationPerformance(performanceType, formattedStartDate, formattedEndDate, franchise),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures
  });
};

const fetchStockOffers = async (franchise?: string, orgName?: string) => {
  const url =
    `/api/statistics/stock/offers?` +
    (franchise ? `&franchise=${encodeURIComponent(franchise)}` : '') +
    (orgName ? `&orgName=${encodeURIComponent(orgName)}` : '');
  const response = await makeAuthenticatedApiCall('get', url);
  return response.data;
};

export const useStockOffers = (franchise?: string, orgName?: string) => {
  return useQuery({
    queryKey: ['stockOffers', franchise, orgName],
    queryFn: () => fetchStockOffers(franchise, orgName),
    staleTime: 24 * 60 * 60 * 1000, // 1 day in milliseconds
  });
};

const fetchStock = async (franchise?: string, orgName?: string) => {
  const params = new URLSearchParams();
  if (franchise) params.append('franchise', franchise);
  if (orgName) params.append('orgName', orgName);
  const url = `/api/statistics/stock?${params.toString()}`;
  const response = await makeAuthenticatedApiCall('get', url);
  return response.data;
};

export const useStock = (franchise?: string, orgName?: string) => {
  return useQuery({
    queryKey: ['stock', franchise, orgName],
    queryFn: () => fetchStock(franchise, orgName),
    staleTime: 24 * 60 * 60 * 1000, // 1 day in milliseconds
  });
};

export const fetchTopAgencies = async (startDate: string, endDate: string, franchise: string) => {
  const response = await makeAuthenticatedApiCall<AgencyPerformance[]>(
    'get',
    `/api/statistics/agencies/top?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}`,
  );
  return response.data;
};

export const useTopAgencies = (startDate: Date, endDate: Date, franchise: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  return useQuery({
    queryKey: ['topAgencies', formattedStartDate, formattedEndDate, franchise],
    queryFn: () => fetchTopAgencies(formattedStartDate, formattedEndDate, franchise),
    staleTime: 24 * 60 * 60 * 1000, // 1 day in milliseconds
  });
};

export const fetchFlopTransformations = async (startDate: string, endDate: string, franchise: string) => {
  try {
    const response = await makeAuthenticatedApiCall(
      'get',
      `/api/statistics/transformations/flop?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}`,
    );
    // S'assurer que nous retournons toujours un tableau
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error fetching flop agencies:', error);
    return []; // Retourner un tableau vide en cas d'erreur
  }
};

export const useFlopTransformations = (startDate: Date, endDate: Date, franchise: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  return useQuery({
    queryKey: ['flopTransformations', formattedStartDate, formattedEndDate, franchise],
    queryFn: () => fetchFlopTransformations(formattedStartDate, formattedEndDate, franchise),
    staleTime: 24 * 60 * 60 * 1000,
    // Ajouter un fallback pour s'assurer qu'on a toujours un tableau
    select: (data) => (Array.isArray(data) ? data : []),
  });
};

export const fetchTopTransformations = async (startDate: string, endDate: string, franchise: string) => {
  try {
    const response = await makeAuthenticatedApiCall(
      'get',
      `/api/statistics/transformations/top?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}`,
    );
    // S'assurer que nous retournons toujours un tableau
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    console.error('Error fetching top agencies:', error);
    return []; // Retourner un tableau vide en cas d'erreur
  }
};

export const useTopTransformations = (startDate: Date, endDate: Date, franchise: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  return useQuery({
    queryKey: ['topTransformations', formattedStartDate, formattedEndDate, franchise],
    queryFn: () => fetchTopTransformations(formattedStartDate, formattedEndDate, franchise),
    staleTime: 24 * 60 * 60 * 1000,
    // Ajouter un fallback pour s'assurer qu'on a toujours un tableau
    select: (data) => (Array.isArray(data) ? data : []),
  });
};

interface AgencyStats {
  activeAgencies: { count: number; agencies: string[] };
  reactivatedAgencies: { count: number; agencies: string[] };
  newActivations: { count: number; agencies: string[] };
  inactiveAgencies: { count: number; agencies: string[] };
  nonReactivatedAgencies: { count: number; agencies: string[] };
}

export const fetchAgencyStats = async (): Promise<AgencyStats> => {
  try {
    const response = await makeAuthenticatedApiCall<AgencyStats>('get', `/api/statistics/agencies/stats`);
    return response.data;
  } catch (error) {
    console.error('Error fetching agency stats:', error);
    return {
      activeAgencies: { count: 0, agencies: [] },
      reactivatedAgencies: { count: 0, agencies: [] },
      newActivations: { count: 0, agencies: [] },
      inactiveAgencies: { count: 0, agencies: [] },
      nonReactivatedAgencies: { count: 0, agencies: [] },
    };
  }
};

export const useAgencyStats = () => {
  return useQuery<AgencyStats>({
    queryKey: ['agencyStats'],
    queryFn: () => fetchAgencyStats(),
    staleTime: 24 * 60 * 60 * 1000, // 24 heures de cache
    select: (data) => ({
      activeAgencies: data?.activeAgencies ?? 0,
      reactivatedAgencies: data?.reactivatedAgencies ?? 0,
      newActivations: data?.newActivations ?? 0,
      inactiveAgencies: data?.inactiveAgencies ?? 0,
      nonReactivatedAgencies: data?.nonReactivatedAgencies ?? 0,
    }),
    retry: 1, // Ne réessaie qu'une fois en cas d'échec
    refetchOnWindowFocus: false, // Ne recharge pas automatiquement quand la fenêtre reprend le focus
  });
};

export const fetchMonthlyStats = async (franchise?: string, orgName?: string): Promise<MonthlyStats> => {
  try {
    const params = new URLSearchParams();
    if (franchise) params.append('franchise', franchise);
    if (orgName) params.append('org_name', orgName);

    const response = await makeAuthenticatedApiCall<MonthlyStats>(
      'get',
      `/api/statistics/monthly-stats?${params.toString()}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching monthly stats:', error);
    return {
      currentMonth: {
        totalCotations: 0,
        totalCotationsPotentialValue: 0,
        totalBuying: 0,
        totalBuyingValue: 0,
        totalSelling: 0,
        totalSellingValue: 0,
        totalNetMarginValue: 0,
      },
      previousMonth: {
        totalCotations: 0,
        totalCotationsPotentialValue: 0,
        totalBuying: 0,
        totalBuyingValue: 0,
        totalSelling: 0,
        totalSellingValue: 0,
        totalNetMarginValue: 0,
      },
    };
  }
};

export const useMonthlyStats = (franchise?: string, orgName?: string) => {
  return useQuery<MonthlyStats>({
    queryKey: ['monthlyStats', franchise, orgName],
    queryFn: () => fetchMonthlyStats(franchise, orgName),
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const fetchDailyBuyingStats = async (franchise?: string, orgName?: string): Promise<DailyBuyingStats[]> => {
  try {
    const params = new URLSearchParams();
    if (franchise) params.append('franchise', franchise);
    if (orgName) params.append('org_name', orgName);

    const response = await makeAuthenticatedApiCall<DailyBuyingStats[]>(
      'get',
      `/api/statistics/daily-buying-stats?${params.toString()}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching daily buying stats:', error);
    return [];
  }
};

export const useDailyBuyingStats = (franchise?: string, orgName?: string) => {
  return useQuery<DailyBuyingStats[]>({
    queryKey: ['dailyBuyingStats', franchise, orgName],
    queryFn: () => fetchDailyBuyingStats(franchise, orgName),
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const fetchDailySellingStats = async (franchise?: string, orgName?: string): Promise<DailySellingStats[]> => {
  try {
    const params = new URLSearchParams();
    if (franchise) params.append('franchise', franchise);
    if (orgName) params.append('org_name', orgName);

    const response = await makeAuthenticatedApiCall<DailySellingStats[]>(
      'get',
      `/api/statistics/daily-selling-stats?${params.toString()}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching daily selling stats:', error);
    return [];
  }
};

export const useDailySellingStats = (franchise?: string, orgName?: string) => {
  return useQuery<DailySellingStats[]>({
    queryKey: ['dailySellingStats', franchise, orgName],
    queryFn: () => fetchDailySellingStats(franchise, orgName),
    staleTime: 24 * 60 * 60 * 1000,
  });
};

export const fetchDetailedStatistics = async (
  startDate: string,
  endDate: string,
  franchise: string,
  orgName: string,
) => {
  const response = await makeAuthenticatedApiCall(
    'get',
    `/api/statistics/detailed-statistics?startDate=${startDate}&endDate=${endDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(orgName)}`,
  );
  return response.data;
};

export const useDetailedStatistics = (startDate: Date, endDate: Date, franchise: string, orgName: string) => {
  const formattedStartDate = format(startDate, 'yyyy-MM-dd');
  const formattedEndDate = format(endDate, 'yyyy-MM-dd');

  return useQuery({
    queryKey: ['detailedStatistics', formattedStartDate, formattedEndDate, franchise, orgName],
    queryFn: () => fetchDetailedStatistics(formattedStartDate, formattedEndDate, franchise, orgName),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  });
};

const fetchOrganizations = async () => {
  try {
    const response = await axios.get('/api/deals/organizations');
    return response.data;
  } catch (error) {
    console.error('fetchOrganizations - error', error);
    throw error;
  }
};

export const useOrganizations = () => {
  return useQuery({ queryKey: ['organizations'], queryFn: fetchOrganizations, staleTime: 24 * 60 * 60 * 1000 }); // 24 hours
};
